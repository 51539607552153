import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { Typography } from 'fronton-react';
import { useSelector } from 'react-redux';
import { getRegions } from 'features/page-model/ducks/get-model';
import { Match, useBreakpoints } from 'common/hooks/use-breakpoints';
import { isSafari } from 'utils/browser';
import { useTranslation } from 'react-i18next';
import { container, regionExtraItem, regionItem, regionList } from './styles';

export const DATA_QA_REGION_LIST_TITLE = 'region-list-title';
export const DATA_QA_REGION_LIST_ITEM = 'region-list-item';
export const DATA_QA_REGION_LIST_EXTRA_ITEM = 'region-list-extra-item';

type Props = {
  onClick: (region: string) => void;
};

// this needs for Safari browser because items render incorrectly (https://jira.lmru.tech/browse/NAVI-2455)
export const calculateExtraItemsLength = (regionsLength: number, columnsCount: number) => {
  const columnItemsLength = Math.ceil(regionsLength / columnsCount);
  return columnItemsLength * columnsCount - regionsLength;
};

export const getColumnCount = ({ tablet, smMobile, mobile, desktop }: Match) => {
  if (desktop) {
    return 3;
  }
  if (tablet) {
    return 2;
  }
  if (smMobile || mobile) {
    return 0;
  }
  return 4;
};

export const RegionList: FC<Props> = memo(({ onClick }) => {
  const [showExtraItems, setShowExtraItems] = useState(false);
  const breakpoints = useBreakpoints() || ({} as Match);
  const regions = useSelector(getRegions) || [];
  const columnsCount = getColumnCount(breakpoints);
  const extraItemsLength = columnsCount && calculateExtraItemsLength(regions.length, columnsCount);
  const { t } = useTranslation();
  const legalName = t('legalName');
  const extraItems = useMemo(
    () =>
      [...new Array(extraItemsLength)].map((_, i) => (
        <div
          className={regionExtraItem}
          key={regions[i]?.regionId}
          data-qa={DATA_QA_REGION_LIST_EXTRA_ITEM}
        />
      )),
    [extraItemsLength, regions],
  );

  useEffect(() => {
    const ua = window?.navigator?.userAgent;
    // show extra items only for Safari browser (https://jira.lmru.tech/browse/NAVI-2455)
    if (isSafari(ua)) {
      setShowExtraItems(true);
    }
  }, [extraItems]);
  return (
    <div className={container}>
      <Typography variant="h2" data-qa={DATA_QA_REGION_LIST_TITLE}>
        {`${t('regions.header')} ${legalName}`}
      </Typography>
      <div className={regionList}>
        {regions.map(({ regionId, regionName, currentPageLink }) => (
          <a
            href={currentPageLink}
            key={regionId}
            className={regionItem}
            data-qa={DATA_QA_REGION_LIST_ITEM}
            onClick={() => onClick(regionName)}
          >
            <Typography variant="m" size="body_long">
              {regionName}
            </Typography>
          </a>
        ))}
        {showExtraItems && extraItems}
      </div>
    </div>
  );
});
