import React from 'react';

interface PalitreIconProps {
  mobile?: boolean;
}

export const PalitreIcon: React.FC<PalitreIconProps> = ({ mobile }) => (
  <svg
    width="49"
    height="48"
    viewBox={`${mobile ? 0 : -0.5} 0 49 48`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M38.0502 28.0123C38.3612 26.647 38.5123 25.2501 38.5002 23.8498C38.4252 13.9311 30.2127 5.90609 20.3127 5.99984C16.0833 6.04443 12.0047 7.57697 8.79251 10.3286C5.58029 13.0801 3.43963 16.875 2.7461 21.0474C2.05257 25.2197 2.85048 29.5031 4.99982 33.1458C7.14917 36.7886 10.5127 39.5582 14.5002 40.9686C15.1785 41.2129 15.9057 41.29 16.6202 41.1933C17.3347 41.0966 18.0152 40.8289 18.6041 40.413C19.1931 39.997 19.6729 39.4452 20.003 38.8042C20.3331 38.1632 20.5036 37.4521 20.5002 36.7311V35.9998C20.4977 35.4082 20.6124 34.8219 20.8377 34.2748C21.0629 33.7277 21.3943 33.2307 21.8127 32.8123C22.231 32.394 22.7281 32.0626 23.2752 31.8373C23.8223 31.6121 24.4085 31.4974 25.0002 31.4998H33.6627C34.6807 31.5005 35.6688 31.156 36.4657 30.5225C37.2626 29.8891 37.8211 29.0042 38.0502 28.0123Z"
      fill="#BF8900"
    />
    <path
      d="M38.0502 28.0123C38.3612 26.647 38.5123 25.2501 38.5002 23.8498C38.4252 13.9311 30.2127 5.90609 20.3127 5.99984C16.0833 6.04443 12.0047 7.57697 8.79251 10.3286C5.58029 13.0801 3.43963 16.875 2.7461 21.0474C2.05257 25.2197 2.85048 29.5031 4.99982 33.1458C7.14917 36.7886 10.5127 39.5582 14.5002 40.9686C15.1785 41.2129 15.9057 41.29 16.6202 41.1933C17.3347 41.0966 18.0152 40.8289 18.6041 40.413C19.1931 39.997 19.6729 39.4452 20.003 38.8042C20.3331 38.1632 20.5036 37.4521 20.5002 36.7311V35.9998C20.4977 35.4082 20.6124 34.8219 20.8377 34.2748C21.0629 33.7277 21.3943 33.2307 21.8127 32.8123C22.231 32.394 22.7281 32.0626 23.2752 31.8373C23.8223 31.6121 24.4085 31.4974 25.0002 31.4998H33.6627C34.6807 31.5005 35.6688 31.156 36.4657 30.5225C37.2626 29.8891 37.8211 29.0042 38.0502 28.0123V28.0123Z"
      stroke="#BF8900"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5 16.5C21.7426 16.5 22.75 15.4926 22.75 14.25C22.75 13.0074 21.7426 12 20.5 12C19.2574 12 18.25 13.0074 18.25 14.25C18.25 15.4926 19.2574 16.5 20.5 16.5Z"
      fill="#BF8900"
    />
    <path
      d="M12.0625 21.375C13.3051 21.375 14.3125 20.3676 14.3125 19.125C14.3125 17.8824 13.3051 16.875 12.0625 16.875C10.8199 16.875 9.8125 17.8824 9.8125 19.125C9.8125 20.3676 10.8199 21.375 12.0625 21.375Z"
      fill="#BF8900"
    />
    <path
      d="M12.0625 31.125C13.3051 31.125 14.3125 30.1176 14.3125 28.875C14.3125 27.6324 13.3051 26.625 12.0625 26.625C10.8199 26.625 9.8125 27.6324 9.8125 28.875C9.8125 30.1176 10.8199 31.125 12.0625 31.125Z"
      fill="#BF8900"
    />
    <path
      d="M28.9375 21.375C30.1801 21.375 31.1875 20.3676 31.1875 19.125C31.1875 17.8824 30.1801 16.875 28.9375 16.875C27.6949 16.875 26.6875 17.8824 26.6875 19.125C26.6875 20.3676 27.6949 21.375 28.9375 21.375Z"
      fill="#BF8900"
    />
  </svg>
);
