import React, { FC } from 'react';
import { HrefLang, RegionWithLangs } from '@navi-toolbox/seo';
import { HelmetContext } from '@okapi/helmet-react';

type Props = {
  url: string;
  context: HelmetContext;
};

export const HrefLangMeta: FC<Props> = ({ context, url }) => {
  const regions: RegionWithLangs[] = [
    {
      regionCodeIS03166: 'ru',
      languagesISO639: ['ru'],
    },
    {
      regionCodeIS03166: 'kz',
      languagesISO639: ['ru', 'kk'],
    },
  ];
  if (!url) {
    return null;
  }
  return <HrefLang regions={regions} url={url} context={context} />;
};
