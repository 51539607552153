import { createSelector } from 'reselect';
import { RootState } from 'store';
import { createCookies } from 'utils/cookies';

export const getAllCookies = createSelector(
  ({ cookie }: RootState) => cookie,
  (cookies) => createCookies(cookies),
);

export const getCustomerID = createSelector(getAllCookies, (cookies) => cookies?.customerId ?? null);

export const getIsAuthorized = createSelector(getAllCookies, (cookie) => cookie?.['user-auth'] === 'true');

export const getLang = createSelector(getAllCookies, (cookies) => cookies?.lang ?? 'ru');
