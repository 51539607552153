import React from 'react';
import { Typography } from 'fronton-react';
import {
  HeaderBlock,
  IconWrapper,
  supportLink,
  supportText,
  supportTitle,
  SupportUnitWrapper,
} from './styles';

export interface SupportUnitProps {
  icon: JSX.Element;
  title: string;
  text: string;
  linkText: string;
  link: string;
}

export const SupportUnit: React.FC<SupportUnitProps> = ({ icon, title, text, linkText = '', link = '' }) => (
  <SupportUnitWrapper>
    <HeaderBlock>
      <IconWrapper>{icon}</IconWrapper>
      <Typography variant="l" size="body_accent" color="text-primary" className={supportTitle}>
        {title}
      </Typography>
    </HeaderBlock>
    <Typography variant="s" size="body_long" className={supportText} color="text-primary">
      {text}
    </Typography>
    <a className={supportLink} href={link}>
      <Typography variant="s" size="body_accent" color="success-dark">
        {linkText}
      </Typography>
    </a>
  </SupportUnitWrapper>
);
