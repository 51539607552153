import React from 'react';

interface GeoIconProps {
  mobile?: boolean;
}

export const GeoIcon: React.FC<GeoIconProps> = ({ mobile }) => {
  const color = '#BF8900';
  return (
    <svg
      width="49"
      height="48"
      viewBox={`${mobile ? 0 : -8}  0 49 48`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M16.5 9C13.0522 9 9.74558 10.3777 7.30761 12.8301C4.86964 15.2825 3.5 18.6087 3.5 22.0769C3.5 33.8462 16.5 43 16.5 43C16.5 43 29.5 33.8462 29.5 22.0769C29.5 18.6087 28.1304 15.2825 25.6924 12.8301C23.2544 10.3777 19.9478 9 16.5 9ZM16.5 27.3077C15.4715 27.3077 14.4662 27.0009 13.611 26.4261C12.7559 25.8514 12.0894 25.0345 11.6958 24.0787C11.3022 23.1229 11.1993 22.0711 11.3999 21.0565C11.6006 20.0418 12.0958 19.1097 12.823 18.3782C13.5503 17.6467 14.4768 17.1485 15.4855 16.9467C16.4942 16.7448 17.5398 16.8484 18.49 17.2443C19.4401 17.6402 20.2523 18.3107 20.8236 19.1709C21.395 20.0311 21.7 21.0424 21.7 22.0769C21.7 23.4642 21.1521 24.7947 20.177 25.7756C19.2018 26.7566 17.8791 27.3077 16.5 27.3077Z"
        fill={color}
      />
      <path d="M4.5 43H28.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M16.5 27C19.2614 27 21.5 24.7614 21.5 22C21.5 19.2386 19.2614 17 16.5 17C13.7386 17 11.5 19.2386 11.5 22C11.5 24.7614 13.7386 27 16.5 27Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.5 22.0769C29.5 33.8462 16.5 43 16.5 43C16.5 43 3.5 33.8462 3.5 22.0769C3.5 18.6087 4.86964 15.2825 7.30761 12.8301C9.74558 10.3777 13.0522 9 16.5 9C19.9478 9 23.2544 10.3777 25.6924 12.8301C28.1304 15.2825 29.5 18.6087 29.5 22.0769V22.0769Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
