import React from 'react';
import { Layout } from 'fronton-react';
import { Remote } from '@okapi/remote';
import { SeoDescriptionSection } from 'features/seo-description';
import { AnalyticsPageMeta } from 'features/analytics';
import { HistoryCarousel } from 'common/components/history-carousel/history-carousel';
import { useSelector } from 'react-redux';
import { getPathname, getRegionId } from 'features/page-model/ducks/get-model';
import { getIsAuthorized } from 'common/selectors';
import { useGaPageview } from 'features/analytics/hooks';
import { HeroSection } from './components/hero-section';
import { WhatIsLm } from './components/what-is-lm';
import { Cards } from './components/cards';
import { GreyBackground, RoundedLayout } from './styles';

export const AboutUs: React.FC = () => {
  const path = useSelector(getPathname);
  const isAuthorized = useSelector(getIsAuthorized) ? '1' : '0';
  const regionId = useSelector(getRegionId);

  useGaPageview(path, isAuthorized, regionId);
  return (
    <>
      <AnalyticsPageMeta />
      <Remote widget="header-footer/header" id="about-header" />
      <HeroSection />
      <GreyBackground>
        <RoundedLayout>
          <Layout>
            <WhatIsLm />
          </Layout>
        </RoundedLayout>
      </GreyBackground>
      <Cards />
      <HistoryCarousel />
      <Layout>
        <SeoDescriptionSection />
      </Layout>
      <Remote widget="header-footer/footer" id="about-footer" />
    </>
  );
};
