import { styled } from 'linaria/react';
import { css } from 'linaria';
import { breakpoints } from '../../common/styles/breakpoints';

export const ErrorPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;

export const paintImg = css`
  margin-top: 84px;
  ${breakpoints.only.mobile} {
    margin-top: 0;
  }
`;

export const errorHeading = css`
  margin-top: var(--space-400);
`;
export const errorDescription = css`
  margin-top: var(--space-200);
  line-height: 22px !important;
  text-align: center;
`;
export const refreshButton = css`
  margin-top: var(--space-400);
`;
export const goHomeButton = css`
  margin-top: var(--space-300);
  margin-bottom: 114px;
`;
