import { configureStore, PreloadedState as PreloadedStateType } from '@reduxjs/toolkit';
import { middleware as createRouterMiddleware } from '@okapi/router-connect';
import { getGlobalRouter } from '@okapi/router-react';
import { getLayers } from 'store/utils';
import { getInitialData } from 'entries/common/get-initial-data';
import { RootReducer, rootReducer } from './root-reducer';
import { Env } from './types';

const router = getGlobalRouter();
const routerMiddleware = createRouterMiddleware(router);

const preloadedState = getInitialData();

export const createStore = (initState: PreloadedState) =>
  configureStore({
    preloadedState: initState,
    reducer: rootReducer({
      globalRouter: router,
      host: initState.host || null,
      userAgent: initState.userAgent || null,
      cookie: initState.cookie || '',
      layers: getLayers(initState.cookie ?? undefined),
      env: (initState.env as Env) || null,
      toErrorPage: initState.toErrorPage || '',
    }),
    devTools: { trace: true, traceLimit: 25 },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware),
  });

const store = createStore(preloadedState);

export type RootState = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;
export type AsyncThunk = (dispatch: Dispatch, getState: () => RootState) => Promise<unknown>;
export type PreloadedState = PreloadedStateType<RootReducer>;

export { preloadedState, router, store };
