import React from 'react';
import { Redirect, Route as ReactRoute, Switch } from '@okapi/router-react';
import { memo } from 'common/hocs/memo';
import { useSelector } from 'react-redux';
import { getToErrorPage } from 'common/selectors/to-error-page';
import { getModelErrorCode } from 'features/error-page/ducks/selectors';
import { patchPath } from 'utils/patch-path';
import { Route, routes, errorRoutes } from '../routes';
import { HttpStatus } from '../types/common';

export const Routes = memo(() => {
  const isError = !!useSelector(getToErrorPage);
  return isError ? <NestedRoutes routesTree={errorRoutes} /> : <NestedRoutes routesTree={routes} />;
});

export const NestedRoutes: React.FC<{ routesTree: Route[] | undefined }> = ({ routesTree }) => {
  const modelErrorCode = useSelector(getModelErrorCode);
  if (
    modelErrorCode === HttpStatus.SERVER_ERROR.toString() ||
    modelErrorCode === HttpStatus.NOT_FOUND.toString()
  ) {
    return <Redirect to={`/${modelErrorCode}`} />;
  }
  return (
    <Switch>
      {routesTree?.map(({ routes: nestedRoutes, component: Component, path, key, strict, exact }) => (
        <ReactRoute path={patchPath(path)} key={key} strict={strict} exact={exact}>
          {Component ? (
            <Component>
              <NestedRoutes routesTree={nestedRoutes} />
            </Component>
          ) : (
            <NestedRoutes routesTree={nestedRoutes} />
          )}
        </ReactRoute>
      ))}
    </Switch>
  );
};
