import { css } from 'linaria';
import { breakpoints } from 'common/styles/breakpoints';

export const container = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: var(--space-350);

  ${breakpoints.from.tablet} {
    margin-top: var(--space-800);
  }
`;

export const description = css`
  display: flex;
  flex-direction: column;
  width: 574px;
`;

export const button = css`
  width: 267px;
  height: 48px;
  margin-top: var(--space-400);

  ${breakpoints.to.tablet} {
    width: 100%;
  }
`;
