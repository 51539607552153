import React from 'react';

export const BoxIcon: React.FC = () => (
  <svg width="171" height="170" viewBox="0 0 171 170" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_259_1259)">
      <rect width="44" height="93" transform="matrix(-4.37114e-08 1 1 4.37114e-08 38.5 59)" fill="#FDC300" />
      <path d="M22.5 59L38.5 59L38.5 103L22.5 59Z" fill="#FDC300" />
    </g>
    <rect
      x="31"
      y="104.5"
      width="109"
      height="9"
      fill="white"
      stroke="#21282B"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <rect
      x="31"
      y="113.5"
      width="17"
      height="12"
      fill="white"
      stroke="#21282B"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <rect
      x="77"
      y="113.5"
      width="17"
      height="12"
      fill="white"
      stroke="#21282B"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <rect
      x="123"
      y="113.5"
      width="17"
      height="12"
      fill="white"
      stroke="#21282B"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <rect x="55" y="43.5" width="61" height="61" fill="#D0DDD8" />
    <rect x="55" y="43.5" width="61" height="61" stroke="#21282B" strokeWidth="3" strokeLinejoin="round" />
    <path
      d="M77 43.5H94V60.5H77V43.5Z"
      fill="white"
      stroke="#21282B"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <path d="M103.5 87.5001L99.5 91.7354V96.5001" stroke="#21282B" strokeWidth="3" strokeLinecap="square" />
    <path
      d="M106.548 90.5L107.5 91.4375V96.5H103.5"
      stroke="#21282B"
      strokeWidth="3"
      strokeLinecap="square"
    />
    <defs>
      <clipPath id="clip0_259_1259">
        <rect
          width="44"
          height="109"
          fill="white"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 22.5 59)"
        />
      </clipPath>
    </defs>
  </svg>
);
