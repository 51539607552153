import React, { useCallback } from 'react';
import { Layout, RegularButton, Typography } from 'fronton-react';
import { AnalyticsPageMeta } from 'features/analytics';
import { useSelector } from 'react-redux';
import { getPathname } from 'features/page-model/ducks/get-model';
import { getIsAuthorized } from 'common/selectors';
import { useGaPageview } from 'features/analytics/hooks';
import { analyticsUtils, patchUrlByLocale } from 'utils';
import { useTranslation } from 'react-i18next';
import {
  errorDescription,
  errorHeading,
  ErrorPageWrapper,
  goHomeButton,
  paintImg,
  refreshButton,
} from './styles';

export const Page500: React.FC = () => {
  const path = useSelector(getPathname);
  const isAuthorized = useSelector(getIsAuthorized) ? '1' : '0';
  const { t } = useTranslation();

  const sendGaEventBack = useCallback(() => {
    analyticsUtils.sendEvent(`GA:clickButton`, {
      elementType: 'button',
      elementBlock: 'landing500', // блок, в котором расположен элемент
      elementName: 'error500', // название блока (заголовок)
      elementText: 'Повторить', // заголовок пункта
    });
    location?.reload();
  }, []);

  const sendGaEventMain = useCallback(() => {
    analyticsUtils.sendEvent(`GA:clickButton`, {
      elementType: 'button',
      elementBlock: 'landing500', // блок, в котором расположен элемент
      elementName: 'error500', // название блока (заголовок)
      elementText: 'Перейти на главную', // заголовок пункта
    });
  }, []);
  useGaPageview(path, isAuthorized, null, 'other');
  return (
    <>
      <AnalyticsPageMeta
        manualTitle="Что-то пошло не так"
        manualDescription="Просим извинения за неудобства. Попробуйте ещё раз или вернитесь позже"
      />
      <Layout>
        <ErrorPageWrapper>
          <div className={paintImg}>
            <svg
              width="160"
              height="104"
              viewBox="0 0 160 104"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M80.9443 22L80.9443 71L77.4393 71L65.765 71L42.4541 70.9999C38.7478 70.9999 35.155 68.5549 32.4108 64.0982C29.6814 59.6658 27.9443 53.4477 27.9443 46.5001C27.9443 39.5525 29.6814 33.3344 32.4108 28.9019C35.155 24.4453 38.7478 22.0002 42.4541 22.0002L64.0965 22.0001L76.8136 22L80.9062 22L80.9443 22Z"
                fill="white"
                stroke="#21282B"
                strokeWidth="3"
                strokeLinejoin="round"
              />
              <mask id="path-2-inside-1_1108_8928" fill="white">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M39.6106 54C38.1576 54 36.9019 52.9597 36.7335 51.5165C36.5435 49.8889 36.4443 48.2124 36.4443 46.5C36.4443 31.036 44.5334 18.5 54.5117 18.5H82.4443C84.1012 18.5 85.4443 19.8431 85.4443 21.5V51C85.4443 52.6569 84.1012 54 82.4443 54H39.6106Z"
                />
              </mask>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.6106 54C38.1576 54 36.9019 52.9597 36.7335 51.5165C36.5435 49.8889 36.4443 48.2124 36.4443 46.5C36.4443 31.036 44.5334 18.5 54.5117 18.5H82.4443C84.1012 18.5 85.4443 19.8431 85.4443 21.5V51C85.4443 52.6569 84.1012 54 82.4443 54H39.6106Z"
                fill="#B8CAC4"
              />
              <path
                d="M39.7133 51.1687C39.5368 49.6571 39.4443 48.0967 39.4443 46.5H33.4443C33.4443 48.3281 33.5502 50.1207 33.7537 51.8643L39.7133 51.1687ZM39.4443 46.5C39.4443 39.2584 41.3441 32.8418 44.2569 28.3276C47.1897 23.7825 50.884 21.5 54.5117 21.5V15.5C48.1611 15.5 42.8217 19.4855 39.2154 25.0745C35.5891 30.6942 33.4443 38.2776 33.4443 46.5H39.4443ZM54.5117 21.5H82.4443V15.5H54.5117V21.5ZM82.4443 21.5V51H88.4443V21.5H82.4443ZM82.4443 51H39.6106V57H82.4443V51ZM82.4443 51V57C85.758 57 88.4443 54.3137 88.4443 51H82.4443ZM82.4443 21.5H88.4443C88.4443 18.1863 85.758 15.5 82.4443 15.5V21.5ZM33.7537 51.8643C34.1117 54.9313 36.7505 57 39.6106 57V51C39.6027 51 39.6145 50.9976 39.6368 51.0166C39.649 51.027 39.6665 51.0458 39.6827 51.0754C39.6999 51.1069 39.71 51.1407 39.7133 51.1687L33.7537 51.8643Z"
                fill="#21282B"
                mask="url(#path-2-inside-1_1108_8928)"
              />
              <circle cx="72.9443" cy="69" r="6.5" transform="rotate(90 72.9443 69)" fill="white" />
              <path
                d="M106.944 25L109.852 21.0564C110.744 19.8469 110.529 18.1503 109.364 17.201L107.808 15.9333C105.116 13.7403 102.089 11.9955 98.8422 10.7661V10.7661C84.5747 5.36382 68.4729 10.6805 60.2274 23.5163L58.9373 25.5246C51.5049 37.0946 51.6098 51.9629 59.2047 63.4269V63.4269C60.9416 66.0486 63.0269 68.422 65.4035 70.4816L70.1718 74.6141C72.5308 76.6587 76.1566 76.1228 77.8234 73.4833L78.4443 72.5"
                stroke="#21282B"
                strokeWidth="3"
                strokeLinejoin="round"
              />
              <rect
                x="62.4506"
                y="12.6392"
                width="9"
                height="23"
                rx="4.5"
                transform="rotate(32 62.4506 12.6392)"
                fill="white"
                stroke="#21282B"
                strokeWidth="3"
                strokeLinejoin="round"
              />
              <path
                d="M113.444 46C113.444 62.8447 103.371 76.5 90.9443 76.5C78.5179 76.5 68.4443 62.8447 68.4443 46C68.4443 29.1553 78.5179 15.5 90.9443 15.5C103.371 15.5 113.444 29.1553 113.444 46Z"
                fill="white"
                stroke="#21282B"
                strokeWidth="3"
                strokeLinejoin="round"
              />
              <g clipPath="url(#clip0_1108_8928)">
                <path d="M70 47L70 79H150L70 47Z" fill="#FDC300" />
                <rect x="70" y="79" width="80" height="17" fill="#FDC300" />
              </g>
              <g clipPath="url(#clip1_1108_8928)">
                <g clipPath="url(#clip2_1108_8928)">
                  <path d="M70 40L70 72H150L70 40Z" fill="#FDC300" />
                </g>
              </g>
              <g clipPath="url(#clip3_1108_8928)">
                <path d="M69 58L78 72V75H69V58Z" fill="white" />
                <path
                  d="M85.5 75.6011C75.7041 72.3012 68.4443 60.2998 68.4443 46C68.4443 29.1553 78.5179 15.5 90.9443 15.5C103.371 15.5 113.444 29.1553 113.444 46C113.444 55.3204 110.36 63.6643 105.5 69.2589"
                  stroke="#21282B"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M106.944 25L109.852 21.0564C110.744 19.8469 110.529 18.1503 109.364 17.201L107.808 15.9333C105.116 13.7403 102.089 11.9955 98.8422 10.7661V10.7661C84.5747 5.36382 68.4729 10.6805 60.2274 23.5163L58.9373 25.5246C51.5049 37.0946 51.6098 51.9629 59.2047 63.4269V63.4269C60.9416 66.0486 63.0269 68.422 65.4035 70.4816L70.1718 74.6141C72.5308 76.6587 76.1566 76.1228 77.8234 73.4833L78.4443 72.5"
                  stroke="#21282B"
                  strokeWidth="3"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1108_8928">
                  <rect width="80" height="49" fill="white" transform="translate(70 47)" />
                </clipPath>
                <clipPath id="clip1_1108_8928">
                  <rect x="70" y="28" width="42" height="35" rx="4" fill="white" />
                </clipPath>
                <clipPath id="clip2_1108_8928">
                  <rect width="80" height="56" fill="white" transform="translate(70 40)" />
                </clipPath>
                <clipPath id="clip3_1108_8928">
                  <rect width="48" height="77" fill="white" transform="translate(67 2)" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <Typography variant="h2" className={errorHeading}>
            Что-то пошло не так
          </Typography>
          <Typography variant="m" size="body_long" className={errorDescription}>
            Просим извинения за неудобства. <br />
            Попробуйте ещё раз или вернитесь позже.
          </Typography>
          <RegularButton className={refreshButton} onClick={sendGaEventBack} variant="primary" size="l">
            {t('500.refresh')}
          </RegularButton>
          <RegularButton
            className={goHomeButton}
            onClick={sendGaEventMain}
            as="a"
            href={patchUrlByLocale('/')}
            variant="pseudo"
            size="l"
            rounded
            data-qa="to-main"
          >
            {t('500.redirect')}
          </RegularButton>
        </ErrorPageWrapper>
      </Layout>
    </>
  );
};
