import React from 'react';

export const LeroyMerlinStoreIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1333_18821)">
      <path
        d="M15.2929 13.664L23.2929 5.66399C23.6834 5.27346 24.3166 5.27346 24.7071 5.66399L32.7071 13.664C32.8946 13.8515 33.149 13.9569 33.4142 13.9569H39.5C40.0523 13.9569 40.5 14.4046 40.5 14.9569V36.9569C40.5 37.5092 40.0523 37.9569 39.5 37.9569H8.5C7.94772 37.9569 7.5 37.5092 7.5 36.9569V14.9569C7.5 14.4046 7.94772 13.9569 8.5 13.9569H14.5858C14.851 13.9569 15.1054 13.8515 15.2929 13.664Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M15 25.5116L24 16.5116L33 25.5116H15Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1333_18821">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
