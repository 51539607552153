import React from 'react';

export const HouseStoreIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.5 20V40.0357H7.5V20L24 3.5L40.5 20ZM31.013 32.1388V23.5877L24 16.7349L16.9873 23.5877V32.1388H31.013Z"
      fill="#BF8900"
    />
    <path
      d="M21 40L40.5 40.0357V20L34 13.5802M15 40L7.5 40.0357V20L24 3.5L30 9.50741M24 16.7349L16.9873 23.5877V32.1388M23 32.1388H31.013V23.5877L27.5065 20.1613"
      stroke="#BF8900"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
