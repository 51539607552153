import React, { StrictMode } from 'react';
import { EnhancedStore } from '@reduxjs/toolkit';
import { connect, Provider } from 'react-redux';
import { DispatcherExperimentsProvider } from '@cft/react-experiments';
import { HelmetContext, Helmet, Provider as HelmetProvider } from '@okapi/helmet-react';
import { RenderCallback } from '@okapi/core';
import { GlobalRouter, Router } from '@okapi/router-react';
import { Provider as RemoteProvider } from '@okapi/remote';
import { RootState } from 'store';
import { App } from 'app';
import { getEnv } from 'common/selectors/env';
import { GoogleAnalyticsScript, YandexMetricaScript } from 'features/analytics';
import { initI18n } from 'utils/init-i18n';
import { MetaRobots } from '@navi-toolbox/seo';
import { getMetaRobotsContent, getPageUrl } from 'features/page-model/ducks/get-model';
import { HrefLangMeta } from 'features/href-lang-meta';

export type EntryProps = { router: GlobalRouter; remoteContext?: RenderCallback['remoteContext'] } & (
  | ClientSideEntryProps
  | ServerSideEntryProps
);

type ClientSideEntryProps = {
  helmetContext?: never;
  store: EnhancedStore;
};

type ServerSideEntryProps = {
  helmetContext?: HelmetContext;
  store: EnhancedStore;
};

const mapState = (state: RootState) => state;

const ConnectedDispatcherExperimentProvider = connect(mapState)(DispatcherExperimentsProvider);

export function createEntry({ store, router, helmetContext, remoteContext }: EntryProps) {
  return () => {
    const state = store.getState();
    const { ORCHESTRATOR_HOST = '' } = getEnv(state) || {};
    const orchestratorDomain = ORCHESTRATOR_HOST ? new URL(ORCHESTRATOR_HOST).origin : '';
    const lang = initI18n(router.getPath(), state.host);
    const metaRobotsContent = getMetaRobotsContent(state);
    const pageUrl = getPageUrl(state);
    const context = helmetContext || {};

    return (
      <Router router={router}>
        <StrictMode>
          <HelmetProvider context={helmetContext}>
            <Provider store={store}>
              <RemoteProvider context={remoteContext}>
                <ConnectedDispatcherExperimentProvider>
                  <App />
                  <MetaRobots content={metaRobotsContent} context={context} />
                  <HrefLangMeta url={pageUrl} context={context} />
                  <Helmet>
                    <html lang={lang} />
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=2" />
                    <meta content="#444b5b" name="theme-color" />
                    <link href="//fonts.googleapis.com" rel="dns-prefetch" />
                    <link href="https://cdn.lemanapro.ru/" crossOrigin="anonymous" rel="preconnect" />
                    <link href="https://www.googletagmanager.com" crossOrigin="anonymous" rel="preconnect" />
                    <link href="https://www.google-analytics.com" crossOrigin="anonymous" rel="preconnect" />
                    <link href="https://fonts.gstatic.com" crossOrigin="anonymous" rel="preconnect" />
                    <link href={orchestratorDomain} crossOrigin="anonymous" rel="preconnect" />
                  </Helmet>
                  <YandexMetricaScript />
                  <GoogleAnalyticsScript />
                </ConnectedDispatcherExperimentProvider>
              </RemoteProvider>
            </Provider>
          </HelmetProvider>
        </StrictMode>
      </Router>
    );
  };
}
