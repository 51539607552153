import React from 'react';

export const CheckSquareOffsetIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.25 26.8688V9.75C8.25 9.35218 8.40804 8.97064 8.68934 8.68934C8.97064 8.40804 9.35218 8.25 9.75 8.25H38.25C38.6478 8.25 39.0294 8.40804 39.3107 8.68934C39.592 8.97064 39.75 9.35218 39.75 9.75V38.25C39.75 38.6478 39.592 39.0294 39.3107 39.3107C39.0294 39.592 38.6478 39.75 38.25 39.75H25.425M26 28.5L14 40.5L8 34.5"
      stroke="#333333"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
