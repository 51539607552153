import React, { MutableRefObject, useRef } from 'react';
import { cx } from 'linaria';
import { Layout, Typography } from 'fronton-react';
import { BreadcrumbsSection } from 'features/breadcrumbs';
import { useBreakpoints } from 'common/hooks/use-breakpoints';
import { useEffectOnce } from 'common';
import { useOptimizedImages } from 'common/hooks/use-optimized-images';
import { carouselScroll } from 'utils/carousel-scroll';
import {
  aboutParagraph,
  heading,
  heroContainer,
  heroTopPadding,
  scrollCarousel,
  subHeading,
  textCenter,
} from '../styles';

export const RebrandSection: React.FC = () => {
  const { mobile } = useBreakpoints() || {};
  const slider = useRef<HTMLDivElement | null>(null);
  let pos = { left: 0, x: 0 };

  useEffectOnce(() => {
    carouselScroll(pos, slider as MutableRefObject<HTMLDivElement>);
  });
  const imgUrl =
    'https://cdn.lemanapro.ru/lmru/image/upload/v1720434561/fudzi/navigation-microfronts/rebrending.png/rebrending.png';
  const optimizedImage = useOptimizedImages(imgUrl, {
    rest: {
      h: '405',
    },
  });

  return (
    <section className={cx(heroContainer, textCenter)}>
      <Layout>
        <BreadcrumbsSection />
        <Typography
          variant="l"
          size="body_long"
          as={mobile ? 'h2' : 'h1'}
          color="text-primary"
          className={heroTopPadding}
        >
          Продолжаем работать для вас в новом образе
        </Typography>
        <Typography variant={mobile ? 'h2' : 'h1'} color="text-primary" className={heading}>
          Леруа Мерлен теперь Лемана ПРО
        </Typography>
        <Typography variant="l" size="body_short" color="text-primary" className={aboutParagraph}>
          Мы сохранили всё самое важное — ценности компании, отношение к клиентам и партнёрам, большой выбор
          проверенных товаров и доступные цены. Все магазины, сайт и приложение продолжат свою работу
          в прежнем режиме.
        </Typography>
        <div className={scrollCarousel} ref={slider}>
          <picture>
            <source srcSet={`${optimizedImage.rest?.xx} 2x, ${optimizedImage.rest?.x} 1x`} />
            <img
              alt="О компании"
              src={optimizedImage.rest?.x ?? undefined}
              srcSet={`${optimizedImage.rest?.xx} 2x, ${optimizedImage.rest?.x} 1x`}
              draggable={false}
            />
          </picture>
        </div>
        {mobile ? (
          <Typography variant="l" size="body_accent" className={subHeading}>
            Ребрендинг будет происходить поэтапно: уже обновили сайт и приложение, а до конца года планируем
            обновить наши магазины — следите за новостями!
          </Typography>
        ) : (
          <Typography variant="h2" as="h2" className={subHeading}>
            Ребрендинг будет происходить поэтапно: уже обновили сайт и приложение, а до конца года планируем
            обновить наши магазины — следите за новостями!
          </Typography>
        )}
      </Layout>
    </section>
  );
};
