import { combineReducers } from '@reduxjs/toolkit';
import { pageModelReducer } from './page-model';
import { stubRegionReducer } from './stub-region';
import { modelErrorCodeReducer } from './error-page';

export const featuresReducer = combineReducers({
  pageModel: pageModelReducer,
  stubRegion: stubRegionReducer,
  modelErrorCode: modelErrorCodeReducer(),
});
