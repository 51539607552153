import { styled } from 'linaria/react';
import { css } from 'linaria';
import { breakpoints } from 'common/styles/breakpoints';

export const AccordionContainer = styled.div``;

export const AccordionItemContainer = styled.div`
  position: relative;
  padding: var(--space-200);
  margin-bottom: -1px;
  border: 1px solid var(--control-minor);
  cursor: pointer;
  ${breakpoints.from.tablet} {
    padding: var(--space-400) var(--space-300);
  }
  &:first-child {
    border-radius: var(--rounding-l) var(--rounding-l) 0 0;
  }
  &:last-child {
    border-radius: 0 0 var(--rounding-l) var(--rounding-l);
  }
  &:only-child {
    border-radius: var(--rounding-l);
  }

  &:hover {
    z-index: 1;
    color: var(--control-hover-primary);
    border-color: var(--control-hover-minor);
  }
`;

export const heading = css`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--space-125);
  width: 100%;
  text-align: left;
  div[role='presentation'] {
    transform: rotate(-180deg);
    transition: 0.5s;
  }
  & > * {
    margin: 0 !important;
  }
`;

export const content = css`
  padding-top: var(--space-100);
  ${breakpoints.from.tablet} {
    padding-right: var(--space-400);
  }
  & > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const contentClosed = css`
  .${heading} {
    div[role='presentation'] {
      transform: rotate(0);
    }
    p {
      font-weight: 400 !important;
    }
  }
`;
