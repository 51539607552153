export const carouselScroll = (
  pos: { left: number; x: number },
  slider: React.MutableRefObject<HTMLDivElement>,
) => {
  const mouseMoveHandler = (e: MouseEvent) => {
    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    // Scroll the element
    slider.current.scrollLeft = pos.left - dx;
  };
  const mouseDownHandler = (e: MouseEvent) => {
    // Change the cursor and prevent user from selecting the text
    slider.current.style.cursor = 'grabbing';
    slider.current.style.userSelect = 'none';
    pos = {
      // The current scroll
      left: slider.current?.scrollLeft,
      // Get the current mouse position
      x: e.clientX,
    };

    slider.current.addEventListener('mousemove', mouseMoveHandler);
    slider.current.addEventListener('mouseup', mouseUpHandler);
    slider.current.addEventListener('mouseleave', mouseUpHandler);
  };
  const mouseUpHandler = () => {
    slider.current.removeEventListener('mousemove', mouseMoveHandler);
    slider.current.removeEventListener('mouseup', mouseUpHandler);
    slider.current.removeEventListener('mouseleave', mouseUpHandler);
    slider.current.style.cursor = 'grab';
    slider.current.style.removeProperty('user-select');
  };
  const sliderPointer = slider.current;
  sliderPointer?.addEventListener('mousedown', mouseDownHandler);
  return () => {
    sliderPointer?.removeEventListener('mousedown', mouseDownHandler);
  };
};
