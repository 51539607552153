import React, { useCallback, useState } from 'react';
import { Typography } from 'fronton-react';
import { ChevronDownIcon } from '@fronton/icons-react';
import { useTranslation } from 'react-i18next';
import AnimateHeight from 'react-animate-height';
import { AccordionItemContainer, content, contentClosed, heading } from './style';

type Props = {
  title: JSX.Element | string;
  onClick?: (open: boolean) => void;
  children: JSX.Element | JSX.Element[] | string;
};

export const AccordionItem = ({ title, children, onClick }: Props) => {
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState<number | 'auto'>(0);
  const { t } = useTranslation();
  const handleClick = useCallback(() => {
    setOpen(!open);
    setHeight(height === 0 ? 'auto' : 0);
    onClick?.(open);
  }, [open, height, onClick]);
  return (
    <AccordionItemContainer
      className={open ? '' : contentClosed}
      data-qa="accordion-item-container"
      aria-expanded={open}
      aria-controls="panel"
      onClick={handleClick}
    >
      <div className={heading} data-qa="accordion-item-header">
        <Typography variant="m" size="body_accent" as="p">
          {title}
        </Typography>
        <ChevronDownIcon
          type="outline"
          size="l"
          color="text-primary"
          aria-label={open ? `${t('ui.accordion.hide')} ${title}` : `${t('ui.accordion.show')} ${title}`}
        />
      </div>
      <AnimateHeight id="panel" duration={500} height={height}>
        <div data-qa="accordion-item-content" className={content}>
          {children}
        </div>
      </AnimateHeight>
    </AccordionItemContainer>
  );
};
