import { css } from 'linaria';
import { breakpoints } from 'common/styles/breakpoints';
import { styled } from 'linaria/react';

export const GreyBackground = styled.div`
  background-color: var(--background-secondary);
`;

export const RoundedLayout = styled.div`
  background-color: var(--background-primary);
  border-radius: var(--rounding-xl) var(--rounding-xl) 0 0;
`;

export const heroTopPadding = css`
  ${breakpoints.only.mobile} {
    margin-top: var(--space-250);
  }
  margin-top: var(--space-500);
  ${breakpoints.to.desktop} {
    line-height: 28px !important;
  }
`;

export const textCenter = css`
  text-align: center;
`;

export const heroContainer = css`
  width: 100%;
  padding-bottom: var(--space-600);
  background-color: var(--background-secondary);
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  ${breakpoints.from.tablet} {
    padding-bottom: var(--space-750);
  }
  ${breakpoints.to.desktop} {
    padding-top: calc(var(--space-300) + 20px);
  }
`;

export const heading = css`
  margin-top: var(--space-200);
  font-weight: 700 !important;
  ${breakpoints.from.tablet} {
    max-width: 692px;
  }
`;

export const aboutParagraph = css`
  display: block;
  width: 100%;
  margin-top: var(--space-200);
  ${breakpoints.from.tablet} {
    width: 584px;
  }
  ${breakpoints.from.desktop} {
    width: 692px;
  }
`;

export const subHeading = css`
  color: var(--brand-dark);
  ${breakpoints.only.mobile} {
    font-weight: 500 !important;
    line-height: var(--space-350) !important;
  }
  ${breakpoints.from.tablet} {
    max-width: 660px;
    font-weight: 600 !important;
  }
  ${breakpoints.from.largeDesktop} {
    max-width: 700px;
  }
`;

export const scrollCarousel = css`
  width: 100vw;
  height: 323px;
  padding: 0 calc(50vw - var(--layout-width) / 2 - var(--layout-gap) * 2 / 2 + var(--layout-gap));
  margin: var(--space-400) 0;
  overflow-x: scroll;
  overflow-y: hidden;
  cursor: grab;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-padding: calc(50vw - var(--layout-width) / 2 - var(--layout-gap) * 2 / 2 + var(--layout-gap));
  img {
    width: auto;
    height: 100%;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  ${breakpoints.from.tablet} {
    height: 346px;
    margin: var(--space-800) 0;
  }
  ${breakpoints.from.largeDesktop} {
    height: 405px;
  }
  @media (min-width: 1966px) {
    padding: unset;
    overflow-x: hidden;
    scroll-padding: unset;
  }
`;
