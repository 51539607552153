import { createSlice } from '@reduxjs/toolkit';
import { pageModelThunk } from 'features/page-model';
import { HttpStatus } from 'types/common';

export const modelErrorCodeReducer = () => {
  const { reducer } = createSlice({
    name: 'modelErrorCode',
    initialState: '',
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(pageModelThunk.rejected, (state, { payload }) =>
        payload === HttpStatus.SERVER_ERROR || payload === HttpStatus.NOT_FOUND ? `${payload}` : '',
      );
    },
  });

  return reducer;
};
