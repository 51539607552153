import React from 'react';
import { Typography } from 'fronton-react';
import { useOptimizedImages } from 'common/hooks';
import { useTranslation } from 'react-i18next';
import {
  HistoryDescription,
  HistoryItemWrapper,
  HistoryPicture,
  HistoryPictureEmpty,
  yearsPadding,
} from './styles';

export interface HistoryItemProps {
  years: string;
  imgUrl?: string;
  description: string;
}

export const HistoryItem: React.FC<HistoryItemProps> = ({ years, imgUrl, description }) => {
  const optimizedImage = useOptimizedImages(imgUrl, {
    rest: {
      h: '220',
    },
  });
  const { t } = useTranslation();
  return (
    <HistoryItemWrapper>
      {imgUrl ? (
        <HistoryPicture>
          <picture>
            <source srcSet={`${optimizedImage.rest?.xx} 2x, ${optimizedImage.rest?.x} 1x`} />
            <img
              alt={t('ui.history.alt')}
              src={optimizedImage.rest?.x ?? undefined}
              srcSet={`${optimizedImage.rest?.xx} 2x, ${optimizedImage.rest?.x} 1x`}
              draggable={false}
            />
          </picture>
        </HistoryPicture>
      ) : (
        <HistoryPictureEmpty />
      )}
      <HistoryDescription>
        <Typography variant="h1" color="text-primary" className={yearsPadding}>
          {years}
        </Typography>
        <Typography variant="m" color="text-primary" size="body_long">
          {description}
        </Typography>
      </HistoryDescription>
    </HistoryItemWrapper>
  );
};
