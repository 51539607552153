import { styled } from 'linaria/react';
import { breakpoints } from 'common/styles/breakpoints';

export const SquaredBorderWrapper = styled.div`
  padding: var(--space-75) 0;
  background-image: url('./square.svg');
  background-repeat: repeat-x;
  ${breakpoints.only.mobile} {
    width: 1730px;
    overflow: hidden;
  }
  ${breakpoints.only.tablet} {
    width: 2184px;
    overflow: hidden;
  }
  ${breakpoints.from.desktop} {
    width: 5750px;
    overflow: hidden;
  }
  @media (min-width: 2064px) {
    width: 6050px;
  }
`;
