import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { cx } from 'linaria';
import { Typography, LinkButton, Divider, ArrowIcon, Icon } from 'fronton-react';
import { MOBILE_VIEW_SEARCH_PARAM } from 'common/constants';
import MenuLinks from 'pages/selling-rules/menu-links.json';
import { analyticsUtils, patchUrlByLocale } from 'utils';
import {
  customButton,
  customButtonInactive,
  displayMobile,
  extraMenuItem,
  FaqMenuContainer,
  icon,
  menuItem,
  menuItemActive,
  title,
} from './faq-menu.styles';
import { MenuItemProps, MenuProps } from './faq-menu.types';
import { getPathnameWithSearch } from '../page-model/ducks/get-model';

export const FaqMenu: React.FC<MenuProps> = ({ isMobileView = false }) => (
  <FaqMenuContainer>
    <Typography className={cx(title, displayMobile)} variant="h2" color="text-primary">
      Другие темы
    </Typography>
    {MenuLinks.map((el, idx) => (
      <MenuItem
        link={patchUrlByLocale(el.link + (isMobileView ? `?${MOBILE_VIEW_SEARCH_PARAM}` : ''))}
        label={el.label}
        idx={idx}
        key={el.label}
      />
    ))}
  </FaqMenuContainer>
);

const MenuItem: React.FC<MenuItemProps> = ({ link, label, idx }) => {
  const [isActive, setIsActive] = useState(false);
  const path = useSelector(getPathnameWithSearch);
  const chosenMenuItem = idx === 0 || idx === 1 ? cx(menuItem, extraMenuItem) : menuItem;

  useEffect(() => {
    setIsActive(link === path);
  }, [link, path]);

  const menuClickGa = (text: string) => {
    analyticsUtils.sendEvent('GA:clickButton', {
      elementType: 'button',
      elementBlock: 'pravila', // блок, в котором расположен элемент
      elementName: 'Правила продажи товаров', // название блока (заголовок)
      elementText: text, // текст элемента
    });
  };

  return (
    <>
      <LinkButton
        onClick={() => menuClickGa(label)}
        href={link}
        className={isActive ? cx(customButton, customButtonInactive) : customButton}
        data-qa="menuContainer"
      >
        <div className={isActive ? cx(chosenMenuItem, menuItemActive) : chosenMenuItem} data-qa="menuLink">
          <Typography variant="m" size="body_long">
            {label}
          </Typography>
          <Icon
            className={cx(icon, displayMobile)}
            color={isActive ? 'text-minor' : 'text-primary'}
            size="space-250"
          >
            <ArrowIcon />
          </Icon>
        </div>
      </LinkButton>
      <Divider className={displayMobile} />
    </>
  );
};
