import { Dispatch, RootState } from 'store';
import { getQaCookies } from 'utils/get-qa-cookies';
import { getRegionFromHost } from 'features/page-model/ducks/get-model';
import { createFeatureSelector } from 'common/create-feature-selector';
import { createSelector } from 'reselect';
import { getLangCookie } from 'utils/get-lang-cookie';
import { GetStubRegionParams } from './api';
import { fetchStubRegionThunk } from './stub-region-data-slice';
import { getEnv } from '../../../common/selectors';

export const rootSelector = createFeatureSelector('stubRegion');

export const getStubRegionName = createSelector(rootSelector, (r) => r.data?.name);
export const getStubRegionNamePrepositional = createSelector(rootSelector, (r) => r.data?.namePrepositional);
export const getStubRegionDelivery = createSelector(rootSelector, (r) => r.data?.delivery);
export const getStubRegionDeliveryFrom = createSelector(rootSelector, (r) => r.data?.deliveryFrom);
export const getStubRegionOrderFrom = createSelector(rootSelector, (r) => r.data?.orderFrom);

export const fetchStubRegion = () => async (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const qaCookie = getQaCookies(state.cookie);
  const lang = getLangCookie(state.cookie);
  const { ORCHESTRATOR_HOST = '', API_KEY = '' } = getEnv(state);

  const regionName = getRegionFromHost(state);
  const options: GetStubRegionParams = {
    host: ORCHESTRATOR_HOST,
    params: { regionName, lang },
    config: {
      headers: {
        'x-api-key': API_KEY,
      },
    },
  };

  if (qaCookie) {
    options.config = { ...options.config, headers: { ...options.config?.headers, cookie: qaCookie } };
  }

  return dispatch(fetchStubRegionThunk(options));
};
