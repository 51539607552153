import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { AnalyticsPageMeta } from 'features/analytics';
import { Layout, Typography } from 'fronton-react';
import { SeoDescriptionSection } from 'features/seo-description';
import { BreadcrumbsSection } from 'features/breadcrumbs';
import { Remote } from '@okapi/remote';
import { FaqMenu } from 'features/faq-menu';
import { getIsMobileView, getPathname, getRegionId } from 'features/page-model/ducks/get-model';
import { getIsAuthorized } from 'common/selectors';
import { useTranslation } from 'react-i18next';
import { useGaPageview } from 'features/analytics/hooks';
import MenuLinks from './menu-links.json';
import { StaticPageContainer, StaticPageContent, Faq } from './selling-rules.styles';
import { Pravila } from './components';

export const SellingRules = memo(() => {
  const currentPathname = useSelector(getPathname);
  const title = (MenuLinks.find(({ link }) => currentPathname === link) || {}).label;
  const isAuthorized = useSelector(getIsAuthorized) ? '1' : '0';
  const regionId = useSelector(getRegionId);
  const isMobileView = useSelector(getIsMobileView);

  useGaPageview(currentPathname, isAuthorized, regionId);

  return (
    <>
      <AnalyticsPageMeta />
      {!isMobileView && <Remote widget="header-footer/header" id="header" />}
      <Layout>
        {!isMobileView && <BreadcrumbsSection />}
        <StaticPageContainer>
          <StaticPageContent>
            <CurrentStaticPage title={title} />
            <FaqMenu isMobileView={isMobileView} />
          </StaticPageContent>
        </StaticPageContainer>
        <SeoDescriptionSection />
      </Layout>
      {!isMobileView && <Remote widget="header-footer/footer" id="footer" />}
    </>
  );
});

const CurrentStaticPage: React.FC<{ title: string | undefined }> = ({ title }) => {
  const { t } = useTranslation();
  const legalName = t('legalName');
  const currentPathname = useSelector(getPathname);
  const urlComponentMap = {
    '/pravila/': <Pravila legalName={legalName} />,
  };

  const Component = Object.entries(urlComponentMap)?.find(([key]) => key === currentPathname)?.[1];
  return Component === undefined ? (
    <div />
  ) : (
    <Faq>
      <Typography variant="h1" as="h1" data-qa="staticPageTitle" color="text-primary">
        {title}
      </Typography>
      {Component}
    </Faq>
  );
};
