export const historyItems = [
  {
    years: '2004',
    imgUrl:
      'https://cdn.lemanapro.ru/lmru/image/upload/v1/other/navigation-microfronts/static-pages/about-us/image%2047.png/file.png',
    description:
      'Открывается первый магазин в России в г. Мытищи Московской области под брендом Леруа Мерлен.',
  },
  // TODO: загрузить картинки
  {
    years: '2018',
    imgUrl:
      'https://cdn.lemanapro.ru/lmru/image/upload/v1718704112/fudzi/navigation-microfronts/almaty.png/almaty.png',
    description: 'Выход на новый рынок - открытие магазина в Казахстане в г. Алматы.',
  },
  {
    years: '2018',
    imgUrl:
      'https://cdn.lemanapro.ru/lmru/image/upload/v1718704112/fudzi/navigation-microfronts/marketplace.png/marketplace.png',
    description: 'Старт развития собственного маркетплейса.',
  },
  {
    years: '2019',
    imgUrl:
      'https://cdn.lemanapro.ru/lmru/image/upload/v1/other/navigation-microfronts/static-pages/about-us/image%2030.png/file.png',
    description: 'Открытие 100-го гипермаркета в России в г. Владивосток.',
  },
  {
    years: '2019',
    imgUrl:
      'https://cdn.lemanapro.ru/lmru/image/upload/v1718704112/fudzi/navigation-microfronts/pro.png/pro.png',
    description:
      'Новый этап во взаимодействии с профессиональными клиентами. Создание отдельного направления ПРО в компании.',
  },
  {
    years: '2024',
    imgUrl:
      'https://cdn.lemanapro.ru/lmru/image/upload/v1718704112/fudzi/navigation-microfronts/brand.png/brand.png',
    description:
      'Старт новой главы в истории бренда. Изменение торговой марки и продолжение работы под названием «Лемана ПРО» (Lemana PRO).',
  },
];
