import { css } from 'linaria';
import { breakpoints } from 'common/styles/breakpoints';

export const showMoreButton = css`
  width: 146px;
  height: var(--space-400);
  margin-bottom: auto;
  text-decoration: none !important;
  ${breakpoints.to.desktop} {
    margin-left: auto;
  }
`;
export const infobarContent = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--space-100);
  width: 100%;

  ${breakpoints.to.desktop} {
    flex-direction: column;
  }
`;
export const refundContainer = css`
  margin-right: 303px;
  ${breakpoints.to.desktop} {
    margin-right: 0;
  }
`;
export const refundInfoText = css`
  display: flex;
  flex-direction: column;
  gap: var(--space-100);
  margin-top: var(--space-200);
  ${breakpoints.to.tablet} {
    margin-top: var(--space-150);
  }
`;
export const easyRefund = css`
  margin-top: var(--space-1000);
  ${breakpoints.to.tablet} {
    margin-top: var(--space-500);
  }
`;
export const easyRefundAdvantagesContainer = css`
  display: flex;
  flex-direction: row;
  gap: var(--space-300);
  margin-top: var(--space-300);
  ${breakpoints.to.tablet} {
    flex-direction: column;
  }
`;
export const easyRefundAdvantage = css`
  min-width: 217px;
  padding: var(--space-300);
  border: 1px solid #e8e9eb;
  border-radius: var(--rounding-l);
  ${breakpoints.only.tablet} {
    min-width: 204px;
  }
`;
export const easyRefundAdvantageText = css`
  display: flex;
  flex-direction: column;
  gap: var(--space-100);
  margin-top: var(--space-200);
`;
export const serviceCardContainer = css`
  margin-top: var(--space-1000);
  background-color: var(--background-secondary);
`;
export const serviceCardCheckoutText = css`
  display: flex;
  flex-direction: column;
  gap: var(--space-50);
  padding: var(--space-50);
`;
export const accordionBlock = css`
  margin: var(--space-1000) 0;
  ${breakpoints.to.desktop} {
    margin: var(--space-800) 0;
  }
  ${breakpoints.to.tablet} {
    margin: var(--space-500) 0;
  }
`;
export const accordionPart = css`
  margin-top: var(--space-400);
`;
export const accordionContainer = css`
  margin: var(--space-200) 0;
`;
export const paragraph = css`
  margin-top: var(--space-100);
`;
export const dottedOrderedList = css`
  padding-inline-start: 0;
  margin: 0;
  list-style-type: none;
  ::before {
    position: relative;
    left: var(--space-125);
    padding-top: var(--space-100);
    font-size: var(--space-35);
    content: '\\2B24 ';
  }
  :not(span) {
    ::before {
      content: none;
    }
  }
`;
export const li = css`
  display: flex;
`;
export const customDottedText = css`
  padding-left: var(--space-225) !important; /* Добавлен important, тк во фронтоне обнуляются padding */
`;
export const table = css`
  margin: var(--space-200) 0;
`;
export const tableItem = css`
  display: flex;
  flex-direction: row;
  gap: var(--space-100);
  margin: var(--space-200) 0;
  ${breakpoints.to.desktop} {
    flex-direction: column;
  }
`;
export const tableRowFirstItem = css`
  display: flex;
  flex-direction: column;
  gap: var(--space-50);
  min-width: 271px;
  max-width: 271px;
  margin-top: var(--space-100);
  ${breakpoints.to.largeDesktop} {
    max-width: inherit;
  }
`;
export const textMinor = css`
  margin-top: var(--space-100);
`;
