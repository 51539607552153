import React, { FC } from 'react';
import { Typography } from 'fronton-react';
import { useTranslation } from 'react-i18next';
import { container, seoDescription } from './style';

type Props = {
  region: string;
  regionLink: string;
  legalName: string;
};

export const SeoDescription: FC<Props> = ({ region, regionLink, legalName }) => {
  const { t } = useTranslation();
  return (
    <div className={container}>
      <Typography variant="m" size="subtitle" as="h2">
        {t('regions.shopsIn')} {region}
      </Typography>
      <div data-qa-seo-text="" className={seoDescription}>
        {t('regions.noShops1')}&nbsp;{region} {t('regions.noShops2')} {legalName}
        {'. '}
        {t('regions.noShops3')} {legalName} {t('regions.noShops4')}
      </div>
      <Typography variant="m" size="subtitle" as="h2">
        {t('regions.howToKnow')} {legalName}?
      </Typography>
      <div data-qa-seo-text="" className={seoDescription}>
        {t('regions.choose1')}
        <a href={`https://${regionLink}.lemanapro.ru/shop/`}>{t('regions.choose2')}</a>{' '}
        {t('regions.choose3')}
      </div>
    </div>
  );
};
