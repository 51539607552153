import { css } from 'linaria';
import { breakpoints } from 'common/styles/breakpoints';

export const sectionStyle = css`
  width: 100%;
  margin-bottom: var(--space-250);
  ${breakpoints.only.mobile} {
    a {
      font-size: 12px !important;
      line-height: 16px !important;
    }
  }
  ${breakpoints.from.desktop} {
    margin: var(--space-300) 0;
  }
`;
