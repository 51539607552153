import React from 'react';

export const CareIcon: React.FC = () => (
  <svg width="171" height="170" viewBox="0 0 171 170" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_259_1365)">
      <g clipPath="url(#clip1_259_1365)">
        <g clipPath="url(#clip2_259_1365)">
          <rect
            width="72"
            height="60"
            transform="matrix(-0.927184 -0.374607 -0.374607 0.927184 139.502 59.2631)"
            fill="#FDC300"
          />
          <path d="M150.365 32.3748L139.502 59.2631L72.7444 32.2915L150.365 32.3748Z" fill="#FDC300" />
        </g>
        <path
          d="M87.3965 123.283C87.3532 120.764 85.5831 118.675 83.2263 118.136H90.7075C93.6209 118.136 95.992 115.791 96.0248 112.878C96.0521 110.463 94.4662 108.409 92.2738 107.735L93.5495 107.738C96.4859 107.747 98.8846 105.395 98.9338 102.459C98.9841 99.4593 96.566 97.0003 93.5655 97.0003H61.3406C61.3367 97.0003 61.3341 96.9999 61.3323 96.9994C61.3312 96.9991 61.3304 96.9988 61.3297 96.9986C61.3272 96.9975 61.3237 96.9955 61.32 96.9919C61.3163 96.9883 61.3141 96.9848 61.313 96.9823C61.3123 96.9808 61.3112 96.978 61.311 96.9715C61.3108 96.9643 61.3119 96.9616 61.3124 96.9602C61.3134 96.9578 61.3155 96.954 61.3193 96.95C61.3209 96.9484 61.3225 96.947 61.3239 96.9459C61.326 96.9444 61.3279 96.9434 61.3293 96.9428C61.3306 96.9422 61.3334 96.941 61.3405 96.941L88.6051 96.8215C91.9576 96.8069 94.6677 94.0849 94.6677 90.7323C94.6677 87.3693 91.9414 84.643 88.5784 84.643H52.8557C51.1639 84.643 50.4411 82.4932 51.7893 81.4711L59.4281 75.6799C62.0068 73.725 62.3937 69.9964 60.2713 67.5537C58.2698 65.2502 54.7913 64.976 52.4536 66.9374L31.5148 84.5054C25.4834 89.5659 22 97.0356 22 104.909C22 118.076 32.674 128.75 45.841 128.75H82.0225C85.027 128.75 87.448 126.287 87.3965 123.283ZM60.4348 107.5C60.4349 107.5 60.435 107.5 60.435 107.5H60.4348ZM60.4346 107.64H60.4348H60.4346Z"
          fill="white"
          stroke="#21282B"
          strokeWidth="3"
        />
        <rect x="55.5" y="87.9995" width="38" height="36" fill="white" />
        <path
          d="M85.2839 79.481C83.6644 81.41 83.6296 84.1482 85.0543 86.1014L79.4083 81.1934C77.2094 79.282 73.8819 79.4958 71.9458 81.6729C70.341 83.4776 70.1904 86.068 71.4024 88.0155L70.4421 87.1757C68.2317 85.2427 64.8785 85.4439 62.9152 87.6274C60.909 89.8586 61.1207 93.3009 63.3852 95.2694L87.7056 116.411C87.7086 116.413 87.7103 116.415 87.7113 116.417C87.712 116.418 87.7124 116.419 87.7127 116.419C87.7139 116.422 87.7152 116.426 87.7157 116.431C87.7161 116.436 87.7155 116.44 87.7147 116.442C87.7142 116.444 87.7132 116.447 87.7091 116.452C87.7045 116.458 87.7019 116.459 87.7006 116.46C87.6983 116.461 87.6942 116.462 87.6887 116.463C87.6864 116.463 87.6844 116.463 87.6826 116.463C87.68 116.463 87.6779 116.462 87.6764 116.462C87.675 116.461 87.6722 116.46 87.6668 116.456L67.0116 98.6586C64.4718 96.4702 60.6407 96.7466 58.4412 99.2768C56.2349 101.815 56.5038 105.661 59.0419 107.867L86.0022 131.304C87.279 132.413 86.4141 134.51 84.7261 134.397L75.1616 133.756C71.9329 133.54 69.1948 136.1 69.194 139.336C69.1933 142.388 71.6386 144.877 74.6897 144.93L102.018 145.408C109.89 145.546 117.42 142.194 122.585 136.252C131.223 126.315 130.17 111.256 120.233 102.618L92.9264 78.8806C90.6588 76.9095 87.2158 77.18 85.2839 79.481ZM95.2778 109.081C95.2778 109.081 95.2777 109.081 95.2777 109.081L95.2778 109.081ZM95.3697 108.975L95.3695 108.975L95.3697 108.975Z"
          fill="#D0DDD8"
          stroke="#21282B"
          strokeWidth="3"
        />
        <path
          d="M104.269 49.7866C124.592 64.7559 117.297 91.4482 82.913 103.684C66.513 70.5324 79.4504 46.1641 104.269 49.7866Z"
          fill="white"
        />
        <path
          d="M90.4895 85.3747L107.997 41.3742M103.978 51.3343C124.3 66.3036 117.006 92.9958 82.6214 105.232C66.2213 72.0801 79.1588 47.7118 103.978 51.3343Z"
          stroke="#21282B"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_259_1365">
        <rect width="170" height="170" fill="white" transform="translate(0.5)" />
      </clipPath>
      <clipPath id="clip1_259_1365">
        <rect width="170" height="170" fill="white" transform="translate(0.5)" />
      </clipPath>
      <clipPath id="clip2_259_1365">
        <rect
          width="72"
          height="88.908"
          fill="white"
          transform="matrix(-0.927184 -0.374607 -0.374607 0.927184 150.365 32.3748)"
        />
      </clipPath>
    </defs>
  </svg>
);
