import React, { MouseEvent, KeyboardEvent } from 'react';
import { withoutPropagationWrapperStyles } from './styles';

type LinkWithoutPropagationProps = {
  children: JSX.Element;
};

export const WithoutPropagationWrapper: React.FC<LinkWithoutPropagationProps> = ({ children }) => {
  const stopPropagation = (event: MouseEvent | KeyboardEvent) => {
    event.stopPropagation();
  };

  return (
    <div
      data-qa="without-propagation-wrapper"
      className={withoutPropagationWrapperStyles}
      onClick={(e) => stopPropagation(e)}
      onKeyDown={(e) => stopPropagation(e)}
    >
      {children}
    </div>
  );
};
