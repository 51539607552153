import React from 'react';
import { useSelector } from 'react-redux';
import { getToErrorPage } from 'common/selectors';
import { Page404 } from '../404';
import { Page500 } from '../500';

export const PageError: React.FC = () => {
  const toErrorCode = useSelector(getToErrorPage);

  switch (toErrorCode) {
    case '404':
      return <Page404 />;
    default:
      return <Page500 />;
  }
};
