import React from 'react';
import { Typography } from 'fronton-react';
import { cx } from 'linaria';
import { useBreakpoints } from 'common/hooks/use-breakpoints';
import { HouseStoreIcon } from 'common/icons/house-store-icon';
import { GeoIcon } from 'common/icons/geo-icon';
import { HumanIcon } from 'common/icons/human-icon';
import { PalitreIcon } from 'common/icons/palitre-icon';
import { CoopIcon } from 'common/icons/coop-icon';
import { SamplesIcon } from 'common/icons/samples-icon';
import { textCenter } from '../styles';
import { number, numbers, numberText, whatIsLmContainer, whatIsLmText } from './what-is-ln.styles';

export const WhatIsLm: React.FC = () => {
  const { mobile } = useBreakpoints() || {};
  return (
    <section className={whatIsLmContainer}>
      <Typography
        variant="l"
        size="body_short"
        color="text-primary"
        className={cx(textCenter, whatIsLmText)}
      >
        Мы&nbsp;объединяем на&nbsp;нашей платформе обычных клиентов и&nbsp;профессионалов, вдохновляем, даем
        возможность обмениваться опытом и&nbsp;делаем доступными проекты, товары и&nbsp;услуги
      </Typography>
      <div className={numbers}>
        <div className={number}>
          <HouseStoreIcon />
          <div>
            <Typography variant="h1">112</Typography>
            <Typography variant="m" size="body_short">
              магазинов
            </Typography>
          </div>
        </div>
        <div className={number}>
          <GeoIcon mobile={mobile} />
          <div>
            <Typography variant="h1">66</Typography>
            <Typography variant="m" size="body_short">
              городов
            </Typography>
          </div>
        </div>
        <div className={number}>
          <div style={{ width: '49px', height: '48px' }}>
            <HumanIcon mobile={mobile} />
          </div>
          <div className={number}>
            <Typography variant="h1">45&nbsp;000&nbsp;+</Typography>
            <Typography variant="m" size="body_short">
              сотрудников
            </Typography>
          </div>
        </div>
        <div className={number}>
          <PalitreIcon mobile={mobile} />
          <div>
            <Typography variant="h1">45&nbsp;+</Typography>
            <Typography variant="m" size="body_short">
              видов услуг
            </Typography>
          </div>
        </div>
        <div className={number}>
          <CoopIcon />
          <div className={numberText}>
            <Typography variant="h1">2&nbsp;000&nbsp;+</Typography>
            <Typography variant="m" size="body_short">
              поставщиков
              <br />и мерчантов
            </Typography>
          </div>
        </div>
        <div className={number}>
          <SamplesIcon mobile={mobile} />
          <div>
            <Typography variant="h1">390&nbsp;000&nbsp;+</Typography>
            <Typography variant="m" size="body_short">
              ассортимент товаров
            </Typography>
          </div>
        </div>
      </div>
    </section>
  );
};
