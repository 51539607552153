import { css } from 'linaria';

export const heading = css`
  margin-bottom: var(--space-200);
  color: var(--text-primary);
`;

export const headingMobileView = css`
  margin: var(--space-300) 0 var(--space-200);
  color: var(--text-primary);
`;
