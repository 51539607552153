import React from 'react';

interface SamplesIconProps {
  mobile?: boolean;
}

export const SamplesIcon: React.FC<SamplesIconProps> = ({ mobile }) => {
  const color = '#BF8900';
  return (
    <svg
      width="49"
      height="48"
      viewBox={`${mobile ? 0 : -2.5} 0 49 48`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M4.38118 32.4378L8.91868 6.73157C8.95039 6.53756 9.0204 6.3518 9.12464 6.18512C9.22887 6.01844 9.36524 5.87418 9.5258 5.76074C9.68636 5.6473 9.86789 5.56696 10.0598 5.52439C10.2517 5.48183 10.4502 5.4779 10.6437 5.51282L20.9937 7.33157C21.3847 7.40469 21.7315 7.62832 21.9594 7.9544C22.1873 8.28048 22.2781 8.68296 22.2124 9.07532L17.6562 34.9316C17.3442 36.6726 16.363 38.2223 14.9228 39.2491C13.4825 40.2758 11.6976 40.698 9.94993 40.4253C6.21868 39.8253 3.72493 36.1691 4.38118 32.4378Z"
        fill={color}
      />
      <path
        d="M4.38118 32.4378L8.91868 6.73157C8.95039 6.53756 9.0204 6.3518 9.12464 6.18512C9.22887 6.01844 9.36524 5.87418 9.5258 5.76074C9.68636 5.6473 9.86789 5.56696 10.0598 5.52439C10.2517 5.48183 10.4502 5.4779 10.6437 5.51282L20.9937 7.33157C21.3847 7.40469 21.7315 7.62832 21.9594 7.9544C22.1873 8.28048 22.2781 8.68296 22.2124 9.07532L17.6562 34.9316C17.3442 36.6726 16.363 38.2223 14.9228 39.2491C13.4825 40.2758 11.6976 40.698 9.94993 40.4253C6.21868 39.8253 3.72493 36.1691 4.38118 32.4378Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.6812 23.3995L33.3499 18.4308C33.5357 18.3621 33.7333 18.331 33.9312 18.3392C34.1292 18.3473 34.3235 18.3946 34.5031 18.4783C34.6826 18.562 34.8438 18.6804 34.9774 18.8267C35.1109 18.973 35.2142 19.1443 35.2812 19.3308L38.8812 29.1933C39.0139 29.5663 38.9938 29.9767 38.8252 30.335C38.6566 30.6932 38.3532 30.9703 37.9812 31.1058L13.3062 40.087"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.75 30.4688V39C38.75 39.3978 38.592 39.7794 38.3107 40.0607C38.0294 40.342 37.6478 40.5 37.25 40.5H11"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 36C12.2426 36 13.25 34.9926 13.25 33.75C13.25 32.5074 12.2426 31.5 11 31.5C9.75736 31.5 8.75 32.5074 8.75 33.75C8.75 34.9926 9.75736 36 11 36Z"
        fill={color}
      />
    </svg>
  );
};
