import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { Typography } from 'fronton-react';
import { getIsMobileView, getTextContent, getTitle } from 'features/page-model/ducks/get-model';
import { heading, headingMobileView } from './styles';

export const Content: FC = () => {
  const textContent = useSelector(getTextContent);
  const title = useSelector(getTitle);
  const isMobileView = useSelector(getIsMobileView);
  return (
    <div>
      <Typography variant="h1" as="h1" className={isMobileView ? headingMobileView : heading}>
        {title}
      </Typography>
      <ReactMarkdown>{textContent}</ReactMarkdown>
    </div>
  );
};
