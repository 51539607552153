import React from 'react';
import { AccordionContainer } from './style';

type Props = {
  children: JSX.Element | JSX.Element[];

  className?: string;
};

export const Accordion = ({ children, className = '' }: Props) => (
  <AccordionContainer className={className}>{children}</AccordionContainer>
);
