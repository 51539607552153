import { styled } from 'linaria/react';
import { css } from 'linaria';
import { breakpoints } from 'common/styles/breakpoints';

export const SupportBlock = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: var(--space-200);
  height: auto;
  margin-bottom: var(--space-800);
  ${breakpoints.from.desktop} {
    display: grid;
    grid-column-gap: var(--space-300);
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: var(--space-1000);
  }
`;

export const ContactsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: var(--space-250);
  ${breakpoints.from.tablet} {
    flex-direction: row;
    justify-content: space-between;
    gap: var(--space-800);
    margin-bottom: 90px;
  }
  ${breakpoints.only.largeDesktop} {
    gap: var(--space-300);
  }
  ${breakpoints.only.desktop} {
    justify-content: flex-start;
  }
`;

export const mainHeader = css`
  margin-bottom: var(--space-400);

  ${breakpoints.to.desktop} {
    margin-bottom: var(--space-300);
  }
`;
