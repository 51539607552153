import React, { useCallback } from 'react';
import { Layout, RegularButton, Typography } from 'fronton-react';
import { Remote } from '@okapi/remote';
import { AnalyticsPageMeta } from 'features/analytics';
import { useSelector } from 'react-redux';
import { getPathname } from 'features/page-model/ducks/get-model';
import { getIsAuthorized } from 'common/selectors';
import { useGaPageview } from 'features/analytics/hooks';
import { ChevronRightIcon } from '@fronton/icons-react';
import { analyticsUtils, patchUrlByLocale } from 'utils';
import { useOptimizedImages } from 'common';
import { useTranslation } from 'react-i18next';
import { buyIzolenta, contentWrapper, fullHeightPage, message } from './styles';

export const Page404: React.FC = () => {
  const path = useSelector(getPathname);
  const isAuthorized = useSelector(getIsAuthorized) ? '1' : '0';
  const { t } = useTranslation();

  const sandGaEvent = useCallback(() => {
    analyticsUtils.sendEvent(`GA:clickButton`, {
      elementType: 'button',
      elementBlock: 'landing404', // блок, в котором расположен элемент
      elementName: 'error404', // название блока (заголовок)
      elementText: 'Перейти на главную', // заголовок пункта
    });
  }, []);

  useGaPageview(path, isAuthorized, null, 'other');

  const optimizedImage = useOptimizedImages(
    'https://cdn.lemanapro.ru/lmru/image/upload/f_auto/q_auto/v1722329954/fudzi/navigation-microfronts/izolenta.png/izolenta.png',
    {
      rest: {
        h: '48',
      },
    },
  );

  return (
    <div className={fullHeightPage}>
      <AnalyticsPageMeta
        manualTitle="404 страница не найдена"
        manualDescription="Страница не найдена. Воспользуйтесь поиском, вернитесь на главную страницу или купите изоленту"
      />
      <Remote widget="header-footer/header" id="404-header" data={{ pathName: '/' }} />
      <Layout className={contentWrapper}>
        <div className={message}>
          <Typography variant="display" as="h1" color="text-primary">
            404
          </Typography>
          <Typography size="body_long" variant="m" as="p" color="text-primary">
            Страница не найдена. Воспользуйтесь поиском, вернитесь на главную страницу или купите изоленту.
          </Typography>
          <RegularButton
            variant="primary"
            size="l"
            as="a"
            href={patchUrlByLocale('/')}
            data-qa="to-main"
            onClick={sandGaEvent}
          >
            Перейти на главную
          </RegularButton>
        </div>
        <a
          href={patchUrlByLocale('/catalogue/izolyaciya-kabelya/izolenta-pvh/?01746=Синий')}
          className={buyIzolenta}
        >
          <picture>
            <source srcSet={`${optimizedImage.rest?.xx} 2x, ${optimizedImage.rest?.x} 1x`} />
            <img
              alt={t('404.imgAlt')}
              src={optimizedImage.rest?.x ?? undefined}
              srcSet={`${optimizedImage.rest?.xx} 2x, ${optimizedImage.rest?.x} 1x`}
              draggable={false}
            />
          </picture>
          <Typography variant="m" size="body_accent">
            {t('404.callToBuy')}
          </Typography>
          <ChevronRightIcon type="outline" size="l" />
        </a>
      </Layout>
      <Remote widget="header-footer/footer" id="404-footer" data={{ pathName: '/' }} />
    </div>
  );
};
