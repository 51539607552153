import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  getStubRegionNamePrepositional,
  getStubRegionOrderFrom,
} from 'features/stub-region/ducks/get-stub-region-data';
import { Typography } from 'fronton-react';
import { useTranslation } from 'react-i18next';
import { useBreakpoints } from 'common/hooks/use-breakpoints';
import { MainInfo } from '../main-info';
import { noteText } from './styles';

export const DATA_QA_MAIN_INFO_WITHOUT_DELIVERY_NOTE = 'main-info-without-delivery-note';

export const MainInfoWithoutDelivery: FC = () => {
  const regionName = useSelector(getStubRegionNamePrepositional) || '';
  const {
    regionLink = '',
    regionNamePrepositional,
    regionNameGenitive,
  } = useSelector(getStubRegionOrderFrom) || {};
  const { mobile, smMobile } = useBreakpoints() || {};
  const { t } = useTranslation();
  const legalName = t('legalName');

  return (
    <MainInfo
      title={`К\u00A0сожалению, в\u00A0${regionName} нет магазинов ${legalName}`}
      buttonText={`Перейти на сайт ${regionNameGenitive}`}
      regionLink={regionLink}
    >
      <Typography
        variant={mobile || smMobile ? 'm' : 'l'}
        size="body_long"
        className={noteText}
        data-qa={DATA_QA_MAIN_INFO_WITHOUT_DELIVERY_NOTE}
      >
        Ближайший к&nbsp;вам магазин находится в&nbsp;{regionNamePrepositional}
      </Typography>
    </MainInfo>
  );
};
