import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  getStubRegionDeliveryFrom,
  getStubRegionName,
  getStubRegionNamePrepositional,
} from 'features/stub-region/ducks/get-stub-region-data';
import { LinkButton, Typography } from 'fronton-react';
import { MapPinIcon, ShoppingCartSimpleIcon, TruckIcon } from '@fronton/icons-react';
import { analyticsUtils } from 'utils';
import { useBreakpoints } from 'common/hooks/use-breakpoints';
import { useTranslation } from 'react-i18next';
import { MainInfo } from '../main-info';
import {
  courierInfo,
  deliveryRules,
  deliveryRulesLink,
  howToOrderInfo,
  howToOrderTitle,
  noteText,
} from './styles';

export const DATA_QA_MAIN_INFO_WITH_DELIVERY_NOTE = 'main-info-with-delivery-note';
export const DATA_QA_MAIN_INFO_WITH_DELIVERY_RULES = 'main-info-with-delivery-rules';
export const DATA_QA_MAIN_INFO_WITH_DELIVERY_RULES_LINK = 'main-info-with-delivery-rules-link';

export const getNote = (regionNameGenitive: string, regionName: string, legalName: string) =>
  `В\u00A0вашем городе нет магазина ${legalName}, но\u00A0вы\u00A0можете заказать товары и\u00A0спроектировать
   комнату на\u00A0сайте ${regionNameGenitive} с\u00A0доставкой в\u00A0${regionName}
   курьером или в\u00A0пункты выдачи вашего города.`;

export const MainInfoWithDelivery: FC = () => {
  const regionNamePrepositional = useSelector(getStubRegionNamePrepositional) || '';
  const regionName = useSelector(getStubRegionName) || '';
  const { mobile, smMobile } = useBreakpoints() || {};
  const { regionLink = '', note, regionNameGenitive = '' } = useSelector(getStubRegionDeliveryFrom) || {};
  const { t } = useTranslation();
  const legalName = t('legalName');

  const title = `Доставляем в ${regionName}`;

  const sandGaDeliveryRulesClickEvent = useCallback(() => {
    analyticsUtils.sendEvent(`GA:clickButton`, {
      elementType: 'button',
      elementBlock: 'stubPage', // блок, в котором расположен элемент
      elementName: title, // название блока (заголовок)
      elementText: `Условия доставки ${legalName} в ${regionNamePrepositional}`, // заголовок пункта
    });
  }, [legalName, regionNamePrepositional, title]);

  const isMobile = mobile || smMobile;
  const textVariant = isMobile ? 'm' : 'l';

  return (
    <MainInfo title={title} buttonText="Перейти к покупкам" regionLink={regionLink}>
      <Typography variant={textVariant} size="body_accent" className={courierInfo}>
        Курьером и в пункты выдачи от 1 ₽
      </Typography>
      <Typography
        variant={textVariant}
        size="body_long"
        className={noteText}
        data-qa={DATA_QA_MAIN_INFO_WITH_DELIVERY_NOTE}
      >
        {getNote(regionNameGenitive, regionName, legalName)}
      </Typography>
      <Typography
        variant="m"
        size="body_long"
        className={deliveryRules}
        data-qa={DATA_QA_MAIN_INFO_WITH_DELIVERY_RULES}
      >
        {note}
      </Typography>
      <LinkButton
        as="a"
        size="m"
        variant="underline"
        className={deliveryRulesLink}
        href={`https://${regionLink}.lemanapro.ru/service/dostavka/`}
        onClick={sandGaDeliveryRulesClickEvent}
        data-qa={DATA_QA_MAIN_INFO_WITH_DELIVERY_RULES_LINK}
      >
        Условия доставки {legalName} в&nbsp;{regionNamePrepositional}
      </LinkButton>
      <Typography variant="l" size="body_accent" className={howToOrderTitle}>
        Как оформить заказ:
      </Typography>
      <div className={howToOrderInfo}>
        <ShoppingCartSimpleIcon type="outline" size="l" color="text-primary" />
        <Typography variant="m" size="body_long">
          Выберите и добавьте товары в корзину
        </Typography>
        <TruckIcon type="outline" size="l" color="text-primary" />
        <Typography variant="m" size="body_long">
          Для доставки курьером выберите опцию «Доставка», укажите адрес в {regionNamePrepositional},
          выберите нужную дату и оплатите заказ
        </Typography>
        <MapPinIcon type="outline" size="l" color="text-primary" />
        <Typography variant="m" size="body_long">
          Для доставки в пункт выдачи выберите опцию «Самовывоз», затем удобный ПВЗ на карте и оплатите заказ
        </Typography>
      </div>
    </MainInfo>
  );
};
