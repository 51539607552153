export type HttpCode = 200 | 201 | 400 | 404 | 500;

export enum HttpStatus {
  NOT_FOUND = 404,
  SERVER_ERROR = 500,
}

export type HttpErrorResponse = {
  code: number;
  error: string;
  message: string;
  status?: number;
};

export type RejectConfig = {
  rejectWithValue: (code: number | undefined) => void;
};
