import React from 'react';
import { Layout, Typography } from 'fronton-react';
import { Remote } from '@okapi/remote';
import { SeoDescriptionSection } from 'features/seo-description';
import { ContactUnit } from 'common/components/contact-unit';
import { AnalyticsPageMeta } from 'features/analytics';
import { ChatsCircleColoredIcon, HeadsetColorIcon, LeroyStoreIcon } from 'common/icons';
import { BreadcrumbsSection } from 'features/breadcrumbs';
import { SupportUnit } from 'common/components/support-unit';
import { useSelector } from 'react-redux';
import { getIsAuthorized } from 'common/selectors';
import { patchUrlByLocale } from 'utils';
import { useGaPageview } from 'features/analytics/hooks';
import { getIsMobileView, getPathname, getRegionId } from 'features/page-model/ducks/get-model';
import { useTranslation } from 'react-i18next';
import { ContactsBlock, mainHeader, SupportBlock } from './styles';

export const Contacts: React.FC = () => {
  const { t } = useTranslation();

  const supportInfo = [
    {
      icon: <ChatsCircleColoredIcon />,
      title: t('contacty.feedback.header'),
      text: t('contacty.feedback.description'),
      linkText: t('contacty.feedback.action'),
      link: '/company/obratnaya-svyaz/',
    },
    {
      icon: <HeadsetColorIcon />,
      title: t('contacty.support.header'),
      text: t('contacty.support.description'),
      linkText: t('contacty.support.action'),
      link: `tel:${t('contacty.support.action')}`,
    },
    {
      icon: <LeroyStoreIcon />,
      title: t('contacty.shops.header'),
      text: t('contacty.shops.description'),
      linkText: t('contacty.shops.action'),
      link: '/shop/',
    },
  ];
  const contactInfo = [
    {
      title: t('contacty.hq.header'),
      text: [t('contacty.hq.address'), ' ', `${t('contacty.hq.notice')}`],
      linkText: t('contacty.hq.phone'),
      link: `tel:${t('contacty.hq.phone')}`,
    },
    {
      title: t('contacty.legal.header'),
      text: [
        t('contacty.legal.description1'),
        t('contacty.legal.description2'),
        t('contacty.legal.description3'),
      ],
    },
  ];

  const path = useSelector(getPathname);
  const isAuthorized = useSelector(getIsAuthorized) ? '1' : '0';
  const regionId = useSelector(getRegionId);
  const isMobileView = useSelector(getIsMobileView);

  useGaPageview(path, isAuthorized, regionId);

  return (
    <>
      <AnalyticsPageMeta />
      {!isMobileView && <Remote widget="header-footer/header" id="contacts-header" />}
      <Layout>
        <BreadcrumbsSection />
        <Typography variant="h1" as="h1" className={mainHeader} color="text-primary">
          {t('contacty.header')}
        </Typography>
        <SupportBlock>
          {supportInfo.map(({ title, text, linkText, link, icon }) => (
            <SupportUnit
              key={title}
              title={title}
              text={text}
              linkText={linkText}
              link={patchUrlByLocale(link)}
              icon={icon}
            />
          ))}
        </SupportBlock>
        <ContactsBlock>
          {contactInfo.map(({ title, text, linkText, link }) => (
            <ContactUnit
              key={title}
              title={title}
              text={text}
              linkText={linkText}
              link={patchUrlByLocale(link)}
            />
          ))}
        </ContactsBlock>
        <SeoDescriptionSection />
      </Layout>
      {!isMobileView && <Remote widget="header-footer/footer" id="contacts-footer" />}
    </>
  );
};
