import { css } from 'linaria';
import { breakpoints } from 'common/styles/breakpoints';

export const whatIsLmContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: var(--space-500);
  ${breakpoints.from.tablet} {
    padding-top: var(--space-800);
  }
`;

export const whatIsLmText = css`
  display: block;
  max-width: 650px;
  margin: 0 auto;
  line-height: 26px !important;
`;
export const number = css`
  align-items: flex-start;
  svg {
    width: 48px !important;
    height: 48px !important;
  }
`;

export const numberText = css`
  max-width: 141px;
`;

export const numbers = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-top: var(--space-500);
  padding-right: var(--space-100);
  padding-left: var(--space-100);
  column-gap: var(--space-500);
  color: var(--text-primary);
  row-gap: var(--space-400);
  ${breakpoints.only.tablet} {
    padding-top: var(--space-750);
  }
  svg {
    width: var(--space-600);
    height: auto;
  }
  & > div {
    display: flex;
    flex-direction: column;
    gap: var(--space-100);
    & > div {
      display: flex;
      flex-direction: column;
      gap: var(--space-50);
    }
    ${breakpoints.from.tablet} {
      flex-direction: row;
    }
    ${breakpoints.from.largeDesktop} {
      &:nth-child(1) {
        grid-area: one;
      }
      &:nth-child(2) {
        grid-area: two;
      }
      &:nth-child(3) {
        grid-area: three;
      }
      &:nth-child(4) {
        grid-area: four;
      }
      &:nth-child(5) {
        grid-area: five;
      }
      &:nth-child(6) {
        grid-area: six;
      }
    }
  }

  ${breakpoints.from.tablet} {
    padding-top: var(--space-750);
    padding-right: var(--space-975);
    padding-left: var(--space-975);
    row-gap: var(--space-750);
    column-gap: 130px;
  }

  ${breakpoints.from.desktop} {
    row-gap: var(--space-800);
  }

  ${breakpoints.from.largeDesktop} {
    grid-template-areas:
      '. one two .'
      'three four five six';
    grid-template-columns: repeat(4, auto);
    padding-right: 0;
    padding-left: 0;
  }
`;
