import { styled } from 'linaria/react';
import { css } from 'linaria';
import { breakpoints } from 'common/styles/breakpoints';

export const SupportUnitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: var(--space-300);
  border: 1px solid var(--control-minor);
  border-radius: var(--rounding-l);
  ${breakpoints.only.mobile} {
    padding: var(--space-200);
  }
`;
export const HeaderBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--space-100);
  ${breakpoints.from.desktop} {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  margin-right: var(--space-100);
  ${breakpoints.from.desktop} {
    margin-bottom: var(--space-150);
  }
`;
export const supportTitle = css`
  line-height: 28px !important;
`;
export const supportText = css`
  margin-bottom: var(--space-150);
  ${breakpoints.from.desktop} {
    padding-bottom: var(--space-150);
    margin-bottom: auto;
  }
`;
export const supportLink = css`
  justify-self: flex-end;
  span {
    color: var(--brand-dark) !important;
  }
  &:hover {
    span {
      color: var(--control-hover-accent) !important;
    }
  }
`;
