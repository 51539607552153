import React from 'react';

export const PlatformIcon: React.FC = () => (
  <svg width="171" height="170" viewBox="0 0 171 170" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_259_1325)">
      <rect x="71" y="132.5" width="29" height="12" fill="#D0DDD8" stroke="#21282B" strokeWidth="3" />
      <rect
        x="39"
        y="67.4999"
        width="93"
        height="65"
        rx="6.5"
        fill="white"
        stroke="#21282B"
        strokeWidth="3"
      />
      <path
        d="M39 73.9999C39 70.41 41.9101 67.4999 45.5 67.4999H125.5C129.09 67.4999 132 70.41 132 73.9999V123.5H39V73.9999Z"
        fill="white"
        stroke="#21282B"
        strokeWidth="3"
      />
      <path d="M57.5 144.5H113.5" stroke="#21282B" strokeWidth="3" />
      <g clipPath="url(#clip1_259_1325)">
        <rect
          width="64"
          height="84"
          transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 117.5 37.9999)"
          fill="#FDC300"
        />
        <path d="M117.5 11.9999L117.5 37.9999L53.5 37.9999L117.5 11.9999Z" fill="#FDC300" />
      </g>
      <path
        d="M85.5 88.4999C88.5376 88.4999 91 90.9623 91 93.9999L91 102C91 105.037 88.5376 107.5 85.5 107.5C82.4624 107.5 80 105.037 80 102L80 93.9999C80 90.9623 82.4624 88.4999 85.5 88.4999Z"
        fill="white"
        stroke="#21282B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.5 63.9999L80.5 68.9999L85.5 63.9999L90.5 68.9999L95.5 63.9999"
        stroke="#21282B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="85.5"
        cy="67.9999"
        r="22.5"
        fill="white"
        stroke="#21282B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.5 67.9999H93.5"
        stroke="#21282B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.5 67.9999L85.5 88.4999"
        stroke="#21282B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94 88.4999L94 99.9999C94 100.276 93.7761 100.5 93.5 100.5L77.5 100.5C77.2239 100.5 77 100.276 77 99.9999L77 88.4999L94 88.4999Z"
        fill="#D0DDD8"
        stroke="#21282B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_259_1325">
        <rect width="170" height="170" fill="white" transform="translate(0.5)" />
      </clipPath>
      <clipPath id="clip1_259_1325">
        <rect
          width="64"
          height="110"
          fill="white"
          transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 117.5 11.9999)"
        />
      </clipPath>
    </defs>
  </svg>
);
