/* eslint-disable max-lines */
import { Divider, LinkButton, Typography } from 'fronton-react';
import React from 'react';
import { cx } from 'linaria';
import { WithoutPropagationWrapper } from 'common/components/without-propagation-wrapper';
import {
  customDottedText,
  dottedOrderedList,
  li,
  paragraph,
  table,
  tableItem,
  tableRowFirstItem,
  textMinor,
} from './styles';

const getProductsProperQuality = (legalName: string, isHideBrandsExperimentAvailable: boolean) => [
  {
    title: 'Возврат товаров надлежащего качества',
    titleText: 'Возврат товаров надлежащего качества',
    content: (
      <>
        <Typography variant="s" size="body_long" color="text-primary">
          Вы можете вернуть товары надлежащего качества в любой магазин {legalName} в России.
          В&nbsp;магазинах мы также принимаем к&nbsp;возврату товары, купленные в нашем интернет-магазине.
          Вернуть товар можно в течение 100 дней с&nbsp;даты покупки. Для&nbsp;владельцев{' '}
          <WithoutPropagationWrapper>
            <LinkButton as="a" size="s" variant="underline" href="/kluch-karta-dom/">
              Ключ-картой для дома
            </LinkButton>
          </WithoutPropagationWrapper>{' '}
          или{' '}
          <WithoutPropagationWrapper>
            <LinkButton as="a" size="s" variant="underline" href="/kluch-karta-pro/">
              Ключ-картой ПРО
            </LinkButton>
          </WithoutPropagationWrapper>{' '}
          этот срок продлевается до 1 года, если&nbsp;карта предъявлялась при&nbsp;покупке товара. Вернуть
          товары наших{' '}
          {isHideBrandsExperimentAvailable ? (
            'собственных торговых марок'
          ) : (
            <WithoutPropagationWrapper>
              <LinkButton as="a" size="s" variant="underline" href="/brands/" data-qa="link-to-brands">
                собственных торговых марок
              </LinkButton>
            </WithoutPropagationWrapper>
          )}{' '}
          можно в течение 1&nbsp;года, а&nbsp;при&nbsp;покупке{' '}
          <WithoutPropagationWrapper>
            <LinkButton as="a" size="s" variant="underline" href="/kluch-karta-dom/">
              с Ключ-картой
            </LinkButton>
          </WithoutPropagationWrapper>{' '}
          или&nbsp;
          <WithoutPropagationWrapper>
            <LinkButton as="a" size="s" variant="underline" href="/kluch-karta-pro/">
              Ключ-картой ПРО
            </LinkButton>
          </WithoutPropagationWrapper>{' '}
          – в течение 2 лет.
        </Typography>
        <Typography variant="s" size="body_long" color="text-primary" className={paragraph}>
          В соответствии со статьёй 25 ЗоЗПП мы принимаем к возврату товары, которые не использовались,
          сохранили товарный вид и&nbsp;потребительские свойства, со всеми заводскими пломбами и ярлыками.
          Товары, приобретённые комплектом – состоящие из&nbsp;двух и&nbsp;более частей, возвращаются
          комплектом.
        </Typography>
        <Typography variant="s" size="body_long" color="text-primary" className={paragraph}>
          Для возврата товара надлежащего качества нужен кассовый чек или другой документ, подтверждающий
          оплату. Возврат без&nbsp;чека возможен при наличии{' '}
          <WithoutPropagationWrapper>
            <LinkButton as="a" size="s" variant="underline" href="/kluch-karta-dom/">
              Ключ-карты
            </LinkButton>
          </WithoutPropagationWrapper>{' '}
          или{' '}
          <WithoutPropagationWrapper>
            <LinkButton as="a" size="s" variant="underline" href="/kluch-karta-pro/">
              Ключ-карты ПРО
            </LinkButton>
          </WithoutPropagationWrapper>
          , которую вы предъявляли при покупке. Если товар приобретён в интернет-магазине, для возврата
          понадобится электронный чек или номер заказа.
        </Typography>
        <Typography variant="s" size="body_long" color="text-primary" className={paragraph}>
          Товары не являются товарами надлежащего качества, если:
        </Typography>
        <ol className={dottedOrderedList}>
          <li className={cx(li, paragraph)}>
            <Typography
              variant="s"
              size="body_long"
              as="p"
              color="text-primary"
              className={customDottedText}
            >
              отсутствует этикетка/упаковка с информацией (партия, условия эксплуатации, срок годности и
              т.д.);
            </Typography>
          </li>
          <li className={cx(li, paragraph)}>
            <Typography
              variant="s"
              size="body_long"
              as="p"
              color="text-primary"
              className={customDottedText}
            >
              осталась только часть пачки/упаковки товара (например, половина коробки с плиткой, половина
              сухой смеси и&nbsp;т.д.);
            </Typography>
          </li>
          <li className={cx(li, paragraph)}>
            <Typography
              variant="s"
              size="body_long"
              as="p"
              color="text-primary"
              className={customDottedText}
            >
              это товар с индивидуальными характеристиками: сделан под индивидуальный заказ, особенные
              размеры, цвет; на&nbsp;отрез (за&nbsp;исключением товара, полностью соответствующего изначально
              проданному);
            </Typography>
          </li>
          <li className={cx(li, paragraph)}>
            <Typography
              variant="s"
              size="body_long"
              as="p"
              color="text-primary"
              className={customDottedText}
            >
              это образец товара, проданный с экспозиции в торговом зале магазина;
            </Typography>
          </li>
          <li className={cx(li, paragraph)}>
            <Typography
              variant="s"
              size="body_long"
              as="p"
              color="text-primary"
              className={customDottedText}
            >
              истёк срок годности товара;
            </Typography>
          </li>
          <li className={cx(li, paragraph)}>
            <Typography
              variant="s"
              size="body_long"
              as="p"
              color="text-primary"
              className={customDottedText}
            >
              это товар, проданный вместе с услугой колеровки, резки, распила, пошива или оверлока.
            </Typography>
          </li>
        </ol>
        <Typography variant="s" size="body_long" color="text-primary" className={paragraph}>
          Если вы не уверены, что ваш товар можно вернуть или в вашем городе нет нашего магазина, напишите в{' '}
          <WithoutPropagationWrapper>
            <LinkButton
              as="a"
              size="s"
              variant="underline"
              href="/company/obratnaya-svyaz/?utm_referrer=https%3A%2F%2Fwww.lemanapro.ru%2Fservice%2Fvozvrat-tovara%2F"
            >
              клиентскую поддержку
            </LinkButton>
          </WithoutPropagationWrapper>{' '}
          на сайте.
        </Typography>
      </>
    ),
  },
  {
    title: 'Сроки возврата товаров надлежащего качества, не подлежащих обмену',
    titleText: 'Сроки возврата товаров надлежащего качества, не подлежащих обмену',
    content: (
      <>
        <Typography variant="s" size="body_long" color="text-primary" className={paragraph}>
          Мы принимаем товары, которые входят в Перечень товаров, не подлежащих обмену (Перечень утверждён
          Постановлением Правительства РФ от 31 декабря 2020 г. № 2463). Среди товаров перечня есть небольшие
          исключения, которые мы&nbsp;не&nbsp;сможем принять к возврату.
        </Typography>
        <div className={table}>
          <div className={tableItem}>
            <div className={tableRowFirstItem}>
              <Typography variant="m" size="body_short" as="p" color="text-primary">
                100 дней со дня покупки
              </Typography>
              <Typography variant="caption" as="p" color="brand-primary">
                1 год с Ключ-картой и&nbsp;Ключ-картой&nbsp;ПРО
              </Typography>
            </div>
            <ol className={dottedOrderedList}>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Текстильные товары (товары по&nbsp;цене за единицу длины)
                </Typography>
              </li>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Швейные и трикотажные изделия
                </Typography>
              </li>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Изделия и материалы, полностью или частично изготовленные из&nbsp;полимерных материалов
                  и&nbsp;контактирующие с пищевыми продуктами
                </Typography>
              </li>
            </ol>
          </div>
          <Divider />
          <div className={tableItem}>
            <div className={tableRowFirstItem}>
              <Typography variant="m" size="body_short" as="p" color="text-primary">
                30 дней со дня покупки
              </Typography>
              <Typography variant="caption" as="p" color="brand-primary">
                100 дней с Ключ-картой и&nbsp;Ключ-картой&nbsp;ПРО
              </Typography>
            </div>
            <ol className={dottedOrderedList}>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Товары бытовой химии, пестициды и агрохимикаты*
                </Typography>
              </li>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Технически сложные товары бытового назначения, на которые установлены гарантийные сроки не
                  меньше одного года**
                </Typography>
              </li>
            </ol>
          </div>
          <Divider />
          <div className={tableItem}>
            <div className={tableRowFirstItem}>
              <Typography variant="m" size="body_short" as="p" color="text-primary">
                7 дней со дня покупки
              </Typography>
              <Typography variant="caption" as="p" color="brand-primary">
                30 дней с Ключ-картой и&nbsp;Ключ-картой&nbsp;ПРО
              </Typography>
            </div>
            <ol className={dottedOrderedList}>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Предметы личной гигиены (мочалки, расчёски)
                </Typography>
              </li>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Парфюмерно-косметические товары (если не истёк срок годности)*
                </Typography>
              </li>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Непериодические издания
                </Typography>
              </li>
            </ol>
          </div>
          <Divider />
          <div className={tableItem}>
            <div className={tableRowFirstItem}>
              <Typography variant="m" size="body_short" as="p" color="text-primary">
                24 часа с момента покупки
              </Typography>
            </div>
            <ol className={dottedOrderedList}>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Животные и растения
                </Typography>
              </li>
            </ol>
          </div>
          <Divider />
          <div className={tableItem}>
            <div className={tableRowFirstItem}>
              <Typography variant="m" size="body_short" as="p" color="text-primary">
                Не подлежат возврату
              </Typography>
            </div>
            <ol className={dottedOrderedList}>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Товары для профилактики и&nbsp;лечения заболеваний в&nbsp;домашних условиях
                </Typography>
              </li>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Автомобили и мотовелотовары, прицепы к ним, номерные агрегаты
                </Typography>
              </li>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Кабели и провода, проданные на&nbsp;отрез, не подлежат возврату.
                </Typography>
              </li>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Товары бытовой химии, пестициды, агрохимикаты и парфюмерно-косметические товары, если
                  заводская пломба повреждена или&nbsp;не&nbsp;предусмотрена производителем. Например, если
                  моющее средство продавалось без&nbsp;защитной пломбы на крышке, мы&nbsp;не&nbsp;сможем его
                  принять к&nbsp;возврату.
                </Typography>
              </li>
            </ol>
          </div>
        </div>
        <Typography variant="s" size="body_long" as="p" color="text-minor" className={textMinor}>
          * Срок годности товара на момент возврата не должен быть менее 30 дней до его истечения.
        </Typography>
        <Typography variant="s" size="body_long" as="p" color="text-minor" className={textMinor}>
          ** При возврате технически сложного товара мы забираем его на 10 дней для проверки.
        </Typography>
      </>
    ),
  },
  {
    title: isHideBrandsExperimentAvailable ? (
      'Сроки возврата товаров надлежащего качества наших торговых марок'
    ) : (
      <>
        Сроки возврата товаров надлежащего качества{' '}
        <WithoutPropagationWrapper>
          <LinkButton as="a" size="m" variant="underline" href="/brands/" data-qa="link-to-brands">
            наших
          </LinkButton>
        </WithoutPropagationWrapper>{' '}
        торговых марок
      </>
    ),
    titleText: 'Сроки возврата товаров надлежащего качества наших торговых марок',
    content: (
      <>
        <Typography variant="s" size="body_long" color="text-primary" className={paragraph}>
          Мы принимаем товары, которые входят в Перечень товаров, не подлежащих обмену (Перечень утверждён
          Постановлением Правительства РФ от 31 декабря 2020 г. № 2463). Среди товаров перечня есть небольшие
          исключения, которые мы&nbsp;не&nbsp;сможем принять к возврату.
        </Typography>
        <div className={table}>
          <div className={tableItem}>
            <div className={tableRowFirstItem}>
              <Typography variant="m" size="body_short" as="p" color="text-primary">
                365 дней со дня покупки
              </Typography>
              <Typography variant="caption" as="p" color="brand-primary">
                730 дней с Ключ-картой и&nbsp;Ключ-картой&nbsp;ПРО
              </Typography>
            </div>
            <ol className={dottedOrderedList}>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Текстильные товары (товары по&nbsp;цене за единицу длины)
                </Typography>
              </li>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Швейные и трикотажные изделия
                </Typography>
              </li>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Изделия и материалы, полностью или частично изготовленные из&nbsp;полимерных материалов
                  и&nbsp;контактирующие с пищевыми продуктами
                </Typography>
              </li>
            </ol>
          </div>
          <Divider />
          <div className={tableItem}>
            <div className={tableRowFirstItem}>
              <Typography variant="m" size="body_short" as="p" color="text-primary">
                100 дней со дня покупки
              </Typography>
              <Typography variant="caption" as="p" color="brand-primary">
                1 год с Ключ-картой и&nbsp;Ключ-картой&nbsp;ПРО
              </Typography>
            </div>
            <ol className={dottedOrderedList}>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Технически сложные товары бытового назначения, на которые установлены гарантийные сроки не
                  менее одного года**
                </Typography>
              </li>
            </ol>
          </div>
          <Divider />
          <div className={tableItem}>
            <div className={tableRowFirstItem}>
              <Typography variant="m" size="body_short" as="p" color="text-primary">
                30 дней со дня покупки
              </Typography>
              <Typography variant="caption" as="p" color="brand-primary">
                100 дней с Ключ-картой и&nbsp;Ключ-картой&nbsp;ПРО
              </Typography>
            </div>
            <ol className={dottedOrderedList}>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Товары бытовой химии, пестициды и агрохимикаты*
                </Typography>
              </li>
            </ol>
          </div>
          <Divider />
          <div className={tableItem}>
            <div className={tableRowFirstItem}>
              <Typography variant="m" size="body_short" as="p" color="text-primary">
                7 дней со дня покупки
              </Typography>
              <Typography variant="caption" as="p" color="brand-primary">
                30 дней с Ключ-картой и&nbsp;Ключ-картой&nbsp;ПРО
              </Typography>
            </div>
            <ol className={dottedOrderedList}>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Предметы личной гигиены
                </Typography>
              </li>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Парфюмерно-косметические товары*
                </Typography>
              </li>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Непериодические издания
                </Typography>
              </li>
            </ol>
          </div>
          <Divider />
          <div className={tableItem}>
            <div className={tableRowFirstItem}>
              <Typography variant="m" size="body_short" as="p" color="text-primary">
                24 часа с момента покупки
              </Typography>
            </div>
            <ol className={dottedOrderedList}>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Животные и растения
                </Typography>
              </li>
            </ol>
          </div>
          <Divider />
          <div className={tableItem}>
            <div className={tableRowFirstItem}>
              <Typography variant="m" size="body_short" as="p" color="text-primary">
                Не подлежат возврату
              </Typography>
            </div>
            <ol className={dottedOrderedList}>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Товары для профилактики и&nbsp;лечения заболеваний в&nbsp;домашних условиях
                </Typography>
              </li>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Автомобили и мотовелотовары, прицепы к ним, номерные агрегаты
                </Typography>
              </li>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Кабели и провода, проданные на&nbsp;отрез, не подлежат возврату.
                </Typography>
              </li>
              <li className={cx(li, paragraph)}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  color="text-primary"
                  className={customDottedText}
                >
                  Не принимаются к&nbsp;возврату товары бытовой химии, пестициды, агрохимикаты
                  и&nbsp;парфюмерно-косметические товары, если заводская пломба повреждена
                  или&nbsp;изначально не&nbsp;предусмотрена производителем. Например, если&nbsp;вы купили
                  моющее средство без&nbsp;защитной пломбы на крышке, мы&nbsp;не&nbsp;сможем его принять
                  к&nbsp;возврату.
                </Typography>
              </li>
            </ol>
          </div>
        </div>
        <Typography variant="s" size="body_long" as="p" color="text-minor" className={textMinor}>
          * Срок годности товара на момент возврата не должен быть менее 30 дней до его истечения.
        </Typography>
        <Typography variant="s" size="body_long" as="p" color="text-minor" className={textMinor}>
          ** При возврате технически сложного товара мы забираем его на 10 дней для проверки.
        </Typography>
      </>
    ),
  },
];
const getProductsImproperQuality = (legalName: string) => [
  {
    title: 'Возврат товаров с браком',
    content: (
      <>
        <Typography variant="s" size="body_long" color="text-primary" className={paragraph}>
          Если вы обнаружили брак или другой недостаток в товаре, вы можете обратиться в любой магазин{' '}
          {legalName} в России.
        </Typography>
        <Typography variant="s" size="body_long" color="text-primary" className={paragraph}>
          До приезда в магазин рекомендуем вам отправить обращение в клиентскую поддержку на сайте. В
          обращении опишите недостатки товара и приложите фотографии. В этом случае мы заранее сообщим вам о
          предварительном решении по вашему обращению.
        </Typography>
        <Typography variant="s" size="body_long" color="text-primary" className={paragraph}>
          Для возврата вам понадобится кассовый чек или другой документ, подтверждающий оплату. Возврат без
          чека возможен при&nbsp;наличии Ключ-карты или Ключ-карты ПРО, которую вы предъявляли при покупке.
        </Typography>
        <Typography variant="s" size="body_long" color="text-primary" className={paragraph}>
          В случае отсутствия кассового чека либо{' '}
          <WithoutPropagationWrapper>
            <LinkButton as="a" size="s" variant="underline" href="/kluch-karta-dom/">
              Ключ-карты
            </LinkButton>
          </WithoutPropagationWrapper>{' '}
          или{' '}
          <WithoutPropagationWrapper>
            <LinkButton as="a" size="s" variant="underline" href="/kluch-karta-pro/">
              Ключ-карты ПРО
            </LinkButton>
          </WithoutPropagationWrapper>
          , которую вы предъявляли при покупке возвращаемого товара, вам будет нужно доказать факт
          приобретения товара в сети {legalName}.
        </Typography>
        <Typography variant="s" size="body_long" color="text-primary" className={paragraph}>
          Перед сдачей технически сложных товаров с бензиновыми моторами в Сервисный Центр магазина не
          забывайте сливать остатки топлива и масла из товара.
        </Typography>
        <Typography variant="s" size="body_long" color="text-primary" className={paragraph}>
          Возврат товаров на гарантии и без установленного гарантийного срока происходит по разным правилам.
        </Typography>
      </>
    ),
  },
  {
    title: 'Товары ненадлежащего качества с гарантией',
    content: (
      <>
        <Typography variant="s" size="body_long" color="text-primary" className={paragraph}>
          Если на товар установлен гарантийный срок, и он не истёк, вы можете передать товар в сервисный
          центр любого магазина {legalName} для проверки качества или ремонта по гарантии. До приезда в
          магазин рекомендуем заполнить форму обратной связи на сайте для предварительной консультации по
          возврату.
        </Typography>
        <Typography variant="s" size="body_long" color="text-primary" className={paragraph}>
          Также вы можете самостоятельно обратиться в авторизованный сервисный центр производителя, указанный
          в гарантийном талоне. Если сервисный центр признает товар не пригодным для ремонта, обратитесь в
          наш магазин для возврата или обмена.
        </Typography>
        <Typography variant="s" size="body_long" color="text-primary" className={paragraph}>
          Для возврата товара в магазине вам понадобится:
        </Typography>
        <ol className={dottedOrderedList}>
          <li className={cx(li, paragraph)}>
            <Typography
              variant="s"
              size="body_long"
              as="p"
              color="text-primary"
              className={customDottedText}
            >
              гарантийный талон либо заключение авторизованного сервисного центра;
            </Typography>
          </li>
          <li className={cx(li, paragraph)}>
            <Typography
              variant="s"
              size="body_long"
              as="p"
              color="text-primary"
              className={customDottedText}
            >
              товар со всеми документами и аксессуарами;
            </Typography>
          </li>
          <li className={cx(li, paragraph)}>
            <Typography
              variant="s"
              size="body_long"
              as="p"
              color="text-primary"
              className={customDottedText}
            >
              кассовый чек или другой документ, подтверждающий оплату, либо Ключ-карта или Ключ-карта ПРО,
              которую вы предъявляли при покупке.
            </Typography>
          </li>
        </ol>
      </>
    ),
  },
  {
    title: 'Товары ненадлежащего качества без гарантии',
    content: (
      <>
        <Typography variant="s" size="body_long" color="text-primary" className={paragraph}>
          Если на товар не установлен гарантийный срок, но вы хотите его вернуть, вы можете обратиться в
          магазин в течение 2&nbsp;лет с&nbsp;даты покупки.
        </Typography>
        <Typography variant="s" size="body_long" color="text-primary" className={paragraph}>
          Для возврата в магазине вам понадобится:
        </Typography>
        <ol className={dottedOrderedList}>
          <li className={cx(li, paragraph)}>
            <Typography
              variant="s"
              size="body_long"
              as="p"
              color="text-primary"
              className={customDottedText}
            >
              экспертное заключение, подтверждающее наличие производственного брака в товаре;
            </Typography>
          </li>
          <li className={cx(li, paragraph)}>
            <Typography
              variant="s"
              size="body_long"
              as="p"
              color="text-primary"
              className={customDottedText}
            >
              товар со всеми документами и аксессуарами;
            </Typography>
          </li>
          <li className={cx(li, paragraph)}>
            <Typography
              variant="s"
              size="body_long"
              as="p"
              color="text-primary"
              className={customDottedText}
            >
              кассовый чек или другой документ, подтверждающий оплату, либо Ключ-карта или Ключ-карта ПРО,
              которую вы&nbsp;предъявляли при покупке.
            </Typography>
          </li>
        </ol>
      </>
    ),
  },
];

const getMoneyRefund = (legalName: string) => [
  {
    title: 'Правила возврата денег',
    content: (
      <>
        <Typography variant="s" size="body_long" color="text-primary" className={paragraph}>
          При оплате наличными возврат будет сделан в той же форме либо на банковскую карту по вашему выбору.
          Для возврата наличных денег потребуется документ, удостоверяющий личность.
        </Typography>
        <Typography variant="s" size="body_long" color="text-primary" className={paragraph}>
          При безналичной оплате деньги будут возвращены на банковскую карту. Срок зачисления денег зависит
          от банка, выпустившего&nbsp;карту.
        </Typography>
        <Typography variant="s" size="body_long" color="text-primary" className={paragraph}>
          Вернуть товар, купленный в кредит, можно только в том магазине, где вы его покупали.
        </Typography>
        <Typography variant="s" size="body_long" color="text-primary" className={paragraph}>
          При полной или частичной оплате покупки подарочной картой {legalName} деньги будут возвращены на
          карту возврата.
        </Typography>
        <Typography variant="s" size="body_long" color="text-primary" className={paragraph}>
          Карта возврата выдаётся при возврате товара, приобретённого с помощью подарочной карты. Ей можно
          оплатить товары в&nbsp;любом магазине {legalName} в России. Сумма денег на карте возврата равна
          стоимости товаров, которые были возвращены в&nbsp;магазин.
        </Typography>
      </>
    ),
  },
];

export const getContentMaps = (legalName: string, isHideBrandsExperimentAvailable: boolean) => ({
  properQuality: getProductsProperQuality(legalName, isHideBrandsExperimentAvailable),
  improperQuality: getProductsImproperQuality(legalName),
  moneyRefund: getMoneyRefund(legalName),
});
