const smMob = { max: 440 };
const mob = { max: 767 };
const tab = { min: 768, max: 1023 };
const desk = { min: 1024, max: 1199 };
const largeDesk = { min: 1200 };

export const breakpoints = {
  only: {
    smMobile: `@media (max-width: ${smMob.max}px)`,
    mobile: `@media (max-width: ${mob.max}px)`,
    tablet: `@media (min-width: ${tab.min}px) and (max-width: ${tab.max}px)`,
    desktop: `@media (min-width: ${desk.min}px) and (max-width: ${desk.max}px)`,
    largeDesktop: `@media (min-width: ${largeDesk.min}px)`,
  },
  from: {
    smMobile: `@media (min-width: ${smMob.max}px)`,
    tablet: `@media (min-width: ${tab.min}px)`,
    desktop: `@media (min-width: ${desk.min}px)`,
    largeDesktop: `@media (min-width: ${largeDesk.min}px)`,
  },
  to: {
    tablet: `@media (max-width: ${mob.max}px)`,
    desktop: `@media (max-width: ${tab.max}px)`,
    largeDesktop: `@media (max-width: ${desk.min}px)`,
  },
};

export const strictBreakpoints = {
  '--only-smMobile': `(max-width: ${smMob.max}px)`,
  '--only-mobile': `(max-width: ${mob.max}px)`,
  '--only-tablet': `(min-width: ${tab.min}px) and (max-width: ${tab.max}px)`,
  '--only-desktop': `(min-width: ${desk.min}px) and (max-width: ${desk.max}px)`,
  '--only-large-desktop': `(min-width: ${largeDesk.min}px)`,
};
