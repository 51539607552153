import { styled } from 'linaria/react';
import { css } from 'linaria';
import { breakpoints } from 'common/styles/breakpoints';

export const HistoryPicture = styled.div`
  width: 320px;
  height: 220px;
  ${breakpoints.only.tablet} {
    width: 300px;
    height: 206px;
    margin-bottom: var(--space-300);
  }
  ${breakpoints.only.mobile} {
    width: 260px;
    height: 178px;
    margin-bottom: var(--space-200);
    margin-left: var(--space-200);
  }
`;

export const HistoryPictureEmpty = styled.div`
  width: 320px;
  height: 220px;
  ${breakpoints.only.tablet} {
    width: 300px;
    height: 206px;
    margin-bottom: var(--space-300);
  }
  ${breakpoints.only.mobile} {
    width: 260px;
    height: 178px;
    margin-bottom: var(--space-200);
    margin-left: var(--space-200);
  }
  ${breakpoints.from.desktop} {
    width: 0;
    height: 0;
    margin: 0;
  }
`;
export const HistoryDescription = styled.div`
  display: flex;
  flex-direction: column;
  width: 490px;
  ${breakpoints.from.desktop} {
    margin-left: var(--space-300);
  }
  span {
    user-select: none;
  }
  ${breakpoints.only.tablet} {
    max-width: 300px;
  }
  ${breakpoints.only.mobile} {
    max-width: 260px;
    margin-left: var(--space-200);
  }
`;

export const HistoryItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: var(--space-300) 0;
  margin-right: var(--space-600);
  ${breakpoints.only.tablet} {
    flex-direction: column;
    max-width: 300px;
    height: auto;
    margin-right: var(--space-500);
  }
  ${breakpoints.only.mobile} {
    flex-direction: column;
    max-width: 260px;
    height: auto;
    padding: var(--space-150) 0;
    margin-right: var(--space-300);
  }
`;

export const yearsPadding = css`
  padding-bottom: var(--space-100);
`;
