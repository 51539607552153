import { css } from 'linaria';
import { breakpoints } from 'common/styles/breakpoints';

export const container = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: var(--space-250) 0;

  ${breakpoints.to.tablet} {
    flex-direction: column;
  }
`;

export const textRegion = css`
  flex: 1 1 auto;
  margin-left: var(--space-300);

  ${breakpoints.to.tablet} {
    margin-top: var(--space-275);
    margin-left: 0;
  }
`;

export const logo = css`
  cursor: pointer;
`;
