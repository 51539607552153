import React, { FC } from 'react';

export const DATA_QA_REGION_STUB_IMAGE = 'data-ga-region-stub-image';

export const StoreImage: FC = () => (
  <svg
    width="311"
    height="162"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-qa={DATA_QA_REGION_STUB_IMAGE}
  >
    <path d="M269.585 106.845H45.121V161h224.464v-54.155Z" fill="#EFF0F2" />
    <path
      d="M309.965 50.904H1.035v109.314h308.93V50.904Z"
      fill="#fff"
      stroke="#000"
      strokeWidth="1.762"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M198.493 127.625h-83.387v30.884h83.387v-30.884Z" fill="#CED0D6" />
    <path
      d="M309.965 65.162H1.035v19.011h308.93v-19.01Z"
      fill="#5AB030"
      stroke="#000"
      strokeWidth="1.762"
      strokeLinecap="round"
    />
    <path
      d="M56.245 105.663 156.308 1l100.063 104.663H56.245Z"
      fill="#fff"
      stroke="#000"
      strokeWidth="1.762"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.075 105.375v54.179h200.107v-54.179"
      stroke="#000"
      strokeWidth="1.762"
      strokeLinecap="round"
    />
    <path d="M115.757 92.995h83.193l-41.594-41.93-41.599 41.93Z" fill="#5AB030" />
    <path
      d="m160.772 39.947-7.359 3.538-1.441-1.45 3.512-7.416-5.433 5.474-2.161-2.2 9.416-9.54 3.099 3.119-3.565 7.066 7.124-3.505 3.004 3.034-9.464 9.54-2.184-2.204 5.452-5.456ZM144.076 41.728l1.761 5.385h-.048l-5.08-1.988-2.646 2.662 8.781 2.798 3.687 3.717 2.401-2.421-3.705-3.74-2.355-9.235-2.796 2.822Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M133.343 52.14c2.962-2.982 6.874-2.695 9.648.099 2.773 2.794 3.06 6.742.103 9.719-2.957 2.977-6.874 2.695-9.643-.099-2.768-2.794-3.065-6.751-.108-9.72Zm1.883 1.884c-1.501 1.513-.998 3.355.829 5.183 1.827 1.828 3.654 2.355 5.156.838 1.502-1.517.998-3.354-.834-5.182-1.831-1.828-3.653-2.356-5.151-.839Z"
      fill="#000"
    />
    <path
      d="m131.241 65.415 6.437 1.842-2.576 2.596-8.183-2.355 1.413-1.414a1.755 1.755 0 0 0 .042-2.473 1.719 1.719 0 0 0-1.896-.344 1.71 1.71 0 0 0-.552.391l-1.55 1.56 7.656 7.721-2.396 2.422-9.464-9.545 4.788-4.82c1.592-1.61 3.984-1.658 5.387-.25a4.077 4.077 0 0 1 .894 4.665v.004ZM116.607 73.312l1.62 1.63 3.328-3.36 1.931 1.946-3.334 3.36 2.063 2.077 4.115-4.15 1.93 1.94-6.521 6.596-9.468-9.54 6.521-6.596 1.93 1.946-4.115 4.15ZM113.961 91.167l6.418-6.469-2.152-2.171-4.026 4.051-7.307-7.368-2.397 2.417 9.464 9.54ZM189.625 79.996l9.464-9.544-2.519-2.544-9.464 9.544 2.519 2.544ZM169.822 44.837l-1.615 1.65 3.631 3.66-1.936 1.946-3.625-3.661-2.062 2.078 4.421 4.452-1.931 1.945-6.817-6.873 9.459-9.54 6.822 6.873-1.911 1.918-4.436-4.448ZM185.375 75.696l-6.408-6.45 9.459-9.54 2.397 2.417-7.303 7.368 4.017 4.052-2.162 2.153ZM204.447 75.846l-3.032 10.115 6.526-6.596 2.25 2.266-9.459 9.536-2.971-2.992 2.59-10.412-6.46 6.52-2.185-2.204 9.459-9.535 3.282 3.302ZM178.99 60.95l-1.826 6.491-2.576-2.581 2.025-8.575 1.709 1.73a1.726 1.726 0 0 0 2.453.037 1.756 1.756 0 0 0-.042-2.469l-1.851-1.86-7.66 7.721-2.402-2.422 9.464-9.54 5.095 5.136c1.596 1.61 1.643 4.018.245 5.436a4.003 4.003 0 0 1-4.624.9l-.01-.005Z"
      fill="#000"
    />
    <path
      d="M57.016 127.569H256.18M115.107 127.569v31.565M156.542 127.569v31.565M197.504 127.569v31.565"
      stroke="#000"
      strokeWidth="1.762"
      strokeLinecap="round"
    />
  </svg>
);
