import { styled } from 'linaria/react';

export const InfobarContainer = styled.div`
  display: flex;
  width: 100%;
  padding: var(--space-150);
  background-color: var(--background-secondary);
  border-radius: var(--rounding-l);
`;

export const IconWrapper = styled.div`
  padding: var(--space-50);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--text-primary);
  a {
    text-decoration: underline;
  }
`;
