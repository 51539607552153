import React, { FC } from 'react';
import { Helmet } from '@okapi/helmet-react';
import { useSelector } from 'react-redux';
import { getPageUrl, getSeoTemplates, getTitle } from 'features/page-model/ducks/get-model';
import { processTemplate } from 'utils/seo-template';

export const DATA_QA_TITLE = 'page-title';
export const DATA_QA_SEO_META = 'page-seo-meta';

type Props = {
  manualTitle?: string;
  manualDescription?: string;
  manualKeywords?: string;
};

export const AnalyticsPageMeta: FC<Props> = ({ manualTitle, manualDescription, manualKeywords }: Props) => {
  const { metakeywords = '', metadescription = '' } = useSelector(getSeoTemplates) || {};
  const seoTitle = useSelector(getTitle) || '';
  const keywords = manualKeywords || processTemplate(metakeywords, 'product name');
  const description = manualDescription || processTemplate(metadescription, 'product name') || '';
  const title = manualTitle || processTemplate(seoTitle, 'product name') || '';
  const currentUrl = useSelector(getPageUrl);

  return (
    <>
      <Helmet>
        <title data-qa={DATA_QA_TITLE}>{title}</title>
        <meta name="keywords" content={keywords ?? undefined} data-qa={DATA_QA_SEO_META} />
        <meta name="description" content={description ?? undefined} data-qa={DATA_QA_SEO_META} />
        <meta property="og:title" content={keywords ?? undefined} />
        <meta property="og:description" content={description ?? undefined} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentUrl} />
        <link
          href="https://cdn.lemanapro.ru/lmru/image/upload/other/favicon/favicon.ico"
          rel="shortcut icon"
          type="image/x-icon"
        />
      </Helmet>
    </>
  );
};
