import React, { FC } from 'react';
import { Typography } from 'fronton-react';
import { cx } from 'linaria';
import { phone } from './styles';

const PHONE_NUMBER = '8 800 700 00 99';

export const DATA_QA_STUB_REGION_PHONE_NUMBER = 'stub-region-phone-number';
export const DATA_QA_STUB_REGION_PHONE_NUMBER_LINK = 'stub-region-phone-number-link';

type Props = {
  className?: string;
  onClick: () => void;
  dataQA?: string;
};

export const PhoneNumber: FC<Props> = ({ className, onClick, dataQA }) => (
  <a
    href={`tel:${PHONE_NUMBER}`}
    data-qa={DATA_QA_STUB_REGION_PHONE_NUMBER_LINK}
    className={cx(className, phone)}
  >
    <Typography
      size="body_accent"
      variant="s"
      data-qa={dataQA || DATA_QA_STUB_REGION_PHONE_NUMBER}
      onClick={onClick}
    >
      {PHONE_NUMBER}
    </Typography>
  </a>
);
