import { registerApp } from '@okapi/core';
import log from 'loglevel';
import { preloadedState, router, store } from 'store';
import { createEntry } from '../common';

log.setDefaultLevel((preloadedState.env.LOG_LEVEL || 'warn') as log.LogLevelDesc);

if (preloadedState.isBot === false) {
  registerApp(__NAME__, () => createEntry({ store, router, remoteContext: {} }));
}
