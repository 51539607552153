import React, { FC, useCallback } from 'react';
import { Layout } from 'fronton-react';
import { useSelector } from 'react-redux';
import {
  getStubRegionDelivery,
  getStubRegionDeliveryFrom,
  getStubRegionName,
  getStubRegionNamePrepositional,
  getStubRegionOrderFrom,
} from 'features/stub-region/ducks/get-stub-region-data';
import { useBreakpoints } from 'common/hooks/use-breakpoints';
import { useGaPageview } from 'features/analytics/hooks';
import { getPathname } from 'features/page-model/ducks/get-model';
import { getCustomerID, getIsAuthorized, getYaVerification } from 'common/selectors';
import { analyticsUtils } from 'utils';
import { AnalyticsPageMeta } from 'features/analytics';
import { Helmet } from '@okapi/helmet-react';
import { useTranslation } from 'react-i18next';
import { Header } from './components/header';
import { RegionList } from './components/region-list';
import { backgroundLine, phoneNumber } from './styles';
import { SeoDescription } from './components/seo-description';
import { PhoneNumber } from './components/phone-number';
import { MainInfoWithoutDelivery } from './components/main-info-without-delivery';
import { MainInfoWithDelivery } from './components/main-info-with-delivery';
import { getSEODescription, getSEOKeywords, getSEOTitleText } from './utils';

export const DATA_QA_HEADER_PHONE_NUMBER = 'header-phone-number';
export const DATA_QA_YANDEX_VERIFICATION_META = 'yandex-verification';
export const YANDEX_VERIFICATION_NAME_ATTR = 'yandex-verification';

export const RegionStubPage: FC = () => {
  const path = useSelector(getPathname);
  const isAuthorized = useSelector(getIsAuthorized) ? '1' : '0';

  const region = useSelector(getStubRegionName) || '';
  const regionNamePrepositional = useSelector(getStubRegionNamePrepositional) || '';
  const hasDelivery = useSelector(getStubRegionDelivery);
  const { mobile, smMobile } = useBreakpoints() || {};

  const { regionLink: regionLinkOrderFrom = '' } = useSelector(getStubRegionOrderFrom) || {};
  const { regionLink: regionLinkDeliveryFrom = '' } = useSelector(getStubRegionDeliveryFrom) || {};

  const userId = useSelector(getCustomerID);

  const yaVerification = useSelector(getYaVerification);

  const elementName = `Доставляем в ${regionNamePrepositional}`;
  const sandGaPhoneClickEvent = useCallback(() => {
    analyticsUtils.sendEvent(`GA:clickButton`, {
      elementType: 'button',
      elementBlock: 'stubPage',
      elementName,
      elementText: '88007000099',
    });
  }, [elementName]);

  const sandGaRegionClickEvent = useCallback(
    (regionName: string) => {
      analyticsUtils.sendEvent(`GA:clickButton`, {
        elementType: 'button',
        elementBlock: 'stubPage',
        elementName,
        elementText: regionName,
      });
    },
    [elementName],
  );

  const { t } = useTranslation();
  const legalName = t('legalName');

  useGaPageview(path, isAuthorized, null, undefined, userId);

  const MainInfo = hasDelivery ? MainInfoWithDelivery : MainInfoWithoutDelivery;
  return (
    <>
      <Layout>
        <Header region={region} legalName={legalName}>
          {!(mobile || smMobile) && (
            <PhoneNumber onClick={sandGaPhoneClickEvent} dataQA={DATA_QA_HEADER_PHONE_NUMBER} />
          )}
        </Header>
        <AnalyticsPageMeta
          manualTitle={getSEOTitleText(regionNamePrepositional, legalName)}
          manualDescription={getSEODescription(regionNamePrepositional, legalName, hasDelivery)}
          manualKeywords={getSEOKeywords(region, legalName)}
        />
        <Helmet>
          <link rel="canonical" href="/" />
          <meta
            name={YANDEX_VERIFICATION_NAME_ATTR}
            content={yaVerification}
            data-qa={DATA_QA_YANDEX_VERIFICATION_META}
          />
        </Helmet>
        {region && <MainInfo />}
        {(mobile || smMobile) && <PhoneNumber className={phoneNumber} onClick={sandGaPhoneClickEvent} />}
      </Layout>
      <div className={backgroundLine} />
      <Layout>
        <RegionList onClick={sandGaRegionClickEvent} />
        <SeoDescription
          region={regionNamePrepositional}
          regionLink={regionLinkOrderFrom || regionLinkDeliveryFrom}
          legalName={legalName}
        />
      </Layout>
    </>
  );
};
