import React from 'react';

export const ChatsCircleColoredIcon: React.FC = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M50.6979 40.4754L50.6093 40.1647L50.7742 39.8869C52.7824 36.5035 53.4863 32.5033 52.7537 28.6376C52.021 24.7719 49.9023 21.3067 46.7953 18.8927C43.6884 16.4787 39.807 15.2821 35.8801 15.5276C31.9533 15.7731 28.2511 17.4438 25.469 20.2259C22.6869 23.008 21.0162 26.7102 20.7707 30.637C20.5252 34.5639 21.7218 38.4453 24.1358 41.5522C26.5498 44.6592 30.015 46.7779 33.8807 47.5106C37.7464 48.2432 41.7466 47.5393 45.13 45.5311L45.4078 45.3662L45.7185 45.4547L51.5518 47.1181C51.5521 47.1181 51.5524 47.1182 51.5527 47.1183C51.6647 47.1496 51.7829 47.1506 51.8954 47.1212C52.0081 47.0917 52.111 47.0328 52.1934 46.9503L52.7226 47.4795L52.1934 46.9503C52.2759 46.8679 52.3348 46.765 52.3643 46.6523C52.3937 46.5398 52.3927 46.4216 52.3615 46.3097C52.3614 46.3094 52.3613 46.309 52.3612 46.3087L50.6979 40.4754Z"
      fill="#F2F3F5"
      stroke="#333333"
      strokeWidth="1.5"
    />
    <path
      d="M9.09316 33.3563L9.18174 33.0456L9.01686 32.7678C7.00862 29.3844 6.30472 25.3842 7.03735 21.5185C7.76998 17.6528 9.88873 14.1875 12.9957 11.7735C16.1027 9.35957 19.984 8.16295 23.9109 8.40844C27.8378 8.65392 31.5399 10.3246 34.322 13.1067C37.1042 15.8889 38.7749 19.591 39.0203 23.5179C39.2658 27.4447 38.0692 31.3261 35.6552 34.4331C33.2413 37.54 29.776 39.6588 25.9103 40.3914C22.0446 41.1241 18.0444 40.4202 14.661 38.4119L14.3832 38.247L14.0725 38.3356L8.23923 39.9989C8.23892 39.999 8.23861 39.9991 8.2383 39.9992C8.12636 40.0305 8.00811 40.0315 7.89565 40.0021C7.78288 39.9726 7.68 39.9136 7.59757 39.8312L7.06842 40.3603L7.59757 39.8312C7.51515 39.7488 7.45617 39.6459 7.42669 39.5331C7.3973 39.4207 7.3983 39.3025 7.42957 39.1906C7.42966 39.1902 7.42976 39.1899 7.42985 39.1895L9.09316 33.3563Z"
      fill="#BDD0FF"
      stroke="#333333"
      strokeWidth="1.5"
    />
    <path
      d="M22.9742 25.7609C23.7965 25.7609 24.463 25.0943 24.463 24.2721C24.463 23.4498 23.7965 22.7832 22.9742 22.7832C22.1519 22.7832 21.4854 23.4498 21.4854 24.2721C21.4854 25.0943 22.1519 25.7609 22.9742 25.7609Z"
      fill="#333333"
    />
    <path
      d="M30.1168 25.7609C30.939 25.7609 31.6056 25.0943 31.6056 24.2721C31.6056 23.4498 30.939 22.7832 30.1168 22.7832C29.2945 22.7832 28.6279 23.4498 28.6279 24.2721C28.6279 25.0943 29.2945 25.7609 30.1168 25.7609Z"
      fill="#333333"
    />
    <path
      d="M15.8258 25.7609C16.648 25.7609 17.3146 25.0943 17.3146 24.2721C17.3146 23.4498 16.648 22.7832 15.8258 22.7832C15.0035 22.7832 14.3369 23.4498 14.3369 24.2721C14.3369 25.0943 15.0035 25.7609 15.8258 25.7609Z"
      fill="#333333"
    />
  </svg>
);
