import { styled } from 'linaria/react';
import { css } from 'linaria';
import { breakpoints } from 'common/styles/breakpoints';

export const ContactUnitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: var(--space-500);
  ${breakpoints.from.tablet} {
    width: 100%;
    margin-bottom: 0;
  }
  ${breakpoints.only.largeDesktop} {
    min-width: 378px;
    max-width: 271px;
  }
`;

export const HardcodeMargin = styled.div`
  height: var(--space-100);
`;

export const contactTitle = css`
  margin-bottom: var(--space-150);
`;
export const contactLink = css`
  margin-top: var(--space-150);
  span {
    color: var(--brand-dark) !important;
  }
  &:hover {
    span {
      color: var(--brand-primary) !important;
    }
  }
`;
