import React from 'react';
import { Content, InfobarContainer, IconWrapper } from './style';

type Props = {
  icon: JSX.Element;
  children: JSX.Element | JSX.Element[];

  className?: string;
};

export const Infobar = ({ icon, children, className }: Props) => (
  <InfobarContainer className={className}>
    <IconWrapper>{icon}</IconWrapper>
    <Content>{children}</Content>
  </InfobarContainer>
);
