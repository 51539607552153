import { styled } from 'linaria/react';
import { breakpoints } from '../../styles/breakpoints';

export const HistoryScroll = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 calc(50vw - var(--layout-width) / 2 - var(--layout-gap) * 2 / 2 + var(--layout-gap));
  scroll-padding: calc(50vw - var(--layout-width) / 2 - var(--layout-gap) * 2 / 2 + var(--layout-gap));
`;

export const HistoryWrapper = styled.div`
  width: 100vw;
  padding-top: var(--space-200);
  padding-bottom: var(--space-200);
  margin: var(--space-400) 0;
  overflow-x: scroll;
  overflow-y: hidden;
  background-color: var(--background-secondary);
  cursor: grab;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  -webkit-scroll-snap-type: none;
  img {
    width: auto;
    height: 100%;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  ${breakpoints.only.mobile} {
    margin-top: var(--space-300);
  }
  ${breakpoints.from.tablet} {
    padding-top: var(--space-225);
    padding-bottom: var(--space-225);
    margin-bottom: var(--space-950);
  }
`;
