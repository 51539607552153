import React, { FC, useCallback } from 'react';
import { RegularButton, Typography } from 'fronton-react';
import { useBreakpoints } from 'common/hooks/use-breakpoints';
import { analyticsUtils } from 'utils';
import { button, container, description } from './styles';
import { StoreImage } from '../store-image';

export const DATA_QA_MAIN_INFO_TITLE = 'region-stub-main-info-title';
export const DATA_QA_MAIN_INFO_BUTTON = 'region-stub-main-info-button';

type Props = {
  title: string;
  buttonText: string;
  regionLink: string;
  children?: React.ReactNode;
};

export const MainInfo: FC<Props> = ({ title, buttonText, regionLink, children }) => {
  const { largeDesktop, desktop } = useBreakpoints() || {};
  const sandGAEvent = useCallback(() => {
    analyticsUtils.sendEvent(`GA:clickButton`, {
      elementType: 'button',
      elementBlock: 'stubPage',
      elementName: title,
      elementText: buttonText,
    });
  }, [buttonText, title]);

  return (
    <div className={container}>
      <div className={description}>
        <Typography variant="h1" data-qa={DATA_QA_MAIN_INFO_TITLE}>
          {title}
        </Typography>
        {children}
        <RegularButton
          invert
          size="l"
          variant="accent"
          as="a"
          href={`https://${regionLink}.lemanapro.ru/`}
          className={button}
          onClick={sandGAEvent}
          data-qa={DATA_QA_MAIN_INFO_BUTTON}
        >
          {buttonText}
        </RegularButton>
      </div>
      {(largeDesktop || desktop) && <StoreImage />}
    </div>
  );
};
