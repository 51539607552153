import { styled } from 'linaria/react';
import { css } from 'linaria';
import { breakpoints } from 'common/styles/breakpoints';

export const FaqMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0;
`;

export const menuItem = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: var(--space-200) var(--space-200) var(--space-200) var(--space-400);

  ${breakpoints.from.tablet} {
    width: 209px;
  }

  ${breakpoints.from.largeDesktop} {
    width: 271px;
  }

  ${breakpoints.to.tablet} {
    padding: var(--space-200) 0 var(--space-200) var(--space-200);
  }
`;

export const menuItemActive = css`
  background-color: transparent;
  border-radius: var(--rounding-l);

  ${breakpoints.from.tablet} {
    background-color: var(--background-secondary);
  }
`;

export const extraMenuItem = css`
  padding: var(--space-200) 0;

  ${breakpoints.from.tablet} {
    padding: var(--space-200);
  }
`;

export const customButton = css`
  justify-content: flex-end;
  width: 100%;
  line-height: var(--space-300) !important;
  text-align: start;
  color: black;
`;

export const customButtonInactive = css`
  color: var(--text-minor);
  pointer-events: none;
  ${breakpoints.from.tablet} {
    color: inherit;
  }
`;

export const displayMobile = css`
  ${breakpoints.from.tablet} {
    display: none !important;
  }
`;

export const title = css`
  margin: 0 0 var(--space-200) 0;
  color: var(--text-primary);
`;

export const icon = css`
  margin-bottom: auto;
  rotate: 180deg;
`;
