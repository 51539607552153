/* eslint-disable max-lines */
import React, { FC, memo } from 'react';
import { Typography } from 'fronton-react';
import { cx } from 'linaria';
import { patchUrlByLocale } from 'utils';
import {
  OrderedList,
  orderedItem,
  link,
  dottedOrderedList,
  paragraph,
  paragraphHeading,
  subListItem,
  subOrderedList,
  subOrderNumber,
  subParagraph,
  dottedListItem,
  paragraphWithoutNumber,
  dottedListPart,
  orderNumber,
  dottedLi,
  listItem,
  number,
} from '../selling-rules.styles';

type Props = {
  legalName: string;
};

export const Pravila: FC<Props> = memo(({ legalName }) => (
  <>
    <div style={{ margin: 'var(--space-200) 0 0 0' }}>
      <Typography variant="m" size="body_accent" color="text-primary">
        Редакция &#8470; /16922 от&nbsp;17.11.2023&nbsp;г. Утверждена Приказом &#8470;&nbsp;16922
        от&nbsp;17.11.2023&nbsp;г.
      </Typography>
      <Typography
        style={{ marginTop: 'var(--space-100)' }}
        variant="m"
        size="body_accent"
        color="text-primary"
      >
        Правила продажи товаров для физических лиц и&nbsp;применения рекомендательных алгоритмов в
        интернет-магазине ООО &laquo;ЛЕ МОНЛИД&raquo;
      </Typography>
      <Typography
        variant="s"
        size="body_long"
        as="p"
        style={{ margin: 'var(--space-100) 0 0 0' }}
        color="text-primary"
      >
        Настоящие Правила продажи товаров интернет-магазина {legalName} для физических лиц (далее —
        Правила)разработаны в соответствии с положениями ГК РФ о розничной купле-продаже (§ 2 глава 30),
        Законом РФ от 07.02.1992 N 2300-1 «О защите прав потребителей» и Постановлением Правительства РФ от
        31.12.2020 № 2463 «Об утверждении Правил продажи товаров по договору розничной купли-продажи…».
        Настоящие правила не регулируют продажу товаров и услуг в торговых центрах (магазинах) Продавца (ООО
        «ЛЕ МОНЛИД») и не распространяются на отношения, возникающие между Продавцом и Покупателем при
        продаже товаров и услуг в торговых центрах (магазинах) Продавца. Настоящие правила регулируют
        отношения между Покупателем и Продавцом исключительно при продаже товаров дистанционным способом с
        использованием информационно-телекоммуникационной сети «Интернет» через Интернет-магазин «{legalName}
        », размещенный в сети Интернет по адресу:{' '}
        <a href={patchUrlByLocale('/')} className={link}>
          lemanapro.ru
        </a>
        , либо с использованием телефонной связи, Мобильного приложения «{legalName}», а также при оказании в
        связи с такими продажами услуг, в том числе, услуг по доставке товаров путем их пересылки почтовыми
        отправлениями или перевозки.
      </Typography>
    </div>
    <div>
      <OrderedList type="1">
        <li className={listItem}>
          <div className={orderedItem}>
            <Typography
              variant="m"
              size="body_accent"
              as="span"
              className={orderNumber}
              color="text-primary"
            >
              1.
            </Typography>
            <Typography variant="m" size="body_accent" className={paragraphHeading} color="text-primary">
              Термины и определения.
            </Typography>
          </div>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            Основные понятия, используемые в Правилах, означают следующее:
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>«Дополнительные услуги»</b> — оказываемые Продавцом услуги в связи с продажей Товара, включая,
            но не ограничиваясь, услуги по доставке товаров путем их пересылки почтовыми отправлениями или
            перевозки.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>«Заказ»</b> — оформленный через Сайт, с использованием Мобильного приложения «{legalName}»
            либо с использованием телефонной связи запрос Покупателя на получение по указанному адресу
            (зависит от вида доставки) перечня Товаров, выбранных на Сайте.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>«Личный кабинет»</b> — персональная страница Покупателя на Сайте и в Мобильном приложении «
            {legalName}», для доступа к которой необходимы логин и пароль, и на которой хранится информация о
            Покупателе и его Заказах.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>«Подтверждение о готовности товара к отгрузке»</b> – направление Продавцом Покупателю по
            электронной почте либо по телефону информации о том, что Заказ собран и готов к выдаче в
            магазинелибо собран и передан Продавцом службе доставки. Обязательство Продавца по передаче
            Покупателю Товара возникает с момента направления Продавцом Покупателю Подтверждения о готовности
            Товара к отгрузке. Если подтверждение об отгрузке не направлено Продавцом Покупателю до истечения
            ожидаемой даты передачи Заказа службе доставки, указанной в автоматическом подтверждении
            получения оплаты Заказа, условие признается не наступившим.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>«Подтверждение получения заказа»</b> – направление Продавцом Покупателю по электронной почте
            либо по телефону информации о получении Продавцом Заказа.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>«Покупатель»</b> — любое полностью дееспособное физическое лицо, заказывающее, приобретающее
            товары на условиях, указанных в Правилах, или использующее товары исключительно для личных,
            семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской деятельности,
            либо лицо, указанное в качестве Получателя приобретаемого товара.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>«Получатель»</b> — Покупатель либо лицо, которые уполномочены Покупателем на получение
            заказанного Товара от имени Покупателя и (или) приемку Дополнительных услуг и данные которых
            сообщаются Продавцу при оформлении Покупателем Заказа.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>«Постамат»</b> – устройство для выдачи Товаров Получателям; терминал со множеством
            автоматизированных ячеек для хранения Заказов, из которых Покупатель самостоятельно забирает
            купленные Товары.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>«Продавец»</b> — ООО «ЛЕ МОНЛИД» (ОГРН 1035005516105, ИНН: 5029069967, адрес места нахождения:
            141031, Россия, Московская обл., г. Мытищи, Осташковское ш., д.1), организация, осуществляющая
            продажу товаров дистанционно, по договору розничной купли-продажи, заключаемому на основании
            ознакомления Покупателя с предложенным на интернет-сайте Продавца по адресу:
            <a href={patchUrlByLocale('/')} className={link}>
              {' '}
              lemanapro.ru
            </a>{' '}
            (далее — Сайт) описанием товара, без непосредственного ознакомления Покупателя с товаром либо
            образцом товара при заключении такого договора.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>«Рекомендательные технологии»</b> — информационные технологии предоставления информации на
            основе сбора, систематизации и анализа сведений, относящихся к предпочтениям пользователей сети
            «Интернет», находящихся на территории Российской Федерации. На Сайте и в Мобильном приложении
            применяются рекомендательные технологии. Правила и информация о применении рекомендательных
            технологий изложены на отдельной странице Сайта.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>«Сайт»</b> — интернет-сайт, принадлежащий Продавцу, размещенный в сети Интернет на домене
            <a href={patchUrlByLocale('/')} className={link}>
              {' '}
              lemanapro.ru
            </a>{' '}
            и его поддоменах, на котором представлены Товары, предлагаемые Покупателям для приобретения
            посредством оформления и размещения Заказов, а также условия заказа, оплаты, предоставления
            Дополнительных услуг и доставки приобретаемых Товаров Покупателям.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>«Мобильное приложение «{legalName}»</b> - программное обеспечение, предназначенное для работы
            на смартфонах, планшетах, и др. мобильных устройствах, разработанное для конкретной платформы
            (IOS, Android и др.), принадлежащее Продавцу и предназначенное для поиска, выбора и заказа
            Товаров. Мобильное приложение «{legalName}» доступно владельцам мобильных устройств для
            бесплатного скачивания и установки в магазинах мобильных приложений третьих лиц.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>«Служба доставки»</b> — третье лицо, оказывающее по договору с Продавцом услуги по доставке
            Заказов Покупателям.
          </Typography>
          <Typography variant="s" size="body_long" as="p" className={paragraph} color="text-primary">
            <b>«Товар»</b> — объект материального мира, не изъятый из гражданского оборота и представленный к
            продаже на Сайте и имеющийся в наличии на момент подтверждения готовности товара к отгрузке.
          </Typography>
        </li>
        <li className={listItem}>
          <div className={orderedItem}>
            <Typography
              variant="m"
              size="body_accent"
              as="span"
              className={orderNumber}
              color="text-primary"
            >
              2.
            </Typography>
            <Typography variant="m" size="body_accent" className={paragraphHeading} color="text-primary">
              Общие положения о продаже товаров
            </Typography>
          </div>
          <ol className={subOrderedList}>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                2.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Продавец, представляя Товары на Сайте или в Мобильном приложении, действует от своего имени
                за свой счет или по поручению и за счет третьих лиц (организаций или индивидуальных
                предпринимателей, являющихся собственниками соответствующего Товара).
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                2.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Настоящий документ, постоянно размещенный в сети Интернет на Сайте или в Мобильном
                приложении, в соответствии со ст. 435 и ч.2 ст. 437 ГК РФ является публичной офертой -
                предложением Продавца заключить Договор купли-продажи Товаров дистанционным способом (далее –
                «Договор») с любым Покупателем на условиях, изложенных ниже. Конкретные тематические разделы
                и страницы Сайта с описанием Товаров, информацией об условиях их оплаты и доставки являются
                неотъемлемой частью настоящих Правил.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                2.3.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                В соответствии со статьей 438 ГК РФ выражение Покупателем согласия с условиями Оферты путём
                нажатия кнопки «Оформить заказ»/«Перейти к оплате» при оформлении Заказа является акцептом
                Покупателем настоящей оферты. Нажимая на кнопку «Оформить заказ»/«Перейти к оплате» при
                оформлении Заказа на Сайте либо давая устное согласие оператору при оформлении Заказа по
                телефону, Покупатель подтверждает свое согласие с настоящими Правилами и с условиями
                индивидуального договора купли-продажи выбранных им Товаров и сообщает Продавцу о своём
                намерении заключить договор розничной купли-продажи на условиях настоящей оферты.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                2.4.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                В случае выбора Покупателем способа оплаты «Картой на сайте» Договор считается заключенным с
                момента направления Продавцом Покупателю кассового чека либо иного документа, подтверждающего
                оплату Товара. В случае выбора Покупателем способа оплаты «При получении» Договор считается
                заключенным с момента получения Продавцом способом, описанным в п. 2.3 выше, сообщения
                Покупателя о намерении заключить договор розничной купли-продажи.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                2.5.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Перед оформлением Заказа Покупатель обязан ознакомиться с настоящими Правилами. При
                оформлении Заказа через Сайт или Мобильное приложение «{legalName}» Покупатель подтверждает
                ознакомление и согласие с Правилами путем нажатия на кнопку «Оформить заказ»/«Перейти к
                оплате». При оформлении Заказа по телефону Покупатель подтверждает ознакомление и согласие с
                Правилами путём продолжения разговора после информационного сообщения автоответчика.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                2.6.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                К отношениям между Покупателем и Продавцом применяются положения ГК РФ о розничной
                купле-продаже (§ 2 глава 30), а также Закон РФ «О защите прав потребителей» от 07.02.1992 №
                2300-1 (ст.26.1) и иные правовые акты, принятые в соответствии с ними.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                2.7.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Покупатель гарантирует, что товары приобретаются им для личных, семейных, домашних и иных
                нужд, не связанных с осуществлением предпринимательской деятельности. Покупатель также
                гарантирует, что ему не менее 18 лет, а если меньше 18 лет, то право на заказ товаров
                предоставлено ему по закону и он обладает дееспособностью для акцепта настоящей оферты.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                2.8.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                На Сайте продаются Товары как из собственного ассортимента товаров Продавца, так и Товары
                партнеров Продавца. Для продажи на Сайте Продавец специально отбирает только качественные
                Товары у проверенных партнеров. При заказе Товары партнеров в корзине формируются в отдельный
                Заказ, так как находятся у разных поставщиков и не могут быть доставлены одним Заказом -
                каждый такой Заказ нужно оформить и оплатить отдельно. В таких случаях на Сайте появляется
                соответствующее уведомление.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                2.9.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Настоящая оферта адресована только физическим лицам-потребителям. Условиях настоящих Правил
                не распространяются на покупки на Сайте Товаров юридическими лицами, индивидуальными
                предпринимателями и участниками программы лояльности «Профессиональная карта {legalName}»
                (владельцев «Профессиональной карты»). Правила продажи Товаров юридическим лицам и
                индивидуальным предпринимателям, Правила программы лояльности «Профессиональная карта»
                размещены на Сайте отдельно.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                2.10.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Продавец оставляет за собой право в одностороннем порядке вносить изменения в настоящие
                Правила, в связи с чем Покупателю рекомендуется знакомиться с Правилами при каждом оформлении
                Заказа.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                2.11.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Действия Покупателя через Мобильное приложение «{legalName}» равноценны действиям Покупателя
                на Сайте. Отношения Продавца и Покупателя при оформлении Заказов с использованием Мобильного
                приложения «{legalName}» регулируются настоящими Правилами.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                2.12.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Не все Товары, представленные на Сайте или в Мобильном приложении «{legalName}»,
                предназначены для продажи дистанционным способом. Если продажа товара на Сайте и (или) или в
                Мобильном приложении «{legalName}» подразумевает предварительное согласование Продавцом
                условий договора розничной купли-продажи, в том числе согласование наличия, наименования и
                количества Товара, а также в случаях, когда Продавец явно указал в описании Товара, что
                соответствующий Товар не предназначен для продажи дистанционным способом, Товар заказать и
                купить на Сайте нельзя.
              </Typography>
            </li>
          </ol>
        </li>
        <li className={listItem}>
          <div className={orderedItem}>
            <Typography
              variant="m"
              size="body_accent"
              as="span"
              className={orderNumber}
              color="text-primary"
            >
              3.
            </Typography>
            <Typography variant="m" size="body_accent" className={paragraphHeading} color="text-primary">
              Оформление Заказа
            </Typography>
          </div>
          <ol className={subOrderedList}>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                3.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Заказ Товара может быть принят оператором Продавца по телефону или оформлен Покупателем
                самостоятельно на Сайте или в Мобильном приложении «{legalName}».
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                3.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Заказ можно оформить и без регистрации на Сайте. Однако для оперативного оформления
                последующих Заказов Покупателю рекомендуется зарегистрироваться на Сайте или в Мобильном
                приложении «{legalName}» и создать Личный кабинет. Для создания Личного кабинета Покупателю
                необходимо указать о себе информацию, заполнив поля формы. После создания Личного кабинета
                Покупателю присваивается уникальное имя пользователя (логин).
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                3.3.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Продавец не несет ответственности за содержание и достоверность информации, предоставляемой
                Покупателем при оформлении Заказов и при создании Личного кабинета. Покупатель несет риски
                невозможности получения Заказа по причине предоставления им недостоверной/некорректной
                информации при оформлении Заказа.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                3.4.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                После регистрации данные о Покупателе передаются в базу данных Продавца. Продавец
                обеспечивает конфиденциальность персональных данных Покупателя в соответствии с разделом 10
                настоящих Правил.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                3.5.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Покупатель обязуется не сообщать третьим лицам логин и пароль, указанные при создании Личного
                кабинета. В случае возникновения у Покупателя подозрений относительно безопасности его логина
                или пароля или ПИН-кода (при выборе доставки Заказа в Постамат или курьером), или возможности
                их несанкционированного использования третьими лицами, Покупатель обязуется незамедлительно
                уведомить об этом Продавца, направив электронное письмо через форму обратной связи по ссылке{' '}
                <a href={patchUrlByLocale('/company/obratnaya-svyaz/')} className={link}>
                  https://lemanapro.ru/company/obratnaya-svyaz/
                </a>{' '}
                на Сайте или по телефону{' '}
                <a href="tel:88007000099" className={cx(link, number)}>
                  8 (800) 700-00-99
                </a>
                . Продавец, в свою очередь, обязуется соблюдать конфиденциальность ПИН‑кода и обеспечивать
                соблюдение конфиденциальности службами доставки и партнерами Продавца.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                3.6.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                При оформлении Заказа по телефону Покупатель сообщает оператору планируемые для Заказа
                артикулы Товара. Оператор проверят наличие и цену данных Товаров, согласовывает с Покупателем
                условия доставки и получает подтверждение Заказа у Покупателя. Затем Продавец направляет
                Покупателю Подтверждение получения им Заказа в виде сообщения по указанному Покупателем
                номеру телефона или письмо по указанному Покупателем адресу электронной почты, содержащее и
                уведомление о необходимости оплатить Заказ. После получения оплаты Заказа Продавец направляет
                Покупателю автоматическое подтверждение получения оплаты Заказа с указанием ожидаемой даты
                передачи Заказа службе доставки. Указанная дата зависит от наличия заказанных Товаров на
                складах Продавца и его контрагентов, и от времени, необходимого на обработку (сборку,
                упаковку, передачу в службу доставки и др.) Заказа. В процессе обработки Заказа дата и время
                доставки Заказа может быть изменена, о чём Продавец сообщает Покупателю по телефону и(или) по
                электронной почте, указанным при оформлении Заказа. После сборки Заказа Продавцом или
                контрагентом Продавца и передачи собранного Заказа службе доставки для передачи Заказа
                Клиенту Продавец направляет Покупателю автоматическое подтверждение готовности Заказа к
                отгрузке с указанием ожидаемой даты доставки Заказа.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                3.7.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                После получения Заказа, сделанного Покупателем на Сайте или в Мобильном приложении «
                {legalName}», Продавец направляет Покупателю автоматическое подтверждение получения Заказа и
                уведомление о необходимости оплатить Заказ. После получения оплаты Заказа Продавец направляет
                Покупателю автоматическое подтверждение получения оплаты Заказа с указанием ожидаемой даты
                передачи Заказа службе доставки. Указанная дата зависит от наличия заказанных Товаров на
                складах Продавца и его контрагентов, и от времени, необходимого на обработку (сборку,
                упаковку, передачу в службу доставки и др.) Заказа. В процессе обработки Заказа дата и время
                доставки Заказа может быть изменена, о чём Продавец сообщает Покупателю по телефону и(или) по
                электронной почте, указанным при оформлении Заказа. После сборки Заказа Продавцом или
                контрагентом Продавца и передачи собранного Заказа службе доставки для передачи Заказа
                Клиенту Продавец направляет Покупателю автоматическое подтверждение готовности Заказа к
                отгрузке с указанием ожидаемой даты доставки Заказа.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                3.8.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Общий срок получения Заказа Покупателем зависит от адреса и региона доставки, и от выбранного
                Покупателем способа доставки Заказа.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                3.9.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Продавец не гарантирует доступность заказанных Товаров на складах Продавца и его контрагентов
                на момент обработки оплаченного Заказа. Если Покупателем оформлен Заказ на Товар, и на момент
                обработки Заказа на складе у Продавца или его контрагента отсутствует этот Товар либо
                отсутствует необходимое количество заказанного Товара, Продавец информирует об этом
                Покупателя по указанному Покупателем номеру телефона или письмом по адресу электронной почты.
                Обязанность Продавца передать Покупателю Товар возникает только с момента направления
                Продавцом Покупателю Подтверждения о готовности товара к отгрузке.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                3.10.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                В случае отсутствия Товара на складе Продавца или его контрагента срок доставки, по
                согласованию с Покупателем, может быть увеличен на срок не более 30 календарных дней, либо
                Заказ может быть аннулирован. Если Товар отсутствует частично (и если Покупатель сообщит
                Продавцу в течение 2-х дней о своем согласии принять Товар в количестве, имеющемся в наличии
                у Продавца), то Продавец отправляет Покупателю Товары в согласованном с Покупателем
                количестве.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                3.11.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Продавец может связаться с Покупателем, не завершившим оформление Заказа, для уточнения
                информации и, при необходимости, оказания Покупателю помощи в оформлении Заказа.
              </Typography>
            </li>
          </ol>
        </li>
        <li className={listItem}>
          <div className={orderedItem}>
            <Typography
              variant="m"
              size="body_accent"
              as="span"
              className={orderNumber}
              color="text-primary"
            >
              4.
            </Typography>
            <Typography variant="m" size="body_accent" className={paragraphHeading} color="text-primary">
              Информация о Товаре
            </Typography>
          </div>
          <ol className={subOrderedList}>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                4.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Продавец стремится к тому, чтобы описания Товаров на Сайте соответствовали характеристикам
                Товаров. Тем не менее, в силу объективных причин (особенности индивидуального восприятия
                человеком, технические ошибки) описания могут не в полной мере передавать достоверную
                информацию о свойствах и характеристиках Товара, включая цвета, размеры и формы. В случае
                возникновения у Покупателя вопросов, касающихся свойств и характеристик Товара, перед
                оформлением Заказа Покупатель должен обратиться за консультацией через форму обратной связи
                на Сайте{' '}
                <a href={patchUrlByLocale('/company/obratnaya-svyaz/')} className={link}>
                  https://lemanapro.ru/company/obratnaya-svyaz/
                </a>{' '}
                или по телефону{' '}
                <a href="tel:88007000099" className={cx(link, number)}>
                  8 (800) 700-00-99
                </a>
                .
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                4.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Информация о товаре, включая условия его эксплуатации и правила хранения, доводится до
                Покупателя путем размещения на Сайте, в Мобильном приложении «{legalName}», на Товаре, на
                электронных носителях, прикладываемых к Товару, в самом Товаре (на электронной плате внутри
                Товара в разделе меню), на таре, упаковке, ярлыке, этикетке, в технической документации или
                иным способом, установленным законом. Обязанность Продавца, предусмотренная пунктом 3 статьи
                26.1 Закона Российской Федерации «О защите прав потребителей», признается исполненной также в
                случае предоставления Покупателю информации с помощью электронных и иных технических средств.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                4.3.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Сведения о подтверждении соответствия Товаров представляются в порядке и способами, которые
                установлены законодательством Российской Федерации о техническом регулировании, и включают в
                себя сведения о номере документа, подтверждающего такое соответствие, о сроке его действия и
                об организации, его выдавшей.
              </Typography>
            </li>
          </ol>
        </li>
        <li className={listItem}>
          <div className={orderedItem}>
            <Typography
              variant="m"
              size="body_accent"
              as="span"
              className={orderNumber}
              color="text-primary"
            >
              5.
            </Typography>
            <Typography variant="m" size="body_accent" className={paragraphHeading} color="text-primary">
              Передача Заказа Покупателю
            </Typography>
          </div>
          <ol className={subOrderedList}>
            <li className={cx(subListItem, dottedListItem)}>
              <div className={dottedListPart}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="span"
                  className={subOrderNumber}
                  color="text-primary"
                >
                  5.1.
                </Typography>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  className={subParagraph}
                  color="text-primary"
                >
                  Передача Заказов осуществляется одним из выбранных Покупателем при оформлении Заказа
                  способов:
                </Typography>
              </div>
              <ol className={dottedOrderedList}>
                <li className={dottedLi}>
                  <Typography
                    variant="s"
                    size="body_long"
                    as="p"
                    className={subParagraph}
                    color="text-primary"
                  >
                    курьером службы доставки по адресу, указанному Покупателем,
                  </Typography>
                </li>
                <li className={dottedLi}>
                  <Typography
                    variant="s"
                    size="body_long"
                    as="p"
                    className={subParagraph}
                    color="text-primary"
                  >
                    в указанном Покупателем пункте выдачи службы доставки или Постамате (доступность выбора
                    данного способа доставки определяется при оформлении Заказа. Услуга доставки в пункт
                    выдачи доступна для Заказа общим весом не более 20 кг и максимальными габаритами
                    40×40×60см, если на Сайте при оформлении Заказа не было указано иное. Услуга недоступна
                    для товаров некоторых классов опасности, для некоторых товаров из категорий Освещение,
                    Сад, а также товаров, содержащих жидкости и сыпучие смеси),
                  </Typography>
                </li>
                <li className={dottedLi}>
                  <Typography
                    variant="s"
                    size="body_long"
                    as="p"
                    className={subParagraph}
                    color="text-primary"
                  >
                    в указанном Покупателем магазине Продавца (доступность выбора данного способа доставки
                    определяется при оформлении Заказа; этот способ передачи товаров Покупателю недоступен
                    для некоторых крупногабаритных товаров партнеров Продавца). Выдача Заказов в магазинах
                    осуществляется во время работы магазинов. С информацией о графике работы магазинов можно
                    ознакомиться на Сайте{' '}
                    <a href={patchUrlByLocale('/shop/')} className={link}>
                      https://lemanapro.ru/shop/
                    </a>
                    .
                  </Typography>
                </li>
              </ol>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Услуга доставки курьером или в пункт выдачи или в Постамат предоставляется не на всей
                территории России. В зависимости от выбранного Товара или способа заказа (на Сайте, по
                телефону, через Мобильное приложение «{legalName}») могут быть доступны только некоторые виды
                доставки. Продавец не предлагает услугу доставки некоторых Товаров (такие Товары можно
                забрать только самовывозом из выбранного Покупателем магазина Продавца). В случае
                невозможности доставки Заказа, Продавец уведомляет Покупателя при оформлении Заказа.
                Подробные Правила доставки приведены на Сайте на странице «Доставка и самовывоз»
                <a href={patchUrlByLocale('/service/dostavka/')} className={link}>
                  {' '}
                  https://lemanapro.ru/service/dostavka/
                </a>
                .
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="p"
                className={paragraphWithoutNumber}
                color="text-primary"
              >
                Доставка Заказов курьером, в пункт выдачи и в Постамат осуществляется привлеченной Продавцом
                Службой доставки.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.3.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Дата передачи и временной интервал передачи Заказа Покупателю доводятся до Покупателя при
                оформлении Заказа (в экранной форме на Сайте или в Мобильном приложении «{legalName}», или
                устно оператором – в случае оформления Покупателем Заказа по телефону), а после завершения
                оформления заказа и его оплаты – и в сервисе отслеживания статуса Заказа https://order.
                lemanapro.ru по ссылке (на конкретный Заказ, например: https://order. lemanapro.ru/хххххх ),
                присланной Продавцом Покупателю в SMS по указанному Покупателем номеру телефона.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.4.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                В случае предоставления Покупателем неверных контактных данных Продавец не несет
                ответственности за ненадлежащее исполнение Заказа.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.5.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                По факту поступления Заказа в пункт выдачи заказов Покупателю будет направлено сообщение на
                номер телефона и/или по адресу электронной почты, указанные при оформлении Заказа. При
                поступлении Заказа в выбранный Покупателем магазин Продавца или Постамат или при передаче
                Заказа курьеру Покупателю присылается ПИН-код посредством смс-сообщения на номер телефона
                и(или) по электронной почте, указанные при оформлении Заказа. Только после получения данного
                сообщения Покупателем Заказ можно забрать в пункте выдачи заказов, в Постамате или в магазине
                Продавца. Покупатель обязуется не сообщать третьим лицам данный ПИН-код, за исключением
                случая сообщения Покупателем ПИН-кода Получателю Товара.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.6.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Если при оформлении Заказа на Сайте не был указан иной срок, то Заказ можно забрать в пункте
                выдачи заказов в течение 7 календарных дней, а в магазине или Постамате Продавца - в течение
                3 календарных дней со дня поступления Заказа в пункт выдачи заказов или в магазин,
                соответственно. Стоимость доставки и сроки хранения Заказов в Постаматах, пунктах выдачи и
                магазинах Продавца Покупатель может уточнить на Сайте на странице «Доставка и самовывоз»
                <a href={patchUrlByLocale('/service/dostavka/')} className={link}>
                  {' '}
                  https://lemanapro.ru/service/dostavka/
                </a>{' '}
                до оформления заказа.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.7.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Доставленный в пункт выдачи заказов или в магазин Продавца Заказ Покупатель получает
                (забирает) в установленный срок. Срок, отведенный для получения Заказа Покупателем, ограничен
                и указывается при оформлении Заказа, на этапе выбора соответствующего способа доставки.
                Неявка Покупателя за Заказом в пункт выдачи заказов или в магазин Продавца в указанный срок
                считается отказом Покупателя от договора купли-продажи и является основанием для
                аннулирования Заказа Продавцом. Если неполученный Заказ был предоплачен, денежные средства
                возвращаются Покупателю в порядке, предусмотренном настоящими Правилами. В указанном случае
                при возврате денежных средств Продавец удерживает стоимость возврата Товаров от Покупателя,
                которая соответствует стоимости доставки, оплаченной Покупателем при оформлении Заказа.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.8.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Если Заказ был доставлен Покупателю в установленный срок, но не был передан Покупателю по его
                вине, последующая доставка производится в новые сроки, согласованные с Продавцом, после
                повторной оплаты Покупателем стоимости услуг по доставке.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.9.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Продавец приложит все усилия для соблюдения сроков доставки, указанных на Сайте, тем не
                менее, задержки в доставке возможны ввиду непредвиденных обстоятельств, произошедших не по
                вине Продавца.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.10.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Заказ вручается Покупателю или Получателю, а в их отсутствие — любому лицу, предъявившему
                информацию о номере заказа, либо иное (в том числе электронное) подтверждение заключения
                договора розничной купли-продажи или оформление Заказа Покупателем. Заказ вручается лицу,
                готовому предоставить сведения о Заказе (номер Заказа и/или ФИО Получателя, Покупателя), а
                также оплатить стоимость Заказа в полном объеме лицу, осуществляющему доставку Заказа. Для
                получения Заказа в Постамате Покупатель должен ввести ПИН-код; лицо, которое ввело ПИН-код,
                считается уполномоченным Получателем Товара. Для получения Заказа в магазине Продавца или при
                доставке заказа курьером Покупатель должен сообщить ПИН-код сотруднику Продавца или курьеру
                службы доставки, соответственно. Лицо, которое сообщило ПИН-код в магазине Продавца или
                курьеру, считается уполномоченным Получателем Товара.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.11.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                В случае, если Покупатель передал третьему лицу (другу, родственнику и т. д.) номер Заказа
                (сообщил ПИН-код) и такое лицо предъявляет данный номер (ПИН-код) при получении Заказа, это
                расценивается как передача Покупателем такому лицу всех полномочий по получению (и оплате,
                если это применимо) Заказа.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.12.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Стоимость доставки каждого Заказа рассчитывается автоматически. При определении стоимости
                доставки учитываются вес (в том числе объемный вес) Заказа, адрес доставки, протяжённость
                маршрута, скорость, срок доставки, день недели, время суток и способ доставки, выбранный
                Покупателем при оформлении Заказа вид тарифа, проводимые Продавцом (или с его участием) в
                момент оформления Заказа маркетинговые акции. Стоимость доставки указывается на последнем
                этапе оформления Заказа на Сайте. В случае доставки только части заказа производится
                перерасчет стоимость доставки в случае, если вес доставленного заказа попадает в другой
                весовой брейк.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.13.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Во избежание случаев мошенничества, а также для выполнения взятых на себя обязательств при
                вручении предоплаченного Заказа, лицо, осуществляющее доставку Заказа Покупателю, вправе
                затребовать документ, удостоверяющий личность Получателя, а также указать тип и номер
                предоставленного Получателем документа на квитанции к Заказу. Продавец гарантирует
                конфиденциальность и защиту персональной информации Получателя.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.14.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                При получении Заказа Получатель должен в месте получения заказа проверить внешний вид и
                упаковку Заказа, количество Товаров в Заказе, внешний вид и упаковку Товаров, комплектность,
                ассортимент, целостность Товаров. Подпись Покупателя в документах, подтверждающих получение
                Заказа (в том числе в электронных документах), означает отсутствие претензий Покупателя к
                количеству, внешнему виду, ассортименту, целостности и комплектности Товара. При обнаружении
                несоответствий вышеуказанных характеристик Товара Заказу Покупатель вправе отказаться от
                Товара (см. Раздел 7 ниже). После приемки Товара Продавец не принимает претензий к Товару в
                отношении вышеуказанных характеристик Товара. Заказ считается полученным в момент его
                передачи Покупателю или указанному им лицу. Принимая Товар и подписывая документ,
                подтверждающий получение Заказа, Покупатель подтверждает исполнение Заказа. При получении в
                Постамате Заказ считается полученным Покупателем в момент открытия дверцы Постамата после
                ввода ПИН-кода. В некоторых случаях (в зависимости от модели Постамата) может потребоваться
                проставление Покупателем подписи на экране Постамата, что признается Покупателем и Продавцом
                аналогом собственноручной подписи. При обнаружении несоответствий вышеуказанных характеристик
                полученного в Постамате Товара Заказу Покупатель должен незамедлительно сообщить об этом
                Продавцу через форму обратной связи на Сайте:{' '}
                <a href={patchUrlByLocale('/company/obratnaya-svyaz/')} className={link}>
                  https://lemanapro.ru/company/obratnaya-svyaz/
                </a>{' '}
                или по телефону:{' '}
                <a href="tel:88007000099" className={cx(link, number)}>
                  8 (800) 700-00-99
                </a>
                .
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="p"
                className={paragraphWithoutNumber}
                color="text-primary"
              >
                При получении в Магазине Заказ считается полученным Покупателем в момент сообщения
                Покупателем ПИН кода сотруднику Продавца, если Покупатель после проверки Товара в месте
                получения не сообщил сотруднику Продавца об отказе от заказа. Покупатель обязан проверить в
                месте получения заказа, что все товары доставлены в нужном количестве, товар и упаковка не
                повреждена. Покупатель вправе отказаться от части товаров в заказе или от всего заказа
                целиком, сообщив об этом представителю Продавца (для большинства способов передачи Заказа
                частичный отказ недоступен – при доставке в Постамат, в пункт выдачи заказов, курьером).
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.15.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Риск случайной гибели или случайного повреждения Товара переходит к Покупателю с момента
                передачи Заказа Получателю или введения ПИН-кода в Постамате (после передачи Заказа
                покупателю). Покупатель и Продавец признают допустимым доказательством передачи Покупателю
                Товара сообщение Покупателем ПИН-кода курьеру службы доставки, отметку Покупателя (в том
                числе электронную отметку в электронной накладной или электронном маршрутном листе службы
                доставки) о получении Товара, видеозапись из пункта выдачи заказов, из магазина Продавца и с
                места установки Постамата.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.16.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Если Продавец не может вручить Заказ Покупателю по не зависящим от Продавца причинам
                (Покупателя нет на месте в условленное время, Покупателем неверно указан адрес доставки или
                номер телефона, Покупатель не явился за Заказом в пункт выдачи заказов или не забрал Заказ в
                установленный срок из Постамата, и проч.), то это считается отказом Покупателя от договора
                купли-продажи и является основанием для аннулирования Заказа Продавцом. Если неполученный
                Заказ был предоплачен, Продавец возвращает Покупателю уплаченные последним денежные средства
                (за вычетом стоимости доставки) в порядке, предусмотренном настоящими Правилами.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                5.17
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                В случае недоставки Заказа по вине Продавца, Продавец возвращает Покупателю стоимость
                предоплаченного Покупателем Заказа и доставки в порядке, предусмотренном настоящими Правилами
                (и после получения подтверждения утраты Заказа от Службы доставки, если применимо).
              </Typography>
            </li>
          </ol>
        </li>
        <li className={listItem}>
          <div className={orderedItem}>
            <Typography
              variant="m"
              size="body_accent"
              as="span"
              className={orderNumber}
              color="text-primary"
            >
              6.
            </Typography>
            <Typography variant="m" size="body_accent" className={paragraphHeading} color="text-primary">
              Оплата Товара
            </Typography>
          </div>
          <ol className={subOrderedList}>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                6.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Цена Товара указывается на Сайте. В силу объективных или субъективных причин возможны ошибки
                на Сайте при указании цен Товаров (отказ или повреждение – не устраняющееся без вмешательства
                оператора нарушение работы программного или аппаратного обеспечения Продавца или его
                контрагентов, ошибка человека при внесении данных о цене товара в базу данных, опечатка и
                т.п.). При возникновении сомнений в отношении указанной на Сайте цены Товара, Покупатель
                может уточнить цену через форму обратной связи, размещенную на Сайте на странице «Клиентская
                поддержка»{' '}
                <a href={patchUrlByLocale('/company/obratnaya-svyaz/')} className={link}>
                  https://lemanapro.ru/company/obratnaya-svyaz/
                </a>
                . В&nbsp;случае обнаружения такой ошибки в&nbsp;отношении цены заказанного Покупателем
                Товара, Продавец информирует Покупателя об&nbsp;этом. Подтверждение о&nbsp;готовности Товара
                к&nbsp;отгрузке Покупателю не&nbsp;направляется.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                6.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Цена Товара на&nbsp;Сайте может быть изменена Продавцом или его контрагентом. Цена Товаров
                и&nbsp;общая стоимость Заказа указывается на&nbsp;последнем этапе оформления Заказа
                и&nbsp;действительна на&nbsp;момент нажатия кнопки &quot;Оформить заказ&quot;/&quot;Перейти
                к&nbsp;оплате&laquo;.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                6.3.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Способы оплаты Товара указаны на Сайте. Согласованным сторонами способом оплаты считается
                способ, выбранный Покупателем из доступных способов оплаты при оформлении Заказа.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                6.4.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                При предоплате Товаров Заказ принимается в работу только после его оплаты Покупателем.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                6.5.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Во избежание случаев различного рода неправомерного использования банковских карт при оплате,
                все Заказы, оформленные на Сайте с предоплатой, проверяются Продавцом. Продавец оставляет за
                собой право без объяснения причины аннулировать Заказ.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                6.6.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                При оплате на Сайте Покупателю направляется электронный чек (или ссылка на скачивание
                электронного чека) по адресу электронной почты, указанному Покупателем при оформлении Заказа
                на Сайте.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                6.7.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Исполнение Заказа Покупателя обеспечивает одно из подразделений Продавца (как правило –
                торговый центр (магазин), указанный Покупателем при выборе города на Сайте, или ближайший к
                адресу Получателя) или партнёр Продавца. В силу указанной особенности организации Продавцом
                торговли дистанционным способом на Сайте, цена одного и того же товара в разных регионах
                может отличаться в зависимости от климатических, экономических, географических границ,
                территориальных особенностей регионов, и других объективных факторов, связанных в первую
                очередь, с различными затратами Продавца на перевозку, организацию продажи и доставку товара
                Покупателю.
              </Typography>
            </li>
          </ol>
        </li>
        <li className={listItem}>
          <div className={orderedItem}>
            <Typography
              variant="m"
              size="body_accent"
              as="span"
              className={orderNumber}
              color="text-primary"
            >
              7.
            </Typography>
            <Typography variant="m" size="body_accent" className={paragraphHeading} color="text-primary">
              Возврат Товара и денежных средств, уплаченных за Товар.
            </Typography>
          </div>
          <ol className={subOrderedList}>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="p"
                className={paragraphWithoutNumber}
                color="text-primary"
              >
                Для возврата Товара Продавца Покупателю необходимо привезти Товар Продавца в один из
                магазинов Продавца. Для возврата Товара партнера Продавца, Покупателю необходимо привезти
                Товар партнера Продавца в один из магазинов Продавца; если же товар партнера Продавца весит
                более 5 кг или в месте жительства Покупателя нет магазина Продавца, Покупатель вправе
                обратиться к Продавцу для вызова курьера для возврата Товара партнера Продавца. Подробные
                правила возврата Товаров Продавца и возврата Товаров его партнеров приведены на Сайте в
                разделе «Возврат товара»{' '}
                <a href={patchUrlByLocale('/service/vozvrat-tovara/')} className={link}>
                  https://lemanapro.ru/service/vozvrat-tovara/
                </a>
                .
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Возврат товара надлежащего качества.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.1.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Покупатель вправе отказаться от Товара надлежащего качества в любое время до его получения, а
                после получения Товара — в течение 100 дней со дня его получения, не считая дня передачи
                Товара Покупателю.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.1.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Покупатель не вправе отказаться от Товара надлежащего качества, имеющего
                индивидуально-определенные свойства, если указанный Товар может быть использован
                исключительно приобретающим его Покупателем.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.1.3.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                При отказе Покупателя от Товара согласно п.7.1.1. Правил Продавец возвращает Покупателю
                уплаченную за Товар денежную сумму, за исключением расходов Продавца на доставку от
                Покупателя возвращенного Товара (в размере стоимости доставки, оплаченной Покупателем при
                оформлении Заказа), не позднее чем через десять дней с момента получения Продавцом
                письменного заявления Покупателя. Заявление признается поданным в надлежащей форме, если оно
                составлено в письменном виде с собственноручной подписью Покупателя. Заявление Покупателя на
                возврат передается Продавцу одновременно с Товаром, от которого Покупатель отказывается.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.1.4.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Возврат Товара надлежащего качества (в том числе технически сложного Товара бытового
                назначения) возможен в случае, если сохранены его потребительские свойства и товарный вид,
                документ, подтверждающий факт и условия покупки указанного Товара у Продавца. Отсутствие у
                Покупателя документа, подтверждающего факт и условия покупки технически сложного Товара
                бытового назначения у Продавца, не лишает Покупателя возможности ссылаться на другие
                доказательства приобретения указанного Товара у Продавца.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Возврат товара ненадлежащего качества
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.2.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Покупатель может возвратить Товар ненадлежащего качества Изготовителю или Продавцу и
                потребовать возврата уплаченной денежной суммы в течение гарантийного срока, срока годности
                либо, если такой срок не установлен, в разумный срок, не превышающий двух лет со дня передачи
                Товара Покупателю с учетом положения п.6 ст.18 Закона «О защите прав потребителей».
                Покупатель также может потребовать замены Товара ненадлежащего качества либо устранения
                недостатков, либо соразмерного уменьшения покупной цены.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.2.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                В случае отказа Покупателя от договора и предъявления требования о возврате уплаченной за
                товар денежной суммы, Продавец возвращает Покупателю уплаченную за Товар денежную сумму в
                течение 10 дней с момента получения Продавцом письменного заявления Покупателя. По требованию
                Продавца и за его счет Покупатель должен возвратить Товар с недостатками.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.3.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Возврат денежных средств
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.3.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                При возврате Покупателем Товара возврат денег производится на основании Заявления Покупателя
                и при условии возврата Товара Продавцу. Денежные средства подлежат возврату тем способом,
                который был использован Покупателем при оплате Товара. Расходы на возврат суммы, уплаченной
                Покупателем за товар ненадлежащего качества, несет Продавец. В случаях отказа Покупателя от
                товара по иным основаниям расходы на возврат суммы, уплаченной Покупателем за товар, несет
                Покупатель.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.3.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                В случае, если Товар был оплачен наличными деньгами, то возврат денежных средств
                осуществляется наличными в кассе магазина Продавца, адреса магазинов можно посмотреть на
                Сайте.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.3.3.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                При возврате денег наличными Покупатель должен предъявить паспорт или иной документ,
                удостоверяющий личность
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.3.4.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Если Товар был оплачен наличными деньгами, но в городе Покупателя нет магазина Продавца, то
                денежные средства будут возвращены на счет Покупателя в банке (банковскую карту) или почтовым
                переводом; для этого Покупателю необходимо в заявлении на возврат Товара указать реквизиты
                своего счета, полный почтовый адрес (в том числе индекс), фамилию, имя и отчество получателя.
              </Typography>
            </li>
            <li className={cx(subListItem, dottedListItem)}>
              <div className={dottedListPart}>
                <Typography
                  variant="s"
                  size="body_long"
                  as="span"
                  className={subOrderNumber}
                  color="text-primary"
                >
                  7.3.5.
                </Typography>
                <Typography
                  variant="s"
                  size="body_long"
                  as="p"
                  className={subParagraph}
                  color="text-primary"
                >
                  В случае, если Товар был оплачен банковской картой, то возврат денежных средств Покупателю
                  осуществляется на ту же банковскую карту, с которой была произведена оплата. Для возврата
                  денежных средств при оплате картой необходимо:
                </Typography>
              </div>
              <ol className={dottedOrderedList}>
                <li className={dottedLi}>
                  <Typography
                    variant="s"
                    size="body_long"
                    as="p"
                    className={subParagraph}
                    color="text-primary"
                  >
                    Позвонить по номеру{' '}
                    <a href="tel:88007000099" className={cx(link, number)}>
                      8 (800) 700-00-99
                    </a>
                  </Typography>
                </li>
                <li className={dottedLi}>
                  <Typography
                    variant="s"
                    size="body_long"
                    as="p"
                    className={subParagraph}
                    color="text-primary"
                  >
                    Заполнить бланк-заявление на возврат
                  </Typography>
                </li>
                <li className={dottedLi}>
                  <Typography
                    variant="s"
                    size="body_long"
                    as="p"
                    className={subParagraph}
                    color="text-primary"
                  >
                    Передать его сотруднику службы доставки вместе с товаром
                  </Typography>
                </li>
              </ol>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.3.6.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Денежные средства будут перечислены в течение 10 дней с момента получения Продавцом
                письменного заявления Покупателя. Срок поступления денежных средств на банковскую карту
                Покупателя зависит от условий обслуживания банка Покупателя.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.3.7.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                При невозможности возврата денег на карту, которой была произведена оплата, Покупатель
                предоставляет заявление с указанием платежных реквизитов для возврата денег и объяснением
                причин, по которым невозможен возврат денег на карту, которой была произведена оплата.
                Возврат денег наличными при оплате банковской картой не производится.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.3.8.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                В&nbsp;случае оплаты Покупателем Товара до&nbsp;наступления отлагательного условия
                о&nbsp;направлении Продавцом Покупателю Подтверждения о&nbsp;готовности Товара
                к&nbsp;отгрузке и&nbsp;ненаступлении отлагательного условия Продавец возвращает Покупателю
                неосновательное обогащение.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.4.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Порядок действий при нарушении Продавцом условия об ассортименте:
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.4.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                В случае передачи Товара в нарушении условия об ассортименте не применяются правила ст. 468
                ГК РФ.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.4.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                В случае обнаружения в Заказе Товара, не соответствующего заказанному ассортименту (т.н.
                «пересорт») либо расхождений по количеству товара, Покупатель вправе при передаче Заказа
                отказаться от Заказа либо от части Заказа, сделав об этом отметку в документе, подтверждающем
                получение Заказа.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.4.3.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Денежные средства, оплаченные Покупателем за фактически не переданный Товар, подлежат
                возврату в течение 10 дней с момента обращения Покупателя с заявлением о возврате денежных
                средств. Возврат уплаченной за товар суммы осуществляется тем способом, которым была
                произведена оплата.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                7.4.4.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Товар, переданный Покупателю в нарушение условия об ассортименте, подлежит возврату
                Продавцу.В случае если Покупатель принимает данный Товар, Продавец вправе потребовать от
                Покупателя оплаты данного Товара по цене, установленной Продавцом для данного Товара на Сайте
                на момент передачи Товара. Если фактически переданный Товар отсутствует в ассортименте
                Продавца, представленном на Сайте на момент передачи Товара, данный Товар оплачивается по
                цене, согласованной с Продавцом.
              </Typography>
            </li>
          </ol>
        </li>
        <li className={listItem}>
          <div className={orderedItem}>
            <Typography
              variant="m"
              size="body_accent"
              as="span"
              className={orderNumber}
              color="text-primary"
            >
              8.
            </Typography>
            <Typography variant="m" size="body_accent" className={paragraphHeading} color="text-primary">
              Интеллектуальные права
            </Typography>
          </div>
          <ol className={subOrderedList}>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                8.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Интеллектуальные права на материалы, представленные на Сайте, включая все его содержимое,
                информацию, фотографии, иллюстрации, рисунки, наименования, товарные знаки, коммерческие
                обозначения и логотипы, принадлежат ООО «ЛЕ МОНЛИД» (Продавцу) и/или его поставщикам, его
                партнерам и производителям Товаров, и охраняются законом. Материалы предназначены
                исключительно для личного, некоммерческого использования. Содержание Сайта, как описано выше,
                не может быть изменено, скопировано, воспроизведено, или выставлено на продажу, сдано в
                аренду, дополнено или использовано любым другим способом без предварительного письменного
                разрешения от ООО «ЛЕ МОНЛИД».
              </Typography>
            </li>
          </ol>
        </li>
        <li className={listItem}>
          <div className={orderedItem}>
            <Typography
              variant="m"
              size="body_accent"
              as="span"
              className={orderNumber}
              color="text-primary"
            >
              9.
            </Typography>
            <Typography variant="m" size="body_accent" className={paragraphHeading} color="text-primary">
              Гарантии и ответственность
            </Typography>
          </div>
          <ol className={subOrderedList}>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                9.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Продавец не несет ответственности за ущерб, причиненный Покупателю вследствие ненадлежащего
                использования Товаров, заказанных на Сайте.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                9.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Продавец не несет ответственности за содержание и функционирование внешних сайтов, ссылки на
                которые могут содержаться на Сайте.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                9.3.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Продавец вправе без предварительного уведомления Покупателя переуступать либо каким-либо иным
                способом передавать свои права и обязанности, вытекающие из его отношений с Покупателем,
                третьим лицам.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                9.4.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Информация о форме и способах направления претензий. Свои вопросы и пожеланию Покупатель
                может направить Продавцу через форму обратной связи, размещенную на Сайте на странице
                «Клиентская поддержка» по адресу{' '}
                <a href={patchUrlByLocale('/company/obratnaya-svyaz/')} className={link}>
                  https://lemanapro.ru/company/obratnaya-svyaz/
                </a>
                , указав соответствующую Тему обращения, или по телефону{' '}
                <a href="tel:88007000099" className={cx(link, number)}>
                  8 (800) 700-00-99
                </a>
                . В случае возникновения претензий со стороны Покупателя, он может обратиться к Продавцу
                через ту же форму обратной связи, указав Тему обращения – «Претензия» или по телефону{' '}
                <a href="tel:88007000099" className={cx(link, number)}>
                  8 (800) 700-00-99
                </a>
                . Все возникающее споры стороны будут рассматриваться в порядке, предусмотренном действующим
                законодательством РФ.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                9.5.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Признание судом недействительности какого-либо положения настоящих Правил не влечет за собой
                недействительность остальных положений.
              </Typography>
            </li>
          </ol>
        </li>
        <li>
          <div className={orderedItem}>
            <Typography
              variant="m"
              size="body_accent"
              as="span"
              className={orderNumber}
              color="text-primary"
            >
              10.
            </Typography>
            <Typography variant="m" size="body_accent" color="text-primary" className={paragraphHeading}>
              Конфиденциальность и защита персональной информации
            </Typography>
          </div>
          <ol className={subOrderedList}>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                10.1.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Предоставляя свои персональные данные при оформлении Заказа или при создании Личного
                кабинета, Покупатель соглашается на их обработку Продавцом, в том числе и в целях продвижения
                Продавцом товаров и услуг.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                10.2.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Продавец вправе передавать персональные данные Покупателя третьим лицам для целей исполнения
                обязательств Продавца перед Покупателем. Подробно описание принципов и подходов Продавца в
                отношении обработки и обеспечения безопасности персональных данных Покупателей приведено в
                Политике обработки персональных данных в ООО «ЛЕ МОНЛИД», размещенной на Сайте.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                10.3.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Продавец вправе направлять Покупателю сообщения рекламно-информационного характера. Если
                Покупатель не желает получать рассылки от Продавца, он должен отказаться от подписки путём
                «клика» по соответствующей ссылке в направляемом Продавцом письме.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                10.4.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Продавец вправе использовать технологию «cookies». Подробная информация о технологии
                «cookies» и её использовании Продавцом содержится в{' '}
                <a href={patchUrlByLocale('/cookie_notification/')} className={link}>
                  Предупреждении об использовании файлов cookies на сайте {legalName}
                </a>
                .
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                10.5.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Продавец получает информацию об IP-адресе посетителя Сайта. Данная информация не используется
                для установления личности посетителя.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                10.6.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Продавец не несет ответственности за сведения, сообщенные Покупателем на Сайте в
                общедоступной форме.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                10.7.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                Продавец не несет ответственности за убытки, которые Покупатель может понести в результате
                того, что его логин и пароль стали известны третьему лицу.
              </Typography>
            </li>
            <li className={subListItem}>
              <Typography
                variant="s"
                size="body_long"
                as="span"
                className={subOrderNumber}
                color="text-primary"
              >
                10.8.
              </Typography>
              <Typography variant="s" size="body_long" as="p" className={subParagraph} color="text-primary">
                В случае возникновения у Продавца подозрений относительно использования Личного кабинета,
                логина, пароля, адреса электронной почты Покупателя третьим лицом или вредоносным программным
                обеспечением, Продавец вправе в одностороннем порядке изменить пароль Покупателя с
                уведомлением последнего на указанный при регистрации адрес электронной почты.
              </Typography>
            </li>
          </ol>
        </li>
      </OrderedList>
    </div>
  </>
));
