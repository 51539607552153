import { createSelector } from 'reselect';
import { RootState } from 'store';

export const getEnv = createSelector(
  ({ env }: RootState) => env,
  (env) => env || {},
);

export const getAnalytics = createSelector(getEnv, (env) => ({
  gtmCode: env.GTM_CODE,
  yaCode: env.YA_CODE,
}));

export const getYaVerification = createSelector(getEnv, (env) => env?.YA_VERIFICATION);
