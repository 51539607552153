import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { StubRegion } from '../types';
import { getStubRegionData } from './api';

type InitialState = { isError: boolean; data: StubRegion | null };

const initialState: InitialState = { isError: false, data: null };

const fetchStubRegionThunk = createAsyncThunk('stubRegionData/FETCH_STUB_REGION_DATA', getStubRegionData);

const { actions, reducer } = createSlice({
  name: 'stubRegion',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStubRegionThunk.pending, (state) => {
        state.isError = false;
      })
      .addCase(fetchStubRegionThunk.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.isError = false;
      })
      .addCase(fetchStubRegionThunk.rejected, (state) => {
        state.isError = true;
      });
  },
});

export { actions, reducer, fetchStubRegionThunk };
