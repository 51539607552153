import React from 'react';
import { Typography } from 'fronton-react';
import { contactLink, contactTitle, ContactUnitWrapper, HardcodeMargin } from './styles';

export interface ContactUnitProps {
  title: string;
  text: string[];
  linkText?: string;
  link?: string;
}

export const ContactUnit: React.FC<ContactUnitProps> = ({ title, text, linkText = '', link = '' }) => (
  <ContactUnitWrapper>
    <Typography variant="s" size="body_accent" className={contactTitle} color="text-primary">
      {title}
    </Typography>
    {text.map((textLine) =>
      textLine === ' ' ? (
        <HardcodeMargin key={textLine} />
      ) : (
        <Typography key={textLine} variant="caption" color="text-primary">
          {textLine}
        </Typography>
      ),
    )}
    {link && (
      <a href={link} className={contactLink}>
        <Typography variant="s" size="body_accent" color="success-dark">
          {linkText}
        </Typography>
      </a>
    )}
  </ContactUnitWrapper>
);
