import { useEffectOnce } from 'common';
import { analyticsUtils } from 'utils';

export const useGaPageview = (
  pagePath: string,
  userAuth: string,
  cityId?: string | null,
  pageType = 'InfoPage',
  userId?: string,
) => {
  useEffectOnce(() => {
    const onPageLoad = () => {
      analyticsUtils.sendEvent('GA:pageview', {
        pageType, // тип страницы
        pagePath, // полный путь страницы, не должен пересекаться с путями других страниц
        userAuth, // показатель авторизации
        platform: 'react', // платформа страницы
        cityId, // id города
        userId,
      });
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
    }
    return () => window.removeEventListener('load', onPageLoad);
  });
};
