const REGIONS = ['', '/kz'];

export function patchPath(path: string | string[] = '') {
  if (Array.isArray(path)) {
    return path.reduce((paths, route) => {
      paths.push(...patchPathToRegion(route));
      return paths;
    }, [] as string[]);
  }
  return patchPathToRegion(path);
}

export function patchPathToRegion(path: string) {
  return REGIONS.map((region) => region + (path === '/' ? '' : path));
}
