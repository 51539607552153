import { styled } from 'linaria/react';
import { css } from 'linaria';
import { breakpoints } from 'common/styles/breakpoints';

export const StaticPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-200);
  width: 100%;
`;
export const StaticPageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-400);
  width: 100%;
  ${breakpoints.from.tablet} {
    flex-direction: row;
  }
`;
export const Faq = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
`;
export const OrderedList = styled.ol`
  flex-direction: row;
  padding: 0;
  margin: var(--space-200) 0 0 0;
  list-style: none;
  counter-reset: item;
  ${breakpoints.from.tablet} {
    padding: 0 0 0 var(--space-200) !important;
  }
`;
export const paragraphHeading = css`
  display: block;
  margin: 0;
  list-style-position: outside;
  ${breakpoints.from.tablet} {
    margin: 0 0 0 var(--space-200) !important;
  }
`;
export const paragraph = css`
  margin: var(--space-100) 0 0 0;
  ${breakpoints.from.tablet} {
    margin: var(--space-100) 0 0 var(--space-400) !important;
  }
`;
export const orderedItem = css`
  display: flex;
  flex-direction: column;
  ${breakpoints.from.tablet} {
    flex-direction: row;
  }
`;
export const orderNumber = css`
  position: relative;
  left: 0;
  margin: 0 0 var(--space-100) 0;
  ${breakpoints.from.tablet} {
    left: -12px;
    margin: 0;
  }
`;
export const listItem = css`
  margin: 0 0 var(--space-200) 0;
`;
export const subOrderedList = css`
  padding: 0;
  margin: 0;
  list-style-type: none;
  ${breakpoints.from.tablet} {
    margin: 0 0 0 var(--space-125);
  }
`;
export const subOrderNumber = css`
  position: relative;
  left: 0;
  width: var(--space-275);
  ${breakpoints.from.tablet} {
    left: -22px;
  }
`;
export const subParagraph = css`
  display: block;
  margin: var(--space-100) 0 0 0;
  ${breakpoints.from.tablet} {
    margin: 0;
  }
`;
export const paragraphWithoutNumber = css`
  padding: 0;
  ${breakpoints.from.tablet} {
    padding: 0 0 0 var(--space-275);
  }
`;
export const subListItem = css`
  display: flex;
  flex-direction: column;
  margin: var(--space-200) 0 0 0;
  ${breakpoints.from.tablet} {
    flex-direction: row;
  }
`;
export const dottedListItem = css`
  flex-direction: column;
`;
export const dottedListPart = css`
  display: flex;
  flex-direction: column;
  ${breakpoints.from.tablet} {
    flex-direction: row;
  }
`;
export const dottedOrderedList = css`
  display: flex;
  flex-direction: column;
  gap: var(--space-100);
  margin: var(--space-100) 0 0 0;
  list-style: disc;
  padding-inline-start: var(--space-300);
  ${breakpoints.from.tablet} {
    padding-inline-start: var(--space-425);
  }
  li {
    display: list-item;
    list-style: outside disc;
  }
  ::marker {
    font-size: var(--space-125);
  }
`;

export const link = css`
  text-decoration: none;
  color: var(--text-primary);

  :visited {
    color: var(--brand-dark);
  }

  &:hover {
    color: var(--brand-dark);
  }
`;
export const number = css`
  white-space: nowrap;
`;

export const dottedLi = css`
  padding: 0;
  ${breakpoints.from.tablet} {
    padding: 0 0 0 var(--space-100);
  }
`;
