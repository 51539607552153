import React from 'react';

export const LmproIcon: React.FC = () => (
  <svg width="171" height="170" viewBox="0 0 171 170" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_259_1294)">
      <g clipPath="url(#clip1_259_1294)">
        <rect x="121.5" y="124" width="72" height="99" transform="rotate(180 121.5 124)" fill="#FDC300" />
        <path d="M121.5 153L121.5 124L49.5 124L121.5 153Z" fill="#FDC300" />
      </g>
      <path
        d="M49 83C49 62.8416 65.3416 46.5 85.5 46.5C105.658 46.5 122 62.8416 122 83V102.5H49V83Z"
        fill="#D0DDD8"
        stroke="#21282B"
        strokeWidth="3"
      />
      <rect x="39" y="96.5" width="93" height="6" rx="3" fill="white" stroke="#21282B" strokeWidth="3" />
      <path
        d="M75.0002 48C75.0002 44.4102 77.9104 41.5 81.5002 41.5H89.5002C93.0901 41.5 96.0002 44.4101 96.0002 48V56.8151C96.0002 68.7343 104.257 79.0635 115.884 81.6889L121.932 83.0546C124.895 83.7238 127 86.3567 127 89.395V96.5H44V89.395C44 86.3567 46.1047 83.7238 49.0683 83.0546L55.1168 81.6888C66.7433 79.0635 75.0002 68.7343 75.0002 56.8151V48Z"
        fill="#D0DDD8"
        stroke="#21282B"
        strokeWidth="3"
      />
      <path
        d="M71 88C71 82.201 75.701 77.5 81.5 77.5H89.5C95.299 77.5 100 82.201 100 88V96.5H71V88Z"
        fill="white"
        stroke="#21282B"
        strokeWidth="3"
      />
    </g>
    <defs>
      <clipPath id="clip0_259_1294">
        <rect width="170" height="170" fill="white" transform="translate(0.5)" />
      </clipPath>
      <clipPath id="clip1_259_1294">
        <rect width="72" height="128" fill="white" transform="translate(121.5 153) rotate(180)" />
      </clipPath>
    </defs>
  </svg>
);
