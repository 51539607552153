import { css } from 'linaria';
import { breakpoints } from 'common/styles/breakpoints';

export const regionList = css`
  width: 100%;
  margin-top: var(--space-450);
  columns: 4;
  column-gap: var(--space-800);

  ${breakpoints.only.desktop} {
    columns: 3;
  }
  ${breakpoints.only.tablet} {
    columns: 2;
  }
  ${breakpoints.to.tablet} {
    columns: 1;
  }
`;

export const regionItem = css`
  display: block;
  width: 240px;
  padding: var(--space-150) var(--space-200) var(--space-150) 0;
  text-decoration: none;
  color: var(--text-primary);
  &:hover {
    color: var(--brand-dark);
  }
`;

export const regionExtraItem = css`
  height: 48px;
`;

export const container = css`
  ${breakpoints.from.tablet} {
    padding: var(--space-500) 0;
    margin-bottom: var(--space-1000);
  }

  ${breakpoints.only.tablet} {
    padding-top: var(--space-300);
  }
  ${breakpoints.to.tablet} {
    padding-top: var(--space-100);
  }
`;
