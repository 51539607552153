import { api, ApiRequestConfig } from 'api';
import { FetchStubRegionProps, StubRegion } from '../types';

export type GetStubRegionParams = {
  params: FetchStubRegionProps;
  config?: ApiRequestConfig;
  host: string;
};

export const getStubRegionData = async ({ host, params, config }: GetStubRegionParams) =>
  api.get<StubRegion, FetchStubRegionProps>(host + '/get-gag-region-data', params, config);
