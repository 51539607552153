import React from 'react';

export const ArrowBendDoubleUpLeftIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 28.5L6 19.5L15 10.5M24 28.5L15 19.5M15 19.5L24 10.5M15 19.5H24C28.7739 19.5 33.3523 21.3964 36.7279 24.7721C40.1036 28.1477 42 32.7261 42 37.5"
      stroke="#333333"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
