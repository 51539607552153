import React, { useCallback } from 'react';
import { LinkButton, Typography } from 'fronton-react';
import { useTranslation } from 'react-i18next';
import { ChevronRightIcon } from '@fronton/icons-react';
import { analyticsUtils, patchUrlByLocale } from 'utils';
import { BoxIcon } from './assets/box-icon';
import { PlatformIcon } from './assets/platform-icon';
import { LmproIcon } from './assets/lmpro-icon';
import { CareIcon } from './assets/care-icon';
import { card, cardContent, cardImg, cards, iconContainer } from './cards.styles';

export const Cards: React.FC = () => {
  const { t } = useTranslation();
  const legalName = t('legalName');

  const cardsContent = [
    {
      icon: <BoxIcon />,
      text: (
        <>
          <span>Сотрудничаем</span> с&nbsp;поставщиками
        </>
      ),
      href: patchUrlByLocale('/postavshchikam/'),
      linkText: 'Стать поставщиком',
    },
    {
      icon: <LmproIcon />,
      text: (
        <>
          <span>Предлагаем</span> товары и услуги для профессионалов
        </>
      ),
      href: patchUrlByLocale('/biznes/'),
      linkText: 'Стать клиентом',
    },
    {
      icon: <PlatformIcon />,
      text: (
        <>
          <span>Cтроим</span> технологичную компанию-платформу
        </>
      ),
      href: 'https://lemanatech.ru/',
      linkText: 'Узнать больше',
    },
    {
      icon: <CareIcon />,
      text: (
        <>
          <span>Заботимся</span> об&nbsp;окружающей среде и&nbsp;людях
        </>
      ),
      href: patchUrlByLocale('/ustoychivoe-razvitie/'),
      linkText: 'Читать подробнее',
    },
  ];

  const gaClickButton = useCallback(
    (elText: string) => {
      analyticsUtils.sendEvent('GA:clickButton', {
        elementType: 'button',
        elementBlock: 'about', // блок, в котором расположен элемент
        elementName: `О компании ${legalName}`, // название блока (заголовок)
        elementText: elText, // текст гиперссылки (зеленым)
      });
    },
    [legalName],
  );

  return (
    <div className={cards}>
      {cardsContent.map(({ icon, text, href, linkText }) => (
        <div key={href} className={card}>
          <div className={cardImg}>
            <div className={iconContainer}>{icon}</div>
          </div>
          <div className={cardContent}>
            <Typography size="body_accent" variant="m" color="text-primary">
              {text}
            </Typography>
            <LinkButton
              iconRight={<ChevronRightIcon type="outline" size="l" color="brand-dark" />}
              variant="accent"
              size="m"
              color="brand-dark"
              as="a"
              href={patchUrlByLocale(href)}
              onClick={() => gaClickButton(linkText)}
            >
              {linkText}
            </LinkButton>
          </div>
        </div>
      ))}
    </div>
  );
};
