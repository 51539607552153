import React from 'react';

interface HumanIconProps {
  mobile?: boolean;
}

export const HumanIcon: React.FC<HumanIconProps> = ({ mobile }) => (
  <svg
    width="49"
    height="48"
    viewBox={`${mobile ? 0 : -6.5} 0 49 48`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M17.4814 13.5C20.3809 13.5 22.7314 11.1495 22.7314 8.25C22.7314 5.35051 20.3809 3 17.4814 3C14.582 3 12.2314 5.35051 12.2314 8.25C12.2314 11.1495 14.582 13.5 17.4814 13.5Z"
      fill="#BF8900"
    />
    <path
      opacity="0.2"
      d="M22.3563 25.2563L28.8813 30.3188C29.3126 30.6778 29.8626 30.8627 30.4233 30.8371C30.984 30.8115 31.5148 30.5773 31.9117 30.1804C32.3086 29.7836 32.5428 29.2527 32.5684 28.692C32.5939 28.1314 32.4091 27.5814 32.05 27.15L24.625 17.6063C24.279 17.2567 23.8673 16.979 23.4136 16.7892C22.9599 16.5993 22.4731 16.5011 21.9813 16.5H12.9813C11.983 16.5053 11.0269 16.9026 10.3188 17.6063L2.89376 27.15C2.50715 27.5791 2.30039 28.1405 2.31628 28.7179C2.33218 29.2953 2.56951 29.8444 2.97914 30.2517C3.38877 30.6589 3.93932 30.893 4.51678 30.9055C5.09425 30.918 5.6544 30.7079 6.08125 30.3188L12.6063 25.2563L7.39375 41.6813C7.26818 41.9496 7.19669 42.2401 7.18339 42.5362C7.17008 42.8322 7.2152 43.1279 7.31619 43.4065C7.41717 43.6851 7.57203 43.941 7.77194 44.1597C7.97184 44.3785 8.21287 44.5557 8.48126 44.6813C8.74965 44.8068 9.04014 44.8783 9.33616 44.8916C9.63217 44.9049 9.92791 44.8598 10.2065 44.7588C10.4851 44.6578 10.741 44.503 10.9597 44.3031C11.1785 44.1032 11.3557 43.8621 11.4813 43.5938L17.4813 34.1438L23.4813 43.5938C23.5985 43.8739 23.7711 44.1273 23.9889 44.3389C24.2067 44.5505 24.465 44.7159 24.7484 44.825C25.0317 44.9341 25.3342 44.9847 25.6377 44.9738C25.9411 44.9629 26.2392 44.8907 26.514 44.7615C26.7888 44.6324 27.0346 44.4489 27.2366 44.2222C27.4387 43.9955 27.5927 43.7303 27.6895 43.4425C27.7863 43.1547 27.8238 42.8503 27.7999 42.5476C27.7759 42.2449 27.6909 41.9502 27.55 41.6813L22.3563 25.2563Z"
      fill="#BF8900"
    />
    <path
      d="M17.4814 13.5C20.3809 13.5 22.7314 11.1495 22.7314 8.25C22.7314 5.35051 20.3809 3 17.4814 3C14.582 3 12.2314 5.35051 12.2314 8.25C12.2314 11.1495 14.582 13.5 17.4814 13.5Z"
      stroke="#BF8900"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.3563 25.2563L28.8813 30.3188C29.3126 30.6778 29.8626 30.8627 30.4233 30.8371C30.984 30.8115 31.5148 30.5773 31.9117 30.1804C32.3086 29.7836 32.5428 29.2527 32.5684 28.692C32.5939 28.1314 32.4091 27.5814 32.05 27.15L24.625 17.6063C24.279 17.2567 23.8673 16.979 23.4136 16.7892C22.9599 16.5993 22.4731 16.5011 21.9813 16.5H12.9813C11.983 16.5053 11.0269 16.9026 10.3188 17.6063L2.89376 27.15C2.50715 27.5791 2.30039 28.1405 2.31628 28.7179C2.33218 29.2953 2.56951 29.8444 2.97914 30.2517C3.38877 30.6589 3.93932 30.893 4.51678 30.9055C5.09425 30.918 5.6544 30.7079 6.08126 30.3188L12.6063 25.2563L7.39376 41.6813C7.26818 41.9496 7.1967 42.2401 7.18339 42.5362C7.17008 42.8322 7.2152 43.1279 7.31619 43.4065C7.41717 43.6851 7.57203 43.941 7.77194 44.1597C7.97184 44.3785 8.21287 44.5557 8.48126 44.6813C8.74965 44.8068 9.04014 44.8783 9.33616 44.8916C9.63217 44.9049 9.92791 44.8598 10.2065 44.7588C10.4851 44.6578 10.741 44.503 10.9597 44.3031C11.1785 44.1032 11.3557 43.8621 11.4813 43.5938L17.4813 34.1438L23.4813 43.5938C23.5985 43.8739 23.7711 44.1273 23.9889 44.3389C24.2067 44.5505 24.465 44.7159 24.7484 44.825C25.0317 44.9341 25.3342 44.9847 25.6377 44.9738C25.9411 44.9629 26.2392 44.8907 26.514 44.7615C26.7888 44.6324 27.0346 44.4489 27.2366 44.2222C27.4387 43.9955 27.5927 43.7303 27.6895 43.4425C27.7863 43.1547 27.8238 42.8503 27.7999 42.5476C27.7759 42.2449 27.6909 41.9502 27.55 41.6813L22.3563 25.2563Z"
      stroke="#BF8900"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
