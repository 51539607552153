import { css } from 'linaria';

export const seoDescription = css`
  margin: var(--space-200) 0 var(--space-450);
  font-size: 12px;
  line-height: 20px;
`;

export const container = css`
  padding-top: var(--space-500);
  padding-bottom: var(--space-1000);
  color: var(--text-minor);

  a {
    color: var(--brand-dark);
  }
`;
