import { css } from 'linaria';

export const noteText = css`
  margin-top: var(--space-300);
`;

export const deliveryRules = css`
  padding: var(--space-225) var(--space-200) var(--space-200);
  margin-top: var(--space-300);
  background-color: var(--secondary-yellow-light);
  border-radius: var(--rounding-l);
`;

export const deliveryRulesLink = css`
  justify-content: left;
  margin-top: var(--space-300);
`;

export const courierInfo = css`
  margin-top: var(--space-150);
`;

export const howToOrderTitle = css`
  margin-top: var(--space-600);
`;

export const howToOrderInfo = css`
  display: grid;
  grid-template-columns: var(--space-325) 1fr;
  margin-top: var(--space-200);
  column-gap: var(--space-200);
  row-gap: var(--space-200);
`;
