import { api, ApiRequestConfig } from 'api';
import { FetchModelProps, ModelJson } from 'features/page-model/types';
import { HttpErrorResponse, RejectConfig } from 'types/common';

export type GetModelParams = {
  host: string;
  params: FetchModelProps;
  config?: ApiRequestConfig;
};

export const getModel = async (
  { host, params, config }: GetModelParams,
  { rejectWithValue }: RejectConfig,
) => {
  try {
    return await api.get<ModelJson, FetchModelProps>(host + '/getModel', params, config);
  } catch (error) {
    const err = error as HttpErrorResponse;
    return rejectWithValue(err?.code || err?.status);
  }
};
