import React, { FC } from 'react';
import { Helmet } from '@okapi/helmet-react';
import { useSelector } from 'react-redux';
import { getAnalytics } from 'common/selectors';

export const YandexMetricaScript: FC = () => {
  const { yaCode } = useSelector(getAnalytics);
  return yaCode ? (
    <Helmet>
      <script>
        {`!function(e,a,t,c,n){e.ym=e.ym||function(){(e.ym.a=e.ym.a||[]).push(arguments)},e.ym.l=+new Date,c=a.createElement(t),n=a.getElementsByTagName(t)[0],c.async=1,c.src="https://mc.yandex.ru/metrika/tag.js",n.parentNode.insertBefore(c,n)}(window,document,"script"),ym(${yaCode},"init",{clickmap:!0,trackLinks:!0,accurateTrackBounce:!0,trackHash:!0,ecommerce:"yaLayer"});`}
      </script>
    </Helmet>
  ) : null;
};
