import { css } from 'linaria';
import { breakpoints } from 'common/styles/breakpoints';

export const backgroundLine = css`
  width: 100%;
  height: var(--space-500);
  margin-top: var(--space-1000);
  background: linear-gradient(180deg, rgba(70, 76, 92, 0.04) 0%, rgba(255, 255, 255, 0) 100%);

  ${breakpoints.only.tablet} {
    margin-top: var(--space-800);
  }
  ${breakpoints.to.tablet} {
    margin-top: var(--space-550);
  }
`;

export const container = css`
  position: relative;
`;

export const phoneNumber = css`
  align-self: center;
  margin-top: var(--space-350);
`;
