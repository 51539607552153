import React from 'react';
import { Remote } from '@okapi/remote';
import { Layout } from 'fronton-react';
import { AnalyticsPageMeta } from 'features/analytics';
import { BreadcrumbsSection } from 'features/breadcrumbs';
import { SeoDescriptionSection } from 'features/seo-description';
import { useSelector } from 'react-redux';
import { getIsMobileView, getPathname, getRegionId } from 'features/page-model/ducks/get-model';
import { getIsAuthorized } from 'common/selectors';
import { useGaPageview } from 'features/analytics/hooks';
import { Content } from './content';

export const RefundPage = () => {
  const path = useSelector(getPathname);
  const isAuthorized = useSelector(getIsAuthorized) ? '1' : '0';
  const regionId = useSelector(getRegionId);
  const isMobileView = useSelector(getIsMobileView);

  useGaPageview(path, isAuthorized, regionId);

  return (
    <>
      <AnalyticsPageMeta />
      {!isMobileView && <Remote widget="header-footer/header" id="header" />}
      <Layout>
        {!isMobileView && <BreadcrumbsSection />}
        <Content />
        <SeoDescriptionSection />
      </Layout>
      {!isMobileView && <Remote widget="header-footer/footer" id="footer" />}
    </>
  );
};
