/* eslint-disable max-lines */
import React, { memo, useRef } from 'react';
import { Typography, Button } from 'fronton-react';
import { useSelector } from 'react-redux';
import {
  ArrowBendDoubleUpLeftIcon,
  CardLoyaltyIcon,
  CheckSquareOffsetIcon,
  LeroyMerlinStoreIcon,
} from 'common/icons';
import { Accordion, AccordionItem } from 'common/components/accordeon';
import { analyticsUtils, patchUrlByLocale } from 'utils';
import { useTranslation } from 'react-i18next';
import { useIntersectionObserverCallback } from 'common';
import { Infobar } from 'common/components/infobar';
import { createIsExperimentExist } from 'features/page-model/ducks/get-model';
import { HIDE_BRANDS_INTERNAL_ID } from 'common/constants';
import {
  accordionBlock,
  accordionContainer,
  accordionPart,
  easyRefund,
  easyRefundAdvantage,
  easyRefundAdvantagesContainer,
  easyRefundAdvantageText,
  infobarContent,
  refundContainer,
  refundInfoText,
  serviceCardCheckoutText,
  serviceCardContainer,
  showMoreButton,
} from './styles';
import { getContentMaps } from './accordion-content-map';

export const Content = memo(() => {
  const gaPromo = (action: string) => {
    analyticsUtils.sendEvent(`GA:promo${action === 'click' ? 'Click' : 'View'}`, {
      elementType: 'banner',
      elementBlock: 'vozvrat', // блок, в котором расположен элемент
      // массив объектов с данными
      promotions: [
        {
          name: 'vozvrat', // расположение баннера
          creative: 'serviceCard', // название
          position: '1', // расположение баннера
        },
      ],
    });
  };

  const gaAccordionClickButton = (open: boolean, heading: string, title: string) => {
    if (!open) {
      analyticsUtils.sendEvent(`GA:clickButton`, {
        elementType: 'button',
        elementBlock: 'vozvrat', // блок, в котором расположен элемент
        elementName: heading, // название блока (заголовок)
        elementText: title, // заголовок пункта
      });
    }
  };

  const observe = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const legalName = t('legalName');
  const isHideBrandsExperimentAvailable = useSelector(createIsExperimentExist(HIDE_BRANDS_INTERNAL_ID));
  const { properQuality, improperQuality, moneyRefund } = getContentMaps(
    legalName,
    isHideBrandsExperimentAvailable,
  );

  useIntersectionObserverCallback(
    observe,
    {
      enter: () => {
        gaPromo('view');
      },
      extraUnobserve: 'enter',
    },
    [],
  );

  return (
    <div className={refundContainer}>
      <div>
        <Typography variant="h1" as="h1" color="text-primary">
          Возврат товаров
        </Typography>
        <div className={refundInfoText}>
          <Typography variant="m" size="body_long" color="text-primary">
            Неважно, где вы приобрели товары – в&nbsp;гипермаркетах или&nbsp;интернет-магазине {legalName},
            вы можете вернуть их в любом нашем магазине.
          </Typography>
          <Typography variant="m" size="body_long" color="text-primary">
            Вернуть товар надлежащего качества можно в течение 100 дней с даты покупки. Для владельцев
            Ключ-карты и&nbsp;Ключ-карты ПРО этот срок продлевается до 1 года, если карта предъявлялась при
            покупке товара. Вернуть товары наших собственных торговых марок можно в течение 1 года, а при
            покупке по Ключ-карте или&nbsp;Ключ-карте ПРО – в течение 2 лет.
          </Typography>
        </div>
      </div>
      <div className={easyRefund}>
        <Typography variant="h2" as="h2" color="text-primary">
          Лёгкий возврат в {legalName}
        </Typography>
        <div className={easyRefundAdvantagesContainer}>
          <div className={easyRefundAdvantage}>
            <LeroyMerlinStoreIcon />
            <div className={easyRefundAdvantageText}>
              <Typography variant="h3" as="h3" color="text-primary">
                Возврат не&nbsp;зависит от места покупки
              </Typography>
              <Typography variant="m" size="body_long" color="text-primary">
                Вы можете вернуть товар в любом магазине {legalName}, если вы купили его на нашем сайте или в
                любом из&nbsp;магазинов нашей сети&nbsp;в&nbsp;России.
              </Typography>
            </div>
          </div>
          <div className={easyRefundAdvantage}>
            <CheckSquareOffsetIcon />
            <div className={easyRefundAdvantageText}>
              <Typography variant="h3" as="h3" color="text-primary">
                Возврат без&nbsp;чека
              </Typography>
              <Typography variant="m" size="body_long" color="text-primary">
                Товары, купленные по Ключ-карте или Ключ-карте ПРО, можно возвращать без чека.
                Не&nbsp;забудьте показать карту при&nbsp;покупке.
              </Typography>
            </div>
          </div>
          <div className={easyRefundAdvantage}>
            <ArrowBendDoubleUpLeftIcon />
            <div className={easyRefundAdvantageText}>
              <Typography variant="h3" as="h3" color="text-primary">
                Расширенный возврат
              </Typography>
              <Typography variant="m" size="body_long" color="text-primary">
                Вы можете вернуть большинство товаров надлежащего качества из&nbsp;Перечня не подлежащих
                обмену (ПП РФ от&nbsp;31&nbsp;декабря 2020 г. №&nbsp;2463).
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div ref={observe}>
        <Infobar className={serviceCardContainer} icon={<CardLoyaltyIcon />}>
          <div className={infobarContent}>
            <div className={serviceCardCheckoutText}>
              <Typography variant="m" size="body_accent" color="text-primary">
                Откройте выгоду: выберите свою Ключ-карту
              </Typography>
              <Typography variant="s" size="body_long" color="text-primary">
                Покупки по карте можно вернуть в течение года, а товары наших марок — в течение 2&nbsp;лет.
              </Typography>
            </div>
            <Button
              kind="regular"
              variant="pseudo"
              as="a"
              href={patchUrlByLocale('/programmy-loyalnosti/')}
              data-qa="promoLinkButton"
              className={showMoreButton}
              onClick={() => gaPromo('click')}
            >
              <Typography variant="s" size="body_accent">
                Узнать&nbsp;подробнее
              </Typography>
            </Button>
          </div>
        </Infobar>
      </div>
      <div className={accordionBlock}>
        <div>
          <Typography variant="h3" as="h3" color="text-primary">
            Товары надлежащего качества
          </Typography>
          <Accordion className={accordionContainer}>
            {properQuality.map(({ title, titleText, content }) => (
              <AccordionItem
                key={titleText}
                title={title}
                onClick={(open) => gaAccordionClickButton(open, 'Товары надлежащего качества', titleText)}
              >
                {content}
              </AccordionItem>
            ))}
          </Accordion>
        </div>
        <div className={accordionPart}>
          <Typography variant="h3" as="h3" color="text-primary">
            Товары ненадлежащего качества
          </Typography>
          <Accordion className={accordionContainer}>
            {improperQuality.map(({ title, content }) => (
              <AccordionItem
                key={title}
                title={title}
                onClick={(open) => gaAccordionClickButton(open, 'Товары ненадлежащего качества', title)}
              >
                {content}
              </AccordionItem>
            ))}
          </Accordion>
        </div>
        <div className={accordionPart}>
          <Typography variant="h3" as="h3" color="text-primary">
            Возврат денег
          </Typography>
          <Accordion className={accordionContainer}>
            {moneyRefund.map(({ title, content }) => (
              <AccordionItem
                key={title}
                title={title}
                onClick={(open) => gaAccordionClickButton(open, 'Возврат денег', title)}
              >
                {content}
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
});
