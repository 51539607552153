import { css } from 'linaria';
import { breakpoints } from 'common/styles/breakpoints';

export const fullHeightPage = css`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const message = css`
  padding-top: var(--space-700);
  margin-bottom: var(--space-500);
  text-align: center;
  ${breakpoints.from.tablet} {
    padding-top: var(--space-1000);
    margin-bottom: var(--space-1000);
  }
  h1 {
    margin-bottom: var(--space-200);
  }
  p {
    margin-bottom: var(--space-400);
  }
`;

export const contentWrapper = css`
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  max-width: 420px;
  ${breakpoints.from.tablet} {
    box-sizing: content-box;
  }
  & > * {
    margin-top: auto;
  }
`;

export const buyIzolenta = css`
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--space-200);
  margin-bottom: var(--space-500);
  text-decoration: none;
  border-radius: var(--rounding-l);
  box-shadow: var(--box-shadow-s);
  cursor: pointer;
  picture {
    width: auto;
    height: var(--space-600);
    margin-right: var(--space-200);
  }
  span {
    color: var(--text-primary);
    transition: 0.2s ease-out;
  }
  svg {
    --i-color: var(--text-primary);
    & > * {
      transition: 0.2s ease-out;
    }
  }
  @media (hover: hover) and (min-width: 768px) {
    &:hover {
      span {
        color: var(--brand-dark);
      }
      svg {
        --i-color: var(--brand-dark);
      }
    }
  }
  ${breakpoints.from.tablet} {
    margin-bottom: var(--space-1000);
  }
  [role='presentation'] {
    margin-left: auto;
  }
`;
