export function getInitialData() {
  if (!__IS_SERVER__ && 'INITIAL_STATE' in window) {
    const { INITIAL_STATE = {} } = window;
    const key = Object.keys(INITIAL_STATE).find((k) => k.startsWith(__NAME__)) ?? '';

    return INITIAL_STATE[key]?.[__NAME__] ?? {};
  }

  return {};
}
