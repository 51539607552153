export const getSEOTitleText = (regionNamePrepositional: string, legalName: string) =>
  `${legalName} в ${regionNamePrepositional} – товары для строительства и ремонта по низким ценам`;

export const getSEOKeywords = (regionName: string, legalName: string) =>
  `${legalName} ${regionName}, строительство, ремонт, декор, обустройство дома, DIY,
   сделай сам, низкие цены, интернет-магазин`;

export const getSEODescription = (
  regionNamePrepositional: string,
  legalName: string,
  hasDelivery = false,
) =>
  hasDelivery
    ? `ПВЗ и доставка товаров ${legalName} в ${regionNamePrepositional}. Как сделать заказ?
      Каталог товаров для строительства и ремонта.`
    : `В настоящий момент магазины ${legalName} не представлены в ${regionNamePrepositional}.
       Ознакомьтесь с каталогом ${legalName} в других городах России.`;
