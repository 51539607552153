import { css } from 'linaria';
import { breakpoints } from 'common/styles/breakpoints';

export const cards = css`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: var(--space-200);
  width: 100vw;
  padding: var(--space-800) var(--layout-gap);
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  -webkit-scroll-snap-type: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ${breakpoints.from.tablet} {
    flex-direction: row;
    gap: var(--space-200);
    padding: var(--space-600) var(--space-675);
    margin: var(--space-400) 0;
  }
  ${breakpoints.from.desktop} {
    justify-content: center;
    flex-wrap: nowrap;
    padding-top: var(--space-600);
    padding-bottom: var(--space-600);
  }
`;

export const card = css`
  display: flex;
  flex-direction: column;
  min-width: 271px;
  width: 100%;
  padding: var(--space-175) var(--space-300) var(--space-400);
  background-color: var(--background-secondary);
  border-radius: var(--rounding-l);
  user-select: none;
  ${breakpoints.from.tablet} {
    flex: calc(50% - var(--space-100) - var(--space-675));
    width: 50%;
  }
  ${breakpoints.from.desktop} {
    flex: unset;
    min-width: unset;
    width: 223px;
  }
`;

export const cardImg = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding-bottom: var(--space-225);
`;

export const iconContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 170px;
`;

export const cardContent = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--space-25);
  a {
    align-items: flex-start;
    margin-top: var(--space-300);
    color: var(--brand-dark) !important;
    &:hover {
      color: var(--text-primary) !important;
    }
  }
`;
