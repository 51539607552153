import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getSeoTemplates } from 'features/page-model/ducks/get-model';
import { processTemplate } from 'utils/seo-template';
import { seoDescriptionStyle } from './style';

export const SeoDescriptionSection: FC = () => {
  const { seotext = '' } = useSelector(getSeoTemplates) || {};
  const description = processTemplate(seotext, 'product name');
  return (
    <div
      data-qa-seo-text=""
      className={seoDescriptionStyle}
      dangerouslySetInnerHTML={{ __html: description ?? '' }}
    />
  );
};
