import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { AnalyticsPageMeta } from 'features/analytics';
import { Remote } from '@okapi/remote';
import { Layout } from 'fronton-react';
import { BreadcrumbsSection } from 'features/breadcrumbs';
import { getIsMobileView } from 'features/page-model/ducks/get-model';
import { Content } from './content';

export const PravilaProvedeniyaAkciiShokCena: FC = () => {
  const isMobileView = useSelector(getIsMobileView);
  return (
    <>
      <AnalyticsPageMeta />
      {!isMobileView && (
        <Remote widget="header-footer/header" id="pravila-provedeniya-akcii-shok-cena-header" />
      )}
      <Layout>
        {!isMobileView && <BreadcrumbsSection />}
        <Content />
      </Layout>
      {!isMobileView && (
        <Remote widget="header-footer/footer" id="pravila-provedeniya-akcii-shok-cena-footer" />
      )}
    </>
  );
};
