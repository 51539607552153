import React from 'react';

export const LeroyStoreIcon: React.FC = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.75 18.125L30 6.875L41.25 18.125H50.625V48.125H9.375V18.125H18.75Z"
      fill="#F2F3F5"
      stroke="#333333"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M18.75 31.25L30 20L41.25 31.25H18.75Z"
      fill="#F4BE55"
      stroke="#333333"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);
