import React from 'react';

export const HeadsetColorIcon: React.FC = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M48.833 29.25C48.8331 26.7746 48.3565 24.3237 47.4307 22.0387C46.505 19.7536 45.1484 17.6798 43.4392 15.9367C41.73 14.1936 39.7021 12.8158 37.4725 11.8828C35.2428 10.9498 32.8556 10.48 30.4485 10.5007C28.043 10.4826 25.6578 10.9543 23.4304 11.8884C21.2029 12.8226 19.1773 14.2008 17.4701 15.9436C15.763 17.6865 14.4081 19.7595 13.4835 22.0432C12.5589 24.3269 12.0829 26.7763 12.083 29.25"
      stroke="#F2F3F5"
      strokeWidth="1.5"
    />
    <path
      d="M10.583 29.25V40.9167C10.583 41.8007 10.9254 42.6486 11.5349 43.2737C12.1444 43.8988 12.9711 44.25 13.833 44.25H17.083C17.945 44.25 18.7716 43.8988 19.3811 43.2737C19.9906 42.6486 20.333 41.8007 20.333 40.9167V32.5833C20.333 31.6993 19.9906 30.8514 19.3811 30.2263C18.7716 29.6012 17.945 29.25 17.083 29.25H10.583Z"
      fill="#F4BE55"
      stroke="#333333"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M51.083 29.25H44.083C43.1548 29.25 42.2645 29.6012 41.6081 30.2263C40.9518 30.8514 40.583 31.6993 40.583 32.5833V40.9167C40.583 41.8007 40.9518 42.6486 41.6081 43.2737C42.2645 43.8988 43.1548 44.25 44.083 44.25H47.583C48.5113 44.25 49.4015 43.8988 50.0579 43.2737C50.7143 42.6486 51.083 41.8007 51.083 40.9167V29.25Z"
      fill="#F4BE55"
      stroke="#333333"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M51.083 40.5V28.6187C51.0831 25.9296 50.5579 23.2671 49.5377 20.7848C48.5174 18.3025 47.0224 16.0496 45.1388 14.156C43.2552 12.2624 41.0204 10.7657 38.5632 9.75214C36.1061 8.73859 33.4753 8.2283 30.8225 8.25071C28.1716 8.23111 25.543 8.74348 23.0883 9.75829C20.6335 10.7731 18.4012 12.2703 16.5198 14.1636C14.6385 16.0569 13.1453 18.3089 12.1264 20.7897C11.1075 23.2706 10.5829 25.9314 10.583 28.6187V40.5"
      stroke="#333333"
      strokeWidth="1.5"
    />
    <path
      d="M48.083 28.5C48.0831 26.3216 47.6357 24.1648 46.7666 22.154C45.8975 20.1432 44.624 18.3182 43.0194 16.7843C41.4149 15.2503 39.5112 14.0379 37.418 13.2168C35.3249 12.3958 33.0838 11.9824 30.8241 12.0006C28.5659 11.9847 26.3267 12.3998 24.2356 13.2218C22.1446 14.0439 20.2429 15.2567 18.6403 16.7904C17.0377 18.3241 15.7657 20.1483 14.8977 22.158C14.0298 24.1677 13.5829 26.3231 13.583 28.5"
      stroke="#333333"
      strokeWidth="1.5"
    />
    <path
      d="M51.083 40.5V45.6429C51.083 47.4615 50.3767 49.2056 49.1195 50.4916C47.8622 51.7776 46.1571 52.5 44.3791 52.5H32.333"
      stroke="#333333"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
