import { css } from 'linaria';
import React, { memo, StrictMode, Suspense } from 'react';
import { AtServer, InBrowser } from 'common/components';
import { ThemeProvider } from 'fronton-react';
import { Routes } from './pages';
import './common/styles/base.css';

export const App = memo(() => (
  <ThemeProvider>
    <main className={app}>
      <StrictMode>
        <InBrowser>
          <Suspense fallback={null}>
            <Routes />
          </Suspense>
        </InBrowser>

        <AtServer>
          <Routes />
        </AtServer>
      </StrictMode>
    </main>
  </ThemeProvider>
));

const app = css`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;
