import React, { FC, useCallback } from 'react';
import { Breadcrumbs, BreadcrumbsItem } from 'fronton-react';
import { useSelector } from 'react-redux';
import { getBreadcrumbs } from 'features/page-model/ducks/get-model';
import { analyticsUtils, patchUrlByLocale } from 'utils';
import { sectionStyle } from './style';

export const BreadcrumbsSection: FC = () => {
  let breadcrumbs = [...(useSelector(getBreadcrumbs) || [])];

  if (breadcrumbs.length > 1) {
    breadcrumbs.pop();
  }
  const sendGA = useCallback((label: string) => {
    analyticsUtils.sendEvent('GA:clickButton', {
      elementType: 'button', // const
      elementBlock: '', // const
      elementText: label,
      elementName: 'breadcrumbs', // const название блока
    });
  }, []);

  if (breadcrumbs) {
    return (
      <section className={sectionStyle}>
        <Breadcrumbs>
          {breadcrumbs.map(({ label, link }) => (
            <BreadcrumbsItem
              key={label}
              onClick={() => sendGA(label)}
              href={patchUrlByLocale(link)}
              data-qa="breadcrumb"
            >
              {label}
            </BreadcrumbsItem>
          ))}
        </Breadcrumbs>
      </section>
    );
  }

  return null;
};
